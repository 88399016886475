// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IFilter } from "../../../components/src/FilterPopover";
import { apiCall,handleLogout, makeApiMessage } from "../../../components/src/common";
import {
  checkIsFilterApplied,
  navigateTo,
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey,
  checkForImportExportPermissionStatus,
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
export const configJSON = require("./config");

interface Company {
  id: number;
  name: string;
}

interface PriceList {
  id: number;
  name: string;
}

interface City {
  id: number;
  name_en: string;
}

interface Gender {
  id: number;
  label: string;
}

interface BusinessAccountCustomerAttributes {
  id: number;
  activated: boolean;
  name: string;
  full_phone_number: string;
  email: string;
  department_name: string;
  department_id: string;
  cost_center: string;
  sub_cost_center: string;
  address: string;
  postal_code: string;
  notes: string | null;
  private_notes: string | null;
  no_of_order: number;
  no_of_pieces: number;
  business_account_id: number;
  employee_id: string;
  company: Company;
  price_list: PriceList;
  city: City;
  gender: Gender;
}

export interface IB2BCustomerData {
  id: string;
  type: "business_account_customer";
  attributes: BusinessAccountCustomerAttributes;
}

interface IMetaData {
  total_pages: number;
  total_count: number;
  current_page: number;
  next_page: number | null;
  pervious_page: number | null;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: unknown;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openCustomerListAction: EventTarget & HTMLButtonElement | null;
  isLoading: boolean;
  customerList: IB2BCustomerData[];
  customerListPagination: IMetaData;
  sortingData: ISortingData;
  isAppliedFilter: boolean;
  filterAnchor: HTMLDivElement | undefined;
  filters: IFilter[];
  page: number;
  pageSize: number;
  isB2bActive: boolean
  errorSnackbarOpen: boolean;
  snakcbarSeverity: "error" | "warning" | "info" | "success";
  errorMessage: string;
  query: string;
  popOverOpened: boolean;
  popOverItemId: string;
  popOverItemStatus: string | boolean;
  popOverTop: number;
  popOverLeft: number;
  rowData: IB2BCustomerData;
  customerQuery: string;
  suggestionFieldTitle: string;
  permissionStatus: PermissionStatus;
  isLoadingPermission: boolean;
  openDeactiveModel: boolean;
  confirmDeactiveModalOpen: boolean;
  snackBarOpen: boolean;
  snackBarMessage: string;
  severity: "warning" | "error" | "success";
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class B2BCustomerListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  authToken: string = "";
  getCustomerListApiCallId: string = "";
  filterSuggestionApiCallId: string = "";
  deactivateCustomerApiCallId: string = "";
  activateCustomerApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SearchTextMessage),
      getName(MessageEnum.LayoutDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      openCustomerListAction: null,
      isLoading: false,
      customerList: [],
      sortingData: {
        name: "",
        email: "",
        phone: "",
        company: "",
        city: "",
        department_name: "",
      },
      isAppliedFilter: false,
      filterAnchor: undefined,
      filters: [{
          title: "Name",
          type: "autocompolete",
          apiKey: "organization",
          options: [],
           value: ""},
             {
            title: "Company",
          type: "autocompolete",
          apiKey: "company_name",
          options: [],
          value: ""  },
            {
           title: "Mobile Number",
              type: "autocompolete",
            apiKey: "full_phone_number",
           options: [],
          value: "" },
           {
          title: "Department",
            type: "autocompolete",
          apiKey: "department_name",
             options: [],
          value: "" },
           {
          title: "Email",
          type: "autocompolete",
          
          
          apiKey: "email",
          options: [],
          value: "" 
        },
          {
          title: "City",

 type: "autocompolete",
          apiKey: "city",
          options: [],
          value: ""       }],


      customerListPagination: {
        total_pages: 0,
        total_count: 0,
        current_page: 0,
        next_page: null,
        pervious_page: null
      },
      page: 0,
      pageSize: 100,
      isB2bActive: false,
      errorSnackbarOpen: false,
      snakcbarSeverity: 'error',
      errorMessage: '',
      query: "",
      popOverOpened: false,
      popOverLeft: 0,
      popOverTop: 0,
      popOverItemId: "",
      popOverItemStatus: "",
      rowData: {
        id: "0",
        type: "business_account_customer",
        attributes: {
          id: 0,
          activated: false,
          name: "",
          full_phone_number: "",
          email: "",
          department_name: "",
          department_id: "",
          cost_center: "",
          sub_cost_center: "",
          address: "",
          postal_code: "",
          notes: null,
          private_notes: null,
          no_of_order: 0,
          no_of_pieces: 0,
          business_account_id: 0,
          employee_id: "",
          company: {
            id: 0,
            name: "",
          },
          price_list: {
            id: 0,
            name: "",
          },
          city: {
            id: 0,
            name_en: "",
          },
          gender: {
            id: 0,
            label: "",
          },
        },
      },
      customerQuery: "",
      suggestionFieldTitle:"",
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false,
        activatePermission: false,
      },
      isLoadingPermission: true,
      openDeactiveModel: false,
      confirmDeactiveModalOpen: false,
      snackBarOpen: false,
      snackBarMessage: "",
      severity: "error",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    this.receiveHeaerSearchText(message);
    this.receiveDataFromLayout(message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.getCustomerListApiCallResponse(apiRequestCallId, responseJson);
      if (apiRequestCallId === this.filterSuggestionApiCallId) {
        this.handleFilterSuggResp(responseJson);
      } else if (apiRequestCallId === this.deactivateCustomerApiCallId) {
        this.handleGetCustomerDeactivateResponse(responseJson);
      } else if (apiRequestCallId === this.activateCustomerApiCallId) {
        this.handleGetCustomerActivateResponse(responseJson);
      } 
    }

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = localStorage.getItem("token");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    this.handleStorageFilter();
    // Customizable Area End
  }
  // Customizable Area Start

  receiveHeaerSearchText = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.SearchMessageText)
        );
        if (recievedData) {
          this.onChangeValue(recievedData.searchText)
        }
    }
  }

  onChangeValue = (value: string) => {
    this.setState({customerQuery: value,page: 0},()=>this.getCustomerList())
  }

  checkGetResponse(responseJson: { errors?: { message: string } }) {
    if (responseJson && !responseJson.errors) {
      return true
    }
    else {
      handleLogout(this.props.navigation, responseJson && responseJson.errors);
      return false
    }
  }

  checkOpacity(activated: string | boolean) {
    if(activated) {
      return 1
    }
    return 0.6
  }

  sortingProps = {
    width: "18%",
    onQueryChange: (query: string) => this.handleQueryChange(query),
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getCustomerList());
  };

  handlePopoverMenuClose = () => {
    this.setState({ popOverOpened: false })
  }

  handleMoreMenu = (item: IB2BCustomerData, position: DOMRect) => {
    this.setState({
      popOverOpened: true,
      popOverItemId: item.id,
      popOverItemStatus: item.attributes.activated,
      popOverLeft: position.left + window.scrollX,
      popOverTop: position.top + window.scrollY,
      rowData: item
    });
  }

  handleCloseFilterPopover = () => {
    this.setState({ filterAnchor: undefined })
  }

  handleFilterChangeAccount = (filters: IFilter[]) => {
    if (checkIsFilterApplied(filters)) {
      localStorage.setItem("b2b_customer_filter_value", JSON.stringify(filters));
    } else {
      localStorage.removeItem("b2b_customer_filter_value");
    };

    this.setState({
      filters,
      isAppliedFilter: checkIsFilterApplied(filters),
      page: 0
    },
    this.getCustomerList
);
  };

  handleFilterClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({ filterAnchor: event.currentTarget })
  }

  handleClearFilter = () => {
    let updated = this.state.filters.map((item: IFilter) => {
      item.value = "";
      item.options = [];
      return item;
    });
    this.setState({ filters: updated });
  }

  handleB2BFilterAutocompleteChange = (title: string, value: string) => {
    this.setState({ suggestionFieldTitle: title });
    this.handleB2BCustomerAutoComplete(title, value);
  }

  handleB2BCustomerAutoComplete=(title: string, value: string)=>{
      this.state.filters.forEach((item: IFilter) => {
        if (item.title === title) item.value = value;
        return item;
      });
  
      let ApiUrl;
      if(title === "Name") {
        ApiUrl = configJSON.B2BCustomerNameSuggestionListEndPoint;
      } else if(title==="Company"){
        ApiUrl = configJSON.B2BCustomerOrgSuggestionListEndPoint;
      }else if(title==="Mobile Number"){
        ApiUrl = configJSON.B2BCustomerMobSuggestionListEndPoint;
      }else if(title==="Department"){
        ApiUrl = configJSON.B2BCustomerDepSuggestionListEndPoint;
      }else if(title==="City"){
        ApiUrl = configJSON.B2BCustomerCitySuggestionListEndPoint;
      }else if(title==="Email"){
        ApiUrl = configJSON.B2BCustomerEmailSuggestionListEndPoint;
      }
  
      let requestMessage = makeApiMessage({
        url: ApiUrl + value,
        method: "GET",
      });
      this.filterSuggestionApiCallId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }; 

  handleFilterSuggResp = (responseJson: { suggestion: string[] }) => {
    const list = this.createSuggestionList(responseJson);
    this.updateFiltersWithSuggestions(list, responseJson.suggestion.join(", ")); 
  };
  
  createSuggestionList = (responseJson: { suggestion: string[] }): { label: string; value: string }[] => {
    if (responseJson?.suggestion.length > 0 && responseJson.suggestion[0] !== "no keywords found") {
      return responseJson.suggestion.map((value: string) => ({
        label: value,
        value,
      }));
    }
    return [];
  };
  
  updateFiltersWithSuggestions = (list: { label: string; value: string }[], suggestion: string) => {
    const updatedFilters = this.state.filters.map((item: IFilter) => {
      if (item.title === this.state.suggestionFieldTitle) {
        item.options = suggestion !== "no keywords found" && item.value !== "" ? list : [];
      }
      return item;
    });
  
    this.setState({ filters: updatedFilters });
  };
  
  handleReturnColorType = () => {
    const { isAppliedFilter } = this.state;
    return isAppliedFilter ? "primary" : "inherit";
  };

  handleStorageFilter = () => {
    const applied_b2bcustomer_filter = localStorage.getItem("b2b_customer_filter_value");
    if (applied_b2bcustomer_filter) {
      this.setState(
        {
          filters: JSON.parse(applied_b2bcustomer_filter),
          isAppliedFilter: checkIsFilterApplied(
            JSON.parse(applied_b2bcustomer_filter)
          ),
        },
        () => this.getCustomerList()
       
      );
    } else {
      this.getCustomerList();
    }
  };

  handleCustomerListCloseActionList = () => {
    this.setState({ openCustomerListAction: null })
  }

  handleCustomerListExportTemplate = () => {
    this.setState({ openCustomerListAction: null })
  }

  handleCustomerListOpenImportModal = () => {
    this.setState({ openCustomerListAction: null });
  }

  handleCustomerListExportCsv = () => {
    this.setState({ openCustomerListAction: null });
  }

  handleNavigateAddCustomer = () => {
    navigateTo({
      props: this.props,
      screenName: "B2BCustomerCreation",
    })
  }

  handlePageChange = (page: number) => {
    this.setState({ page }, () => { this.getCustomerList() })
  }

  getCustomerList = () => {
    this.setState({ isLoading: true });

    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }

    let url;
    let nameFilter = this.state.filters.find((item:IFilter) => item.title === "Name")?.value;
    let cityFilter = this.state.filters.find((item:IFilter) => item.title === "City")?.value;
    let depFilter  = this.state.filters.find((item:IFilter) => item.title === "Department")?.value;
    let mobFilter  = this.state.filters.find((item:IFilter) => item.title === "Mobile Number")?.value;
    let emailFilter = this.state.filters.find((item:IFilter) => item.title === "Email")?.value;
    let orgFilter =this.state.filters.find((item:IFilter) => item.title === "Company")?.value;

    url = configJSON.B2BCustomerAPIEndpoint +
      (`?page_no=${this.state.page + 1}&per_page=${this.state.pageSize}`) 
      +
      (nameFilter ? `&filter_by[name]=${nameFilter}` : '') +
      (cityFilter ? `&filter_by[city_name]=${cityFilter}` : '') +
      (depFilter ? `&filter_by[department_name]=${depFilter}` : '') +
      (mobFilter ? `&filter_by[full_phone_number]=${mobFilter}` : '') +
      (emailFilter ? `&filter_by[email]=${emailFilter}` : '') +
      (orgFilter ? `&filter_by[company_name]=${orgFilter}` : '') +
      (this.state.query ? `${this.state.query}` : '') + 
      (this.state.customerQuery ? `&filter_by[query]=${this.state.customerQuery}` : '');
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getCustomerListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getCustomerListApiCallResponse(apiRequestCallId: string, responseJson: { data: IB2BCustomerData[], status: number,meta: IMetaData, message?: string, errors?: { message: string } }) {
    if (apiRequestCallId === this.getCustomerListApiCallId) {
      if(responseJson?.status == 500) {
        this.setState({
          isLoading: false,
          errorSnackbarOpen: true,
          errorMessage: "Internal server error",
          snakcbarSeverity: 'error'
        })
      } 
      else {
      if (this.checkGetResponse(responseJson) && !responseJson.message) {
        this.setState({ isLoading: false, customerList: responseJson.data , customerListPagination: responseJson.meta});
      } else {
        this.setState({ isLoading: false })
      }
      }
    }
  }

  handleViewCustomerDetails = (getCustomerId: string | number) => {
    navigateTo({
      id: String(getCustomerId),
      props: this.props,
      screenName: "B2BCustomerView",
    });
  }

  handleEditCustomerDetails = (getCustomerId: string | number) => {
    navigateTo({
      id: String(getCustomerId),
      props: this.props,
      screenName: "B2BCustomerEdit",
    });
  };

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.LayoutMessageData)
      );
      if (recievedData.userContext) {
        this.handleUserChange(recievedData.userContext);
      }
    }
  };

  handleUserChange = (userContext: IUserContext) => {
    const userData = userContext.user?.attributes.permission_groups;
    const apiKey = customPermissionApiKey.b2bCustomersPermission;
    const permissionVal = checkForNewPermissonStatus(
      apiKey,
      userData as Array<PermissionGroupArray>
    );

    const apiKeyImportExport =
      customPermissionApiKey.dataImportExportPermission;
    const valueImportExportPermission = checkForImportExportPermissionStatus(
      apiKeyImportExport,
      userData as Array<PermissionGroupArray>
    );
    this.setState({
      permissionStatus: {
        ...permissionVal,
        exportPermission: valueImportExportPermission.exportPermission,
      },
      isLoadingPermission: false,
    });
  };

  handleActivateDeactivate = () => {
    const { rowData } = this.state;
    if (rowData && rowData.attributes.activated) {
      this.setState({
        popOverOpened: false,
        openDeactiveModel: true,
      });
    } else {
      this.activateCustomerGroup();
    }
  };

  handleCloseConfirmModal = () => {
    this.setState({ openDeactiveModel: false });
  };

  handleDeactivate = () => {
    this.deActivateCustomerGroup();
    this.setState({ openDeactiveModel: false });
  };

  deActivateCustomerGroup = () => {
    const { rowData } = this.state;

    const apiUrl = configJSON.B2BCustomerAPIEndpoint;

    let message = makeApiMessage({
      url: apiUrl + `/${rowData.id}/deactivate`,
      method: "PUT",
    });
    this.deactivateCustomerApiCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  activateCustomerGroup = () => {
    const { rowData } = this.state;

    const apiUrl = configJSON.B2BCustomerAPIEndpoint;

    let message = makeApiMessage({
      url: apiUrl + `/${rowData.id}/activate`,
      method: "PUT",
    });
    this.activateCustomerApiCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleGetCustomerDeactivateResponse = (response: { data: string }) => {
    if (response && response.data) {
      this.setState({ confirmDeactiveModalOpen: true });
    }
  };

  handleGoBackToListing = () => {
    this.setState({
      confirmDeactiveModalOpen: false,
    });
    this.getCustomerList();
  };

  handleGetCustomerActivateResponse = (response: { data: string }) => {
    if (response && response.data) {
      this.setState({
        popOverOpened: false,
        snackBarOpen: true,
        snackBarMessage: response.data,
        severity: "success",
      });
      setTimeout(() => this.getCustomerList(), 500);
    }
  };

  handleSnackbarClose = () => {
    this.setState({ snackBarOpen: false, snackBarMessage: "" });
  };
  // Customizable Area End
}