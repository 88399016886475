import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toString } from "lodash";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IFilter } from "../../../components/src/FilterPopover";
import moment from "moment";
import { handleMomentDateFormat } from "../../../blocks/cfcashier/src/utils";
import { getStorageData } from "framework/src/Utilities";
import { IUserContext } from "../../navigationmenu/src/PageContainerController.web";
import { makeApiMessage } from "../../../components/src/common";
import { getRegionStoreParam, removeSarUnit } from "./utils";
import { handleDisplayRegion } from "../../dashboard/src/utils";
import html2pdf from "html2pdf.js";
import { customPermissionApiKey, DashboardPermissionStatus, checkForDashboardPermissonStatus, checkIsFilterApplied, getDateRangeForQuery } from "../../utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
export type ClothesCarpetResponse = {
  data: {
    orders: string;
    pieces: string;
    amount: string
  }
}
export type TotalOrdersResponse = {
  data: {
    clothes_orders_count: string,
    clothes_pieces: string,
    clothes_amount: string,
    clothes_discount: string,
    clothes_sub_total: string,
    clothes_vat: string,
    clothes_net:string,
    carpets_orders_count: string,
    carpets_pieces: string,
    carpets_amount: string,
    carpets_discount:string,
    carpets_sub_total: string,
    carpets_vat: string,
    carpets_net:string
  }
}
export interface SaleOrdersTable {
  total: TotalData,
  dates: string,
  clothes: ClothesCarpetData,
  carpet: ClothesCarpetData
}

type TotalData = {
  orders: string
  pieces: string
  amount: string
  discount: string
  subTotal: string
  vat: string
  net: string
}
type ClothesCarpetData = {
  orders_count: string;
  orders_pieces: string;
  orders_amount: string;
}
type SaleOrdersTableResponse = {
  data: Array<{
    date: string;
    clothes_data: {
      orders_count: string;
      orders_pieces: string;
      orders_amount: string;
    },
    carpets_data: {
      orders_count: string;
      orders_pieces: string;
      orders_amount: string;
    },
    total_orders: string,
    total_pieces: string,
    total_amount: string,
    total_discount: string,
    total_sub_total: string,
    total_vat: string,
    total_net: string,
  }>
}
type HorizontalChart = {
  name: string;
  value: number | string
}
type VertialChart = {
  name: string; 
  clothes: number | string; 
  carpet: number | string
}

export const dateColumns = [
  {
    header: "Date",
    binding: "dates",
  },
] as {header: string, binding: string}[]

export const totalColumns = [
  {
    header: "Orders",
    binding: "orders",
    unit:""
  },
  {
    header: "Pieces",
    binding: "pieces",
    unit:""
  },
  {
    header: "Amount",
    binding: "amount",
    unit:" (SAR)"
  },
  {
    header: "Discount",
    binding: "discount",
    unit:" (SAR)"

  },
  {
    header: "Sub Total",
    binding: "subTotal",
    unit:" (SAR)"

  },
  {
    header: "VAT",
    binding: "vat",
    unit:" (SAR)"

  },
  {
    header: "Net",
    binding: "net",
    unit:" (SAR)"

  }
] as { header: string; binding: keyof TotalData, unit: string }[]

export const clothesColumn = [
  {
    header: "Orders",
    binding: "orders_count",
    unit:""
  },
  {
    header: "Pieces",
    binding: "orders_pieces",
    unit:""
  },
  {
    header: "Amount",
    binding: "orders_amount",
    unit:" (SAR)"

  },
] as { header: string; binding: keyof ClothesCarpetData, unit: string }[]


export const carpetColumn = [
  {
    header: "Orders",
    binding: "orders_count",
    unit:""
  },
  {
    header: "Pieces",
    binding: "orders_pieces",
    unit:""
  },
  {
    header: "Amount",
    binding: "orders_amount",
    unit:' (SAR)'
  },
] as { header: string; binding: keyof ClothesCarpetData, unit: string }[]
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  query: string;
  isLoading: boolean;
  sortingFields: ISortingData;
  pageNum: number;
  pageSize: number;
  textSearchHeader: string;
  filterAnchor?: HTMLDivElement;
  filters: IFilter[];
  token: string,
  printerDialogOrderId: string,
  regionId: string,
  storeId: string,
  areaId: string;
  storeName: string,
  groupId: string,
  groupName: string,
  regionName: string,
  saleOrdersTable: SaleOrdersTable[],
  clothesData: HorizontalChart[],
  carpetData: HorizontalChart[],
  totalData: VertialChart[],
  isLoadingClothes: boolean,
  isLoadingCarpet: boolean,
  isLoadingTotal: boolean,
  isLoadingSummaryReceipt: boolean
  openPrintSummary: boolean
  receiptUrl: string,
  openExportModal: boolean
  permissionStatus: DashboardPermissionStatus | null;
  isAppliedFilter: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SalesDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  saleOrdersTableId: string = ""
  clothesOrdersId: string = ""
  carpetOrdersId: string = ""
  tatalOrdersId: string = ""
  summaryReceiptId = ""

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.BroadcastNavbarDataMessage),
      getName(MessageEnum.LayoutDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      sortingFields: {
        year: "",
        clothes: "",
        carpets: "",
        orders: "",
        pcs: "",
        amount:"",
        discount:"",
        subtotal:"",
        vat:"",
        net:""
      },
      filters: [
        {
          title: "Date",
          value: "today",
          type: "dateselect",
          datevalue: { from: "", to: "" },
          options: [
            {
              label: "Today",
              value: "today",
            },
            {
              label: "Last 7 days",
              value: "last_7_days",
            },
            {
              label: "Last 30 days",
              value: "last_30_days",
            },
            {
              label: "Specific Dates",
              value: "specific",
            },
          ]
        }
      ],
      textSearchHeader: "",
      query: "",
      isLoading: false,
      pageNum: 1,
      pageSize: 10,
      filterAnchor: undefined,
      token: "",
      printerDialogOrderId: "",
      regionName: "-",
      regionId: "",
      storeId: "",
      areaId: "",
      storeName: "",
      groupId: "",
      groupName: "",
      saleOrdersTable: [],
      clothesData: [],
      carpetData: [],
      totalData: [],
      isLoadingClothes: true,
      isLoadingCarpet: true,
      isLoadingTotal: true,
      isLoadingSummaryReceipt: false,
      openPrintSummary: false,
      receiptUrl: "",
      openExportModal: false,
      permissionStatus: null,
      isAppliedFilter: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    this.receiveDataFromLayout(message)
    if (message.id === getName(MessageEnum.BroadcastNavbarDataMessage)) {
      const receivedData = message.getData(
        getName(MessageEnum.BroadcastNavbarData)
      );
      const {areaId, storeId,groupId,regionMultiId,regionNames,regionSelectAll} = receivedData
      this.setState({areaId,storeId,groupId,regionId:regionMultiId,regionName: handleDisplayRegion(regionMultiId,regionNames,regionSelectAll).textDisplayed},()=>{
        if(regionMultiId) this.handleStorageFilter()
      })
    }
    const requestApiId: any = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successResponse: any = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (requestApiId === this.saleOrdersTableId) {
      this.handleSaleOrderDataTable(successResponse);
    }
    if (requestApiId === this.clothesOrdersId) {
      this.handleClothesOrdersResponse(successResponse);
    }
    if (requestApiId === this.carpetOrdersId) {
      this.handleCarpetOrdersResponse(successResponse);
    }
    if (requestApiId === this.tatalOrdersId) {
      this.handleTotalOrdersResponse(successResponse);
    }
    if (requestApiId === this.summaryReceiptId) {
      this.handleSummaryReceiptResponse(successResponse)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    const token = await getStorageData("token");
    this.setState(({ token }), () => {
     if(this.state.regionId || this.state.storeId) {
       this.getSectionSaleApiCalling();
     }
    })
  }

  getSectionSaleApiCalling() {
    //get all api calling
    this.getSaleOrdersDataTable()
    this.getCarpetOrders()
    this.getClothesOrders()
    this.getTatalOrders()
  }

  getSaleOrdersDataTable() {
    const apiEndPoint =  configJSON.saleOrdersWiseEndPoint + "?" + this.handleDateFilterParam().params
    const requestMessage: Message = makeApiMessage({
      url: apiEndPoint,
      method: "GET",
    });
    this.saleOrdersTableId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSaleOrderDataTable = (responseData: SaleOrdersTableResponse) => {
    const transformData: SaleOrdersTable[] = responseData.data.map((response) => ({
      dates: response.date,
      carpet: response.carpets_data,
      clothes: response.clothes_data,
      total: {
        amount: response.total_amount,
        discount: response.total_discount,
        net: response.total_net,
        orders: response.total_orders,
        pieces: response.total_pieces,
        subTotal: response.total_sub_total,
        vat: response.total_vat
      }
    }))
    this.setState({
      saleOrdersTable: transformData
    })
  }
  getClothesOrders() {
    this.setState({isLoadingClothes: true})
    const apiEndPoint = configJSON.clothesOrderEndpoint + "?" + this.handleDateFilterParam().params
    const requestMessage: Message = makeApiMessage({
      url: apiEndPoint,
      method: "GET",
    });
    this.clothesOrdersId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleClothesOrdersResponse = (responseData: ClothesCarpetResponse) => {
    const transformData: HorizontalChart[] = [
      {
        name:"Orders",
        value: removeSarUnit(responseData.data.orders)
      },
      {
        name:"Pieces",
        value: removeSarUnit(responseData.data.pieces)
      },
      {
        name:"Amount",
        value: removeSarUnit(responseData.data.amount)
      }
    ] 
    this.setState({
      clothesData: transformData,
      isLoadingClothes: false
    })
  }
  getCarpetOrders() {
    this.setState({isLoadingCarpet: true})
    const apiEndPoint = configJSON.carpetOrdersEndPoint + "?" + this.handleDateFilterParam().params
    const requestMessage: Message = makeApiMessage({
      url: apiEndPoint,
      method: "GET",
    });
    this.carpetOrdersId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleCarpetOrdersResponse = (responseData: ClothesCarpetResponse) => {
    const transformData: HorizontalChart[] = [
      {
        name:"Orders",
        value: removeSarUnit(responseData.data.orders)
      },
      {
        name:"Pieces",
        value: removeSarUnit(responseData.data.pieces)
      },
      {
        name:"Amount",
        value: removeSarUnit(responseData.data.amount)
      }
    ] 
    this.setState({
      carpetData: transformData,
      isLoadingCarpet: false
    })
  }

  getTatalOrders() {
    this.setState({isLoadingTotal: true})
    const apiEndPoint = configJSON.totalOrdersEndPoint + "?" + this.handleDateFilterParam().params
    const requestMessage: Message = makeApiMessage({
      url: apiEndPoint,
      method: "GET",
    });
    this.tatalOrdersId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleTotalOrdersResponse = (responseData: TotalOrdersResponse) => {
    const totalRes = responseData.data
    const transformData: VertialChart[] = [
      { name: "Orders", clothes: removeSarUnit(totalRes.clothes_orders_count), carpet: removeSarUnit(totalRes.carpets_orders_count) },
      { name: "Pieces", clothes: removeSarUnit(totalRes.clothes_pieces), carpet: removeSarUnit(totalRes.carpets_pieces) },
      { name: "Amount", clothes: removeSarUnit(totalRes.clothes_amount), carpet: removeSarUnit(totalRes.carpets_amount) },
      { name: "Discount", clothes: removeSarUnit(totalRes.clothes_discount), carpet: removeSarUnit(totalRes.carpets_discount) },
      { name: "Subtotal", clothes: removeSarUnit(totalRes.clothes_sub_total), carpet: removeSarUnit(totalRes.carpets_sub_total) },
      { name: "VAT", clothes: removeSarUnit(totalRes.clothes_vat), carpet: removeSarUnit(totalRes.carpets_vat) },
      { name: "NET", clothes: removeSarUnit(totalRes.clothes_net), carpet: removeSarUnit(totalRes.carpets_net) }
    ]
    
    this.setState({
      totalData: transformData,
      isLoadingTotal: false
    })
  }

  getSummaryReceipt = (startDate: string, endDate: string) => {
    const {regionId, storeId,groupId,areaId} = this.state
    if (regionId && groupId) {
      this.setState({isLoadingSummaryReceipt: true})
      const regionParam = `?region_ids=${regionId}`
      const groupParam = groupId  ? `&group_ids=${groupId}` : ""
      const areaParam = areaId  ? `&area_ids=${areaId}` : ""
      const storeParam = storeId ? `&store_ids=${storeId}` : ""
      const message = makeApiMessage({
        url: configJSON.salesSummaryApi + regionParam + groupParam + areaParam + storeParam + getDateRangeForQuery(startDate, endDate).dateRangeQuery,
        method: configJSON.dashboarApiMethodType
      })
      this.summaryReceiptId = message.messageId
      this.send(message)
    }
  }

  handleSummaryReceiptResponse = (responseJson?: {url?: string}) => {
    this.setState({receiptUrl: toString(responseJson?.url), isLoadingSummaryReceipt: false})
  }

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getSectionSaleApiCalling());
  };

  sortingProps = {
    onQueryChange: (query: string) => this.handleQueryChange(query),
    onChange: (sortingFields: ISortingData) => this.setState({ sortingFields }),
  };

  handleFilterOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({ filterAnchor: event.currentTarget })
  }
  handleCloseFilterPopover = () => {
    this.setState({ filterAnchor: undefined })
  }
  handleFilterChangeAccount = (filters: IFilter[]) => {
    if (checkIsFilterApplied(filters)) {
      localStorage.setItem("filter_value", JSON.stringify(filters));
    } else {
      localStorage.removeItem("filter_value");
    };
    this.setState({
      filters,
      pageNum: 1,
      isAppliedFilter: checkIsFilterApplied(filters)
    }, () => this.getSectionSaleApiCalling());
  };
  handleDateFilterParam = () => {
    const today = moment().format('YYYY-MM-DD');
    let startDate, endDate;
    let dateRange = ""
    let commonEndDate = today;
    let comomStartDate = moment().subtract(6, "days").format("YYYY-MM-DD");
    const dateFilter = this.state.filters.find((item) => item?.title === 'Date')?.value;
    switch (dateFilter) {
      case "last7":
      case "last_7_days":
        endDate = commonEndDate;
        startDate = comomStartDate;
        dateRange = "last_7_days"
        break;
      case "last30":
      case 'last_30_days':
          dateRange = "last_30_days"
          endDate = today;
          startDate = moment().subtract(29, "days").format("YYYY-MM-DD");
           break;
      case 'today':
        dateRange = "today"
        endDate = today;
        startDate = today;
        break;
      case 'specific':
        const dateFilter = this.state.filters.find((item) => item?.title === 'Date')?.datevalue;
        if (dateFilter?.from && dateFilter?.to) {
          endDate = moment(dateFilter.to).format('YYYY-MM-DD');
          startDate = moment(dateFilter.from).format('YYYY-MM-DD');
        }
        dateRange = "custom"
        break;
      default:
        endDate = today;
        startDate = moment().format('YYYY-MM-DD');
        dateRange = "today"
    }
    const startDateText = startDate ? handleMomentDateFormat(startDate, 'DD-MM-YYYY') : moment().subtract(7, 'days').format('DD-MM-YYYY')
    const endDateText = endDate ? handleMomentDateFormat(endDate, 'DD-MM-YYYY') : moment().format('DD-MM-YYYY')

    const dateParams = `&date_range=${dateRange}${getDateRangeForQuery(startDate, endDate).dateRangeQuery}`

    const regionStore = getRegionStoreParam(this.state.storeId, this.state.regionId,this.state.groupId,this.state.areaId)

    const params = regionStore  + dateParams

    return {
      startDateText, 
      endDateText,
      params
     }
  };
  openPrinterDialog = (orderId: string) => {
    this.setState({ printerDialogOrderId: orderId })
  }
  closePrinterDialog = () => {
    this.setState({ printerDialogOrderId: "" })
  }

  togglePrintSummaryDialog = () => this.setState(prev => ({openPrintSummary: !prev.openPrintSummary}))

  handleSubmitPrintDialog = (selectedType: string) => {
   
  }
  handleExport = () => {}

  handleUserChange = (context: IUserContext) => {
    const apiKey = customPermissionApiKey.dashboardPermission;
    const userData = context.user?.attributes.permission_groups;
    const value = checkForDashboardPermissonStatus(apiKey, userData as unknown as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    });
  }

  handleOpenExportModal = () => {
    this.setState({
      openExportModal: !this.state.openExportModal
    })
  }

  handleExportConfirm = async (method: string) => {
    if (method === "pdf") {
      const wrapperElement = document.getElementById("print-wrapper") as HTMLElement
     await html2pdf(wrapperElement, {
        filename: "sale.pdf",
        jsPDF: {
          unit: "px",
          format: [1440 , 1440]
        },
        margin: 24,
        pagebreak: { mode: ['css'] },
      })
      this.setState({openExportModal: false})
    }
  }

  handleReturnColorType = () => {
    const { isAppliedFilter } = this.state;
    return isAppliedFilter ? "primary" : "inherit";
  };

  handleStorageFilter = () => {
    const applied_profession_filter = localStorage.getItem("filter_value");
    if (applied_profession_filter) {
      this.setState(
        {
          filters: JSON.parse(applied_profession_filter),
          isAppliedFilter: checkIsFilterApplied(
            JSON.parse(applied_profession_filter)
          ),
        },
        () => {
          this.getSectionSaleApiCalling()          
        }
      );
    } else {
      this.getSectionSaleApiCalling()
    }
  };

  // Customizable Area End
}