// Customizable Area Start
import React from "react";
import {
  styled,
  Box,
  Card,
  CardContent,
  Typography,
  CardHeader,
  StyledComponentProps,
  Divider,
  TextField,
  InputAdornment,
  TableRow as Row1,
  TableHead,
  Button,
  TableCell as Cell1,
  TableBody,
  Table,
  Hidden,
  DialogContent,
  Dialog,
  Tooltip,
} from "@material-ui/core";
import {
  TableRow,
  TableCell,
  OrderTable,
  RowContainer,
} from "../../../components/src/OrderTable/src";
import { Skeleton } from "@material-ui/lab";
import FilterPopover, { IFilter } from "../../../components/src/FilterPopover";
import { FilterList as FilterIcon } from "@material-ui/icons";
import PlantReportControlle, {
  Props,
  webConfigJSON,
} from "./PlantReportController.web";
export const tabString = webConfigJSON.Tabs.Strings;
export const cardString = webConfigJSON.Cards.Strings;
import DashboardHeader from "../../../components/src/DashboardHeader";
import { colors } from "../../utilities/src/Colors";
import { eyeIcon, searchIcon } from "./assets";
import { withStyles, Theme } from "@material-ui/core/styles";
import { PieChart, Pie, Tooltip as ChartTooltip, Cell, Sector } from "recharts";
import ExportModal from "../../../components/src//ExportModal";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import DashboardTabPanel from "../../../components/src/DashboardTabPanel.web";
const IconSortingUp = require("../../../components/src/assets/sort/sort top.png");
const IconSortingNormal = require("../../../components/src/assets/sort/sort.png");
const IconSortingDown = require("../../../components/src/assets/sort/sort down.png");
import { getGroupNameDisplay } from "../../cfsalesdashboard3/src/utils";
import { storeNameBasedOnGroup } from "./utils";

interface StyledCardHeaderProps {
  headerColor?: string;
  mainColor?: string;
}

interface RenderActiveShapeProps {
  cx: number;
  cy: number;
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
  fill: string;
}

const Colors = [
  "#EC4E20",
  "#059669",
  "#F59E0B",
  "#7F9CF5",
  "#10B981",
  "#DB2777",
  "#7C3AED",
  "#3B82F6",
  "#6B7280",
  "#4B5563",
  "#FF5733",
  "#C70039",
  "#900C3F",
  "#581845",
  "#1F618D",
  "#76D7C4",
  "#F4D03F",
  "#D35400",
  "#6C3483",
  "#27AE60",
  "#F5B7B1",
  "#5DADE2",
  "#AF7AC5",
  "#52BE80",
  "#F8C471",
];

interface BarDataStructure {
  section: string;
  orders: number;
  pieces: number;
  amount: string;
}

export const options = {
  pieHole: 0.7,
  legend: "none",
  colors: Colors,
  pieSliceText: "none",
};

const CustomTooltip1 = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-tooltip'>
        <p>{`No. Orders: ${payload[0].payload.payload.orders}`}</p>
        <p className='paddingtop'>{`Pieces: ${payload[0].value}`}</p>
        <p className='paddingtop'>{`Amount: SAR ${payload[0].payload.payload.amount}`}</p>
      </div>
    );
  }

  return null;
};

// Customizable Area End

export default class PlantReport extends PlantReportControlle {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  getCardColors = (title: string) => {
    let mainCardColor;
    let headerCardColor;
    let headerTitle;
    let planStatus;
    switch (title) {
      case "upcoming":
        mainCardColor = colors().mainCardUpcoming;
        headerCardColor = colors().cardHeaderUpcoming;
        headerTitle = cardString.upcoming;
        planStatus = "receiving_area";
        break;
      case "received":
        mainCardColor = colors().mainCardRecieved;
        headerCardColor = colors().cardHeaderRecieved;
        headerTitle = cardString.received;
        planStatus = "shorting_area";
        break;
      case "loading_area":
        mainCardColor = colors().mainCardLoadArea;
        headerCardColor = colors().cardHeaderLoadArea;
        headerTitle = cardString.loading_area;
        planStatus = "loading_area";
        break;
      case "dispatched":
        mainCardColor = colors().mainCardDispatched;
        headerCardColor = colors().cardHeaderDispatched;
        headerTitle = cardString.dispatched;
        planStatus = "dispatched";
        break;
      case "metal_progetti":
        mainCardColor = colors().mainCardMetal;
        headerCardColor = colors().cardHeaderMetal;
        headerTitle = cardString.metal_progetti;
        planStatus = "metal_progetti";
        break;
      case "due_today":
        headerTitle = cardString.dueToday;
        planStatus = "today";
        break;
      case "due_tomorrow":
        headerTitle = cardString.dueTomorrow;
        planStatus = "tomorrow";
        break;
      case "over_due":
        headerTitle = cardString.overdue;
        planStatus = "over_due";
        break;
      default:
        planStatus = "receiving_area";
        break;
    }
    return { mainCardColor, headerCardColor, headerTitle, planStatus };
  };

  renderCardDetails = () => {
    const {metalProgettiData} = this.state

    return (
      <>
        {this.state.cardDetails &&
          Object.entries(this.state.cardDetails).filter(([key, item])=> item).map(([key, item]) => (
            <>
              <StyledMainCard mainColor={this.getCardColors(key).mainCardColor}>
                <StyledCardHeader
                  headerColor={this.getCardColors(key).headerCardColor}
                  data-test-id='imageCardHeader'
                  title={
                    <Box className='mainheader'>
                      <Typography className='cardtitle'>
                        {this.getCardColors(key).headerTitle}
                      </Typography>
                      <img
                        data-test-id='card-eye-icon'
                        className='eyeiconstyle'
                        onClick={() =>
                          this.handleOpenDetailsModal(
                            this.getCardColors(key).planStatus
                          )
                        }
                        src={eyeIcon}
                      />
                    </Box>
                  }
                ></StyledCardHeader>
                <CardContent className='maincontent'>
                  <Box>
                    <Typography className='contenttitle'>Orders</Typography>
                    <Typography className='contentvalue'>
                      {item.count}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className='contenttitle'>Pieces</Typography>
                    <Typography className='contentvalue'>
                      {item.pieces}
                    </Typography>
                  </Box>
                </CardContent>
              </StyledMainCard>
            </>
          ))}
        <StyledMainCard
          mainColor={this.getCardColors("metal_progetti").mainCardColor}
        >
          <StyledCardHeader
            headerColor={this.getCardColors("metal_progetti").headerCardColor}
            data-test-id='imageCardHeader'
            title={
              <Box className='mainheader'>
                <Typography className='cardtitle'>
                  {this.getCardColors("metal_progetti").headerTitle}
                </Typography>
                <img
                  data-test-id='metal-progetti-detail'
                  className='eyeiconstyle'
                  src={eyeIcon}
                  onClick={() =>
                    this.handleOpenMetalProgettiModal()
                  }
                />
              </Box>
            }
          ></StyledCardHeader>
          <CardContent className='maincontent'>
            <Box>
              <Typography className='contenttitle'>Orders</Typography>
              <Typography className='contentvalue'>{metalProgettiData.in.order_count}</Typography>
            </Box>
            <Box>
              <Typography className='contenttitle'>Pieces</Typography>
              <Typography className='contentvalue'>{metalProgettiData.in.total_pieces}</Typography>
            </Box>
          </CardContent>
        </StyledMainCard>
      </>
    );
  };

  renderTotalItemtoClean = () => {
    const filteredData = this.state.topItemToCleanDetails
      .filter((item) =>
        item.name
          .toLowerCase()
          .includes(this.state.searchTopItemQuery.toLowerCase())
      )
      .slice(0, 4);
    return (
      <>
        <Typography className='mainlefttitle'>
          {webConfigJSON.topItemToCleanText}
          <div className='leftheader'>
            {!this.state.isSearchOpen ? (
              <img
                data-test-id='open-search-button'
                src={searchIcon}
                className='searchicnonbtn'
                onClick={this.handleSerchOpen}
              />
            ) : (
              <StyledTextField
                variant='outlined'
                inputProps={{
                  "data-test-id": "field-search",
                }}
                placeholder='Search'
                onChange={this.handleSearchCleanItem}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={"start"}>
                      <img src={searchIcon} className='searchicnon' />
                    </InputAdornment>
                  ),
                }}
              />
            )}

            <div
              data-test-id='filterTableItemBtn'
              className='filterButtonAccount'
              onClick={this.handleItemToCleanFilterButton}
            >
              <FilterIcon />
            </div>
            <FilterPopover
              onClose={this.handleItemToCleanCloseFilterButton}
              anchor={
                this.state.itemToCleanFilterAnchor as HTMLDivElement | undefined
              }
              onFilterChange={this.handleFilterChangeForItemtoClean}
              filters={this.state.itemToCleanFilters as IFilter[]}
              rangeValue1={this.state.rangeValue}
            />
          </div>
        </Typography>
        <Divider className='titledivider' />

        <Table aria-label='customized table'>
          <TableHead>
            <Row1>
              <CustomHeaderTableCell>Items</CustomHeaderTableCell>
              <CustomHeaderTableCell>Quantity</CustomHeaderTableCell>
              <CustomHeaderTableCell>Pieces</CustomHeaderTableCell>
            </Row1>
          </TableHead>
          <TableBody>
            {filteredData.map((item, index) => (
              <Row1 key={index}>
                <CustomTableCell align='left'>{item.name}</CustomTableCell>
                <CustomTableCell align='left'>{item.quantity}</CustomTableCell>
                <CustomTableCell align='left'>
                  {item.total_pieces}
                </CustomTableCell>
              </Row1>
            ))}
          </TableBody>
        </Table>
        {filteredData.length > 3 && (
          <Button
            onClick={() => this.handleOpenItemModal()}
            data-test-id='viewmoreButton'
            className={"viewmoreButton"}
          >
            View More
          </Button>
        )}
      </>
    );
  };

  renderPlanLegends = (lable: string, color: string) => {
    const { chartLoader } = this.state;

    return (
      <Box className='graphLegendsBody'>
        <Box className='LegendIcon' style={{ background: color }} />
        {!chartLoader.isDryLoading && !chartLoader.isLaundryLoading && !chartLoader.isPressingLoading ? (
          <Typography className='lable-12-400'>{lable}</Typography>
        ) : (
          <Skeleton animation='wave' className="skeletonlegen" />
        )}
      </Box>
    );
  };

  renderColumnLimit1 = (limit: string) => {
    return (
      <Box className='columnLimitContainer'>
        <Typography align='right' className='lable-12-400 coolGrey mw40'>
          {limit}
        </Typography>
        <Box className='columnLimitLine' />
      </Box>
    );
  };

  getModalTitle = (title: string) => {
    let planStatusTitle;
    switch (title) {
      case "receiving_area":
        planStatusTitle = "Upcoming";
        break;
      case "shorting_area":
        planStatusTitle = "Received";
        break;
      case "loading_area":
        planStatusTitle = "Loading Area";
        break;
      case "dispatched":
        planStatusTitle = "Dispatched";
        break;
      case "metal_progetti":
        planStatusTitle = "Metal Progetti";
        break;
      case "today":
        planStatusTitle = "Due Today";
        break;
      case "tomorrow":
        planStatusTitle = "Due Tomorrow";
        break;
      case "over_due":
        planStatusTitle = "Overdue";
        break;
      default:
        break;
    }
    return { planStatusTitle };
  };

  renderCardDetailsModal() {
    let modalHeader = ["Sections", "Orders", "Pieces"];
    if (this.state.isCleanDueDet) {
      modalHeader = ["Sections", "Orders"];
    }
    if (this.state.plantStatusTilte === "metal_progetti") {
      modalHeader = ["Status", "Orders"]
    }
    return (
      <OrderDetailsDialog
        maxWidth={"sm"}
        open={this.state.isCardModal}
        fullWidth
      >
        <DialogContent className='p-24'>
          <StyledWrapper>
            <Typography className='dialogutitle'>
              {this.getModalTitle(this.state.plantStatusTilte).planStatusTitle}
            </Typography>
            <OrderTable>
              <RowContainer header>
                <TableRow columns={this.getTableRowColumnsForOrderDetails()}>
                  {modalHeader.map((heading: string) => {
                    return (
                      <TableCell
                        header
                        content={heading}
                        className='align-item-center'
                      />
                    );
                  })}
                </TableRow>
              </RowContainer>
              <RowContainer>
                {this.state.cardDetailsModal.map((item) => {
                  const { section, orders, pieces } = item;
                  let modalItem = [section, orders, pieces];
                  if (this.state.isCleanDueDet) {
                    modalItem = [section, orders];
                  }
                  return (
                    <TableRow
                      columns={this.getTableRowColumnsForOrderDetails()}
                    >
                      {modalItem.map((heading: unknown, index: number) => (
                        <TableCell
                          content={heading as React.ReactNode}
                          type={this.getTypeOfOrderItemTable(index)}
                        />
                      ))}
                    </TableRow>
                  );
                })}
              </RowContainer>
            </OrderTable>
            <BottomContainer>
              <button
                data-test-id='btn-cancel'
                onClick={this.handleCloseCardModal}
                className='cancel'
              >
                Cancel
              </button>
            </BottomContainer>
          </StyledWrapper>
        </DialogContent>
      </OrderDetailsDialog>
    );
  }

  renderTopItemsModal() {
    let modalHeader = [
      { title: "Items", sortingKey: "items" },
      { title: "Quantity", sortingKey: "quantity" },
      { title: "Pieces", sortingKey: "pieces" },
    ];
    const filteredData = this.state.topItemToCleanDetails.filter((item) =>
      item.name
        .toLowerCase()
        .includes(this.state.searchTopItemQuery.toLowerCase())
    );
    return (
      <OrderDetailsDialog
        open={this.state.openTopItemModal}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogContent className='p-24'>
          <StyledWrapper>
            <Typography className='dialogutitle'>Top Items to Clean</Typography>
            <div className='searchitem'>
              <StyledTextFieldModal
                variant='outlined'
                inputProps={{
                  "data-test-id": "field-search",
                }}
                onChange={this.handleSearchCleanItem}
                placeholder='Search'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={"start"}>
                      <img src={searchIcon} className='searchicnon' />
                    </InputAdornment>
                  ),
                }}
              />

              <div
                className='filterButtonAccount'
                data-test-id='filterTableBtn'
                onClick={this.handleItemToCleanFilterButton}
              >
                <FilterIcon />
              </div>
              <FilterPopover
                anchor={
                  this.state.itemToCleanFilterAnchor as
                    | HTMLDivElement
                    | undefined
                }
                onClose={this.handleItemToCleanCloseFilterButton}
                onFilterChange={this.handleFilterChangeForItemtoClean}
                filters={this.state.itemToCleanFilters as IFilter[]}
                rangeValue1={this.state.rangeValue}
              />
            </div>
            <OrderTable>
              <RowContainer header>
                <TableRow columns={this.getTableRowColumnsForOrderDetails()}>
                  {modalHeader.map((heading) => {
                    return (
                      <TableCell
                        header
                        className='align-item-center'
                        content={heading.title}
                        sortingIcons={[
                          IconSortingNormal,
                          IconSortingUp,
                          IconSortingDown,
                        ]}
                        sortingKey={heading.sortingKey}
                        sortingDisabled={!Boolean(heading.sortingKey)}
                        {...this.getSortingProps()}
                      />
                    );
                  })}
                </TableRow>
              </RowContainer>
              <RowContainer>
                <div
                  className='scollstyle'
                  style={{ overflow: "auto", maxHeight: "368px" }}
                  data-test-id='scroll-item'
                  onScroll={this.handleItemScroll}
                >
                  {filteredData.map((item) => {
                    const { name, quantity, total_pieces } = item;
                    let modalItem = [name, quantity, total_pieces];
                    return (
                      <TableRow
                        columns={this.getTableRowColumnsForOrderDetails()}
                      >
                        {modalItem.map((heading: unknown, index: number) => (
                          <TableCell
                            type={this.getTypeOfOrderItemTable(index)}
                            content={heading as React.ReactNode}
                          />
                        ))}
                      </TableRow>
                    );
                  })}
                </div>
              </RowContainer>
            </OrderTable>
            <BottomContainer>
              <button
                onClick={this.handleCloseTopItemModal}
                data-test-id='btn-cancel-topitem'
                className='cancel'
              >
                {webConfigJSON.cancelBtnText}
              </button>
            </BottomContainer>
          </StyledWrapper>
        </DialogContent>
      </OrderDetailsDialog>
    );
  }

  getCleaningDetails = (title: string) => {
    let cleaningColor;
    let cleaningTitle;
    switch (title) {
      case "due_today":
        cleaningColor = colors().dueTodayColor;
        cleaningTitle = "Due Today";
        break;
      case "due_tomorrow":
        cleaningColor = colors().dueTomorrowColor;
        cleaningTitle = "Due Tomorrow";
        break;
      case "over_due":
        cleaningColor = colors().overdueColor;
        cleaningTitle = "Overdue";
        break;
      default:
        break;
    }
    return { cleaningColor, cleaningTitle };
  };

  getDataAsPerSection = (section: string) => {
    const chartData = this.state.chartData;
    let chartSectionData: {
      section: string;
      orders: number;
      pieces: number;
      amount: string;
    }[] = [];
    const formattedChartData: any = [];

    switch (section) {
      case "Laundry":
        chartSectionData = chartData.laundryData;
        chartData.laundryData.forEach((item) => {
          formattedChartData.push({
            name: item.section,
            value: item.pieces,
            orders: item.orders,
            amount: item.amount,
          });
        });
        break;
      case "Dry Cleaning":
        chartSectionData = chartData.dryCleanData;
        chartData.dryCleanData.forEach((item) => {
          formattedChartData.push({
            name: item.section,
            value: item.pieces,
            orders: item.orders,
            amount: item.amount,
          });
        });
        break;
      case "Pressing":
        chartSectionData = chartData.pressingData;
        chartData.pressingData.forEach((item) => {
          formattedChartData.push({
            name: item.section,
            value: item.pieces,
            orders: item.orders,
            amount: item.amount,
          });
        });
        break;
      default:
        break;
    }

    return { chartSectionData, formattedChartData };
  };

  renderActiveShape = (props: RenderActiveShapeProps) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
      props;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius + 1}
          outerRadius={outerRadius + 1}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };

  getRenderChart = () => {
    function formatNumber(num: number) {
      if (num >= 1e9) {
        return (num / 1e9).toFixed(1) + "B";
      }
      if (num >= 1e6) {
        return (num / 1e6).toFixed(1) + "M";
      }
      if (num >= 1e3) {
        return (num / 1e3).toFixed(1) + "k";
      }
      return num.toString();
    }

    function generateSteps(data: BarDataStructure[], numberOfSteps: number) {
      const maxValue = Math.max(...data.map((dataObject) => dataObject.pieces));
      const bufferedMaxValue = maxValue * 1.1;
      const stepSize = Math.ceil(bufferedMaxValue / numberOfSteps);
      const steps = [];
      for (let i = 1; i <= numberOfSteps; i++) {
        steps.push({
          formatted: formatNumber(i * stepSize),
          raw: i * stepSize,
        });
      }
      return steps.reverse();
    }

    function calculateBarHeight(
      value: number,
      maxValue: number,
      maxHeight: number
    ) {
      const heightPercentage = (value / maxValue) * 100 || 0;
      const barHeight = (heightPercentage / 100) * maxHeight;
      return `${Math.max(parseFloat(barHeight.toFixed(2)), 2)}px`;
    }

    function getBarHeight(data: BarDataStructure[], section: string) {
      const correction = 0;
      let limitArray = generateSteps(data, 5);
      let maxLimit = limitArray[0].raw;
      const newData = data.map((item) => ({
        label: item.section,
        value: item.pieces,
        height: calculateBarHeight(
          item.pieces,
          maxLimit,
          parseInt("160px") - correction
        ),
      }));
      return newData;
    }

    const { isDryLoading, isLaundryLoading, isPressingLoading } =
      this.state.chartLoader;

    return (
      <StylesMainCharContainer>
        {this.state.chartDetails.map((item,chartindex) => (
          <StyledChartBox>
            <Box
              id='paymentSummaryHeaderContainer'
              className='paymentSummaryHeaderContainer'
            >
              <Typography id='title' className='lable-16-700'>
                {item.sections}
              </Typography>
            </Box>
            <Divider className='chartdivider' />
            <Typography className='desctitlt'>
              X axis - Categories, Y axis - No. of Pieces
            </Typography>
            {!isDryLoading && !isLaundryLoading && !isPressingLoading ? (
              <StyledChartDIV>
                <Box id='columnGraphContainer' className='columnGraphContainer'>
                  {generateSteps(
                    this.getDataAsPerSection(item.sections).chartSectionData,
                    5
                  ).map((data) => this.renderColumnLimit1(data.formatted))}

                  <Box className='relative'>
                    <Box className='columnLimitContainer'>
                      <Typography
                        align='right'
                        className='lable-12-400 coolGrey mw40'
                      >
                        {"0"}
                      </Typography>
                      <Box className='columnLimitLine' />
                    </Box>

                    <Box className='columnLimitContainer columnBarBase'>
                      <Box className='mw40' />
                      <Box className='graphControlBox'>
                        <Box className='graphScrollBox'>
                          <Box className='columnHBarContainerBody'>
                            {getBarHeight(
                              this.getDataAsPerSection(item.sections)
                                .chartSectionData,
                              item.sections
                            ).map((data, index) => (
                              <CustomTooltip
                                placement='top'
                                title={`${data.label}: Pieces ${data.value}`}
                              >
                                <Box
                                  height={data.height}
                                  style={{ background: Colors[index] }}
                                  className='columnBar'
                                />
                              </CustomTooltip>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Typography id='title1' className='lableBottom'>
                    Categories
                  </Typography>
                </Box>
                <ChartDiv>
                  <PieChart
                    width={200}
                    height={332}
                    style={{ top: "-95px" }}
                    className='piechartstyleres'
                  >
                    <Pie
                      id='piecharttestid'
                      data-test-id='piecharttest'
                      activeIndex={this.state.activeIndexes[chartindex]} 
                      activeShape={
                        this.renderActiveShape as Object | React.ReactElement
                      }
                      data={
                        this.getDataAsPerSection(item.sections)
                          .formattedChartData
                      }
                      cx={115}
                      cy={200}
                      innerRadius={60}
                      outerRadius={80}
                      fill='#8884d8'
                      paddingAngle={2}
                      dataKey='value'
                      onMouseEnter={(event: React.MouseEvent, index: number) =>
                        this.handleMouseActiveEnter(event, index,chartindex)
                      }
                      onMouseLeave={() => this.handleMouseActiveLeave(chartindex)}
                    >
                      {this.getDataAsPerSection(
                        item.sections
                      ).chartSectionData.map((entry, index: number) => (
                        <Cell key={`cell-${index}`} fill={Colors[index]} />
                      ))}
                    </Pie>
                    <ChartTooltip
                      wrapperStyle={{
                        width: "80%",
                        top: "-55px",
                      }}
                      content={<CustomTooltip1 />}
                    />
                  </PieChart>
                </ChartDiv>
              </StyledChartDIV>
            ) : (
              <SkeletonDiv>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <Skeleton animation='wave' className="skeletonline"  />
                  <Skeleton animation='wave' className="skeletonline"  />
                  <Skeleton animation='wave' className="skeletonline"  />
                  <Skeleton animation='wave' className="skeletonline"  />
                  <Skeleton animation='wave' className="skeletonline"  />
                  <Skeleton animation='wave' className="skeletonline"  />
                </Box>

                <Box>
                  <Skeleton variant='circle' className="skeletoncircle"/>
                </Box>
              </SkeletonDiv>
            )}

            <div id='graphLegendsContainer' className='graphLegendsContainer'>
              {this.getDataAsPerSection(item.sections).chartSectionData.map(
                (data, index) => {
                  return this.renderPlanLegends(data.section, Colors[index]);
                }
              )}
            </div>
          </StyledChartBox>
        ))}
      </StylesMainCharContainer>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <DashboardTabPanel
          navigation={this.props.navigation}
          id='PlantReport'
          permissionStatus={this.state.permissionStatus} 
        />
          
        <StyledWrapper id={sortCondition(this.state.exportModalStatus, "print-wrapper-plantReport", undefined) as string}>
          <MainBodyBox>
            <DashboardHeader
              filterLoading={this.state.chartLoader.isDryLoading || this.state.chartLoader.isPressingLoading || this.state.chartLoader.isPressingLoading}
              isAppliedFilter={this.state.isAppliedFilter}
              regionVal={this.state.selectedRegion}
              storeVal={storeNameBasedOnGroup(this.state.storeId)}
              isGroup={true}
              groupVal={getGroupNameDisplay(this.state.groupId)}
              handleFilterButton={this.handleFilterButton}
              handleCloseFilterButton={this.handleCloseFilterButton}
              handleFilterChange={this.handleFilterChange}
              filterAnchor={
                this.state.filterAnchor as HTMLDivElement | undefined
              }
              filters={this.state.filters as IFilter[]}
              periodDateRange={this.getPeriodDate(
                this.handleDateFilterParam().startDateText,
                this.handleDateFilterParam().endDateText
              )}
              elementAfterFilter={
                  <button
                    type="button"
                    onClick={() => this.handleExportModal()}
                    className="exportBtn"
                  >
                    {webConfigJSON.exportAll}
                  </button>
              }
            />
            <Box className='maincardbox'>{this.renderCardDetails()}</Box>
            <Box> {this.getRenderChart()} </Box>
            <GridContainer>
              <StyledLeftBox>{this.renderTotalItemtoClean()}</StyledLeftBox>
              <Box>
                {" "}
                <StyledRightBox>
                  {" "}
                  <Typography className='lefttitle'>Cleaning</Typography>
                  <Divider />
                  <MainDueBox>
                    {this.state.cleaningDetails &&
                      Object.entries(this.state.cleaningDetails).filter(([key, item])=> item).map(
                        ([key, item]) => (
                          <Box>
                            <StyledCleanigTypograpgy
                              headerColor={
                                this.getCleaningDetails(key).cleaningColor
                              }
                            >
                              {this.getCleaningDetails(key).cleaningTitle}
                            </StyledCleanigTypograpgy>
                            <Box className='duevaluestyle'>
                              <Typography className='cleancardtext'>
                                Pieces
                              </Typography>
                              <Typography className='valuestyle'>
                                {item.pieces}
                              </Typography>
                            </Box>
                            <Box className='duevaluestyle'>
                              <Typography className='cleancardtext'>
                                Orders
                              </Typography>
                              <Box className='ordereyestyle'>
                                <Typography className='ordertextclean'>
                                  {item.orders}
                                </Typography>
                                <div>
                                  <img
                                    data-test-id='order-eye-icon'
                                    className='ordereyeiconstyle'
                                    onClick={() =>
                                      this.handleOpenCleaningDueDetailsModal(
                                        this.getCardColors(key).planStatus
                                      )
                                    }
                                    src={eyeIcon}
                                  />
                                </div>
                              </Box>
                            </Box>
                            {key !== "over_due" ? (
                              <Box className='duevaluestyle'>
                                <Typography className='cleancardtext'>
                                  Completed
                                </Typography>
                                <Typography className='comvaluestyle'>
                                  {item.completed
                                    ? item.completed.toFixed(2)
                                    : 0}
                                  %
                                </Typography>
                              </Box>
                            ) : (
                              <Hidden>
                                <Box className='disableduevaluestyle'>
                                  <Typography className=''>
                                    Completed
                                  </Typography>
                                  <Typography className='comvaluestyle'>
                                    {item.completed
                                      ? item.completed.toFixed(2)
                                      : 0}
                                    %
                                  </Typography>
                                </Box>
                              </Hidden>
                            )}
                          </Box>
                        )
                      )}
                  </MainDueBox>
                </StyledRightBox>
              </Box>
            </GridContainer>
          </MainBodyBox>
          {this.renderCardDetailsModal()}
          {this.renderTopItemsModal()}
        </StyledWrapper>
      
        {
          this.state.exportModalStatus && <ExportModal isOpen onCancel={this.handleExportModal} onConfirm={this.handleExportConfirm} />
        }

      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

export const StyledWrapper = styled("div")({
  "& .exportBtn": {
    display: "flex",
    fontSize: "16px",
    marginLeft: "14px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    background: colors().lightgray,
    fontWeight: 600,
    cursor: "pointer",
    height: "44px",
    border: 0,
    padding: "0 16px",
    color: colors().darkliver,
    "@media only screen and (max-width: 1366px)": {
      height: 32,
      fontSize: "11px",
      width: "auto"
    },
    "@media only screen and (max-width: 1280px)": {
      height: 32,
      width: "auto",
      fontSize: "11px",
    },
    "@media only screen and (max-width: 1024px)": {
      height: 32,
      width: "auto",
      fontSize: "11px"
    }
  },
  "& .piechartstyleres": {
    top: "-95px !important",
    height: "332px !important",
    width: "200px !important",
    "@media only screen and (max-width: 1366px)": {
      width: "140px !important",
      top: "-55px !important",
      height: "300px !important",
    },
    "@media only screen and (max-width: 1280px)": {
      width: "140px !important",
      top: "-55px !important",
      height: "300px !important",
    },
    "@media only screen and (max-width: 1024px)": {
      width: "115px !important",
      top: "-55px !important",
      height: "300px !important",
    },
  },
  "& .tableContainer": {
    flex: "1 1 0",
  },
  "& .headerWrapper": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: 12,
    alignItems: "center",
  },
  "& .PageHeading": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().darkjunglegreen,
    "@media only screen and (max-width: 1366px)": {
      fontSize: "11px",
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: "11px",
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "11px",
    },
  },
  "& .storeval": {
    marginLeft: "50px",
  },
  "& .datePeriod": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    "@media only screen and (max-width: 1366px)": {
      fontSize: "11px",
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: "11px",
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "11px",
    },
  },
  "& .d-flex": {
    display: "flex",
  },
  "& .ordereyestyle": {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  "& .ordertextclean": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    "@media only screen and (max-width: 1366px)": {
      fontSize: 11,
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: 11,
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: 11,
    },
  },
  "& .valuestyle": {
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    marginRight: "30px",
    "@media only screen and (max-width: 1366px)": {
      fontSize: 11,
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: 11,
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: 11,
    },
  },
  "& .comvaluestyle": {
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    marginRight: "10px",
    "@media only screen and (max-width: 1366px)": {
      fontSize: 11,
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: 11,
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: 11,
    },
  },
  "& .ordereyeiconstyle": {
    width: "26px",
    height: "26px",
    "@media only screen and (max-width: 1366px)": {
      height: "17px",
      width: "17px",
    },
    "@media only screen and (max-width: 1280px)": {
      height: "17px",
      width: "17px",
    },
    "@media only screen and (max-width: 1024px)": {
      height: "17px",
      width: "17px",
    },
  },
  "& .filterButtonAccount": {
    position: "relative",
    cursor: "pointer",
    background: colors().white,
    height: "41px",
    marginRight: "4px",
    color: colors().darkliver,
    width: "44px",
    borderRadius: "8px",
    display: "flex",
    fontSize: "24px",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid",
    borderColor: colors().lightborder,
    "@media only screen and (max-width: 1366px)": {
      width: 32,
      height: 32,
    },
    "@media only screen and (max-width: 1280px)": {
      height: 32,
      width: 32,
    },
    "@media only screen and (max-width: 1024px)": {
      width: 32,
      height: 32,
    },
    "&.active-border": {
      borderColor: colors().cyancobaltblue,
    },
    "& .filterActiveIcon": {
      position: "absolute",
      background: "#204B9C",
      top: "-5px",
      right: "-5px",
      minWidth: "15px",
      minHeight: "15px",
      borderRadius: "50%"
    }
  },
  "& .filterButtonModal": {
    marginRight: "4px",
    cursor: "pointer",
    background: colors().white,
    height: "32px",
    color: colors().darkliver,
    fontSize: "24px",
    borderRadius: "8px",
    width: "32px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    border: "1px solid",
    borderColor: colors().lightborder,
    "@media only screen and (max-width: 1366px)": {
      height: 32,
      width: 32,
    },
    "@media only screen and (max-width: 1280px)": {
      width: 32,
      height: 32,
    },
    "@media only screen and (max-width: 1024px)": {
      height: 32,
      width: 32,
    },
  },
  "& .titledivider": {
    marginTop: "12px",
  },
  "& .searchicnon": {
    marginBottom: "2px",
  },
  "& .searchicnonbtn": {
    padding: "13px",
    border: "1px solid #ECECEC",
    borderRadius: "8px",
    cursor: "pointer",
    "@media only screen and (max-width: 1366px)": {
      padding: "6px",
    },
    "@media only screen and (max-width: 1280px)": {
      padding: "6px",
    },
    "@media only screen and (max-width: 1024px)": {
      padding: "6px",
    },
  },
  "& .eyeiconstyle": {
    cursor: "pointer",
    "@media only screen and (max-width: 1366px)": {
      height: "17px",
      width: "17px",
    },
    "@media only screen and (max-width: 1280px)": {
      height: "17px",
      width: "17px",
    },
    "@media only screen and (max-width: 1024px)": {
      height: "17px",
      width: "17px",
    },
  },
  "& .maincardbox": {
    display:"flex",
    justifyContent: "space-between",
    gap:"1rem",
    "@media only screen and (max-width: 1280px)": {
      gridTemplateColumns: "repeat(3,1fr)",
    },
    "@media only screen and (max-width: 1024px)": {
      gridTemplateColumns: "repeat(2,1fr)",
    },
  },
  "& .dialogutitle": {
    fontSize: "24px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    color: colors().viewTextColor,
    textAlign: "center",
    marginBottom: "16px",
    "@media only screen and (max-width: 1366px)": {
      fontSize: 19,
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: 19,
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "19px",
    },
  },
  "& .searchitem": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginBottom: "18px",
  },
  "& .cardtitle": {
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    color: colors().darkjunglegreen,
    "@media only screen and (max-width: 1366px)": {
      fontSize: 11,
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: 11,
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: 11,
    },
  },
  "& .maincontent": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px !important",
  },
  "& .contenttitle": {
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    color: colors().darkliver,
    "@media only screen and (max-width: 1366px)": {
      fontSize: 9,
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: 9,
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: 9,
    },
  },
  "& .contentvalue": {
    fontSize: "19px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    color: colors().darkjunglegreen,
    marginTop: "10px",
    "@media only screen and (max-width: 1366px)": {
      fontSize: 11,
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: 11,
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: 11,
    },
  },
  "& .mainheader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .lefttitle": {
    fontSize: "19px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    color: colors().black,
    "@media only screen and (max-width: 1366px)": {
      fontSize: 13,
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: 13,
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: 13,
    },
  },
  "& .relative": {
    position: "relative",
  },
  "& .mainlefttitle": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "19px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    color: colors().black,
    "@media only screen and (max-width: 1366px)": {
      fontSize: 13,
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: 13,
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
    },
  },
  "& .cleancardtext": {
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    "@media only screen and (max-width: 1366px)": {
      fontSize: 11,
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: 11,
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "11px",
    },
  },
  "& .duevaluestyle": {
    marginTop: "12px",
    backgroundColor: "#F1F5F9",
    padding: "12 12 8 12",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "cneter",
    width: "210px",
    "@media only screen and (max-width: 1366px)": {
      width: "151px",
    },
    "@media only screen and (max-width: 1280px)": {
      width: "151px",
    },
    "@media only screen and (max-width: 1024px)": {
      width: "151px",
    },
  },
  "& .disableduevaluestyle": {
    marginTop: "12px",
    backgroundColor: "white",
    color: "white",
    padding: "12 12 8 12",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "cneter",
    width: "210px",
  },
  "& .columnBar": {
    width: "14px",
    borderRadius: "4px 4px 0px 0px",
  },
  "& .columnGraphContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    width: "172px",
    "@media only screen and (max-width: 1366px)": {
      width: "140px",
    },
    "@media only screen and (max-width: 1280px)": {
      width: "140px",
    },
    "@media only screen and (max-width: 1024px)": {
      width: "120px",
    },
  },
  "& .lableBottom": {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    position: "relative",
    left: "15px",
    bottom: "12px",
    textAlign: "center",
    color: colors().darkliver,
  },
  "& .columnLimitContainer": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  "& .columnLimitLine": {
    borderRadius: "1px",
    width: "100%",
    height: "1px",
    background: "#CBD5E1",
  },
  "& .graphLegendsContainer": {
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
    padding: "15px",
    paddingTop: 0,
  },
  "& .skeletonlegen": {
    width: "95px",
    "@media only screen and (max-width: 1366px)": {
    },
    "@media only screen and (max-width: 1280px)": {
    },
    "@media only screen and (max-width: 1024px)": {
      width: "55px",
    },
  },
  "& .columnHBarContainerBody": {
    width: "fit-content",
    padding: "0 10px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-end",
    gap: "14px",
    "@media only screen and (max-width: 1366px)": {
      gap: "8px",
      padding: "0 0px",
    },
    "@media only screen and (max-width: 1280px)": {
      gap: "8px",
      padding: "0 0px",
    },
    "@media only screen and (max-width: 1024px)": {
      gap: "4px",
      padding: "0 0px",
    },
  },
  "& .graphLegendsBody": {
    display: "flex",
    gap: "5px",
    alignItems: "center",
  },
  "& .LegendIcon": {
    width: "12px",
    height: "12px",
    borderRadius: "4px",
  },
  "& .barContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  "& .paymentOptionContainer": {
    padding: "8px 16px",
    marginRight: "-68px",
    zIndex: 1,
  },
  "& .lable-16-700": {
    fontSize: "19px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    marginBottom: "-8px",
    "@media only screen and (max-width: 1366px)": {
      fontSize: 14,
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: 14,
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: 14,
    },
  },
  "& .chartdivider": {
    marginRight: "18px",
    marginLeft: "10px",
  },
  "& .lable-12-400": {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: 400,
  },
  "& .desctitlt": {
    fontSize: "10px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    color: colors().darkjunglegreen,
    padding: "25px",
    paddingBottom: 0,
  },
  "& .mw40": {
    minWidth: "30px",
  },
  "& .coolGrey": {
    color: "#64748B",
  },
  "& .columnBarBase": {
    position: "absolute",
    width: "100%",
    bottom: "9px",
  },
  "& .columnBarContainerBody": {
    width: "100%",
    padding: "0 10px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-end",
  },
  "& .custom-tooltip": {
    background: "#E2E8F0",
    padding: "10px",
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    border: "1px solid #ddd",
    borderRadius: "3px",
    pointerEvents: "none",
    paddingTop: "10px",
    zIndex: 1000,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  },
  "& .paddingtop": {
    paddingTop: "6px",
  },
  "&#print-wrapper-plantReport": {
    "& .exportBtn, .filterButtonAccount" :{
      display:"none"
    }
  }
});

export const MainBodyBox = styled(Box)({
  marginTop: "0px",
});

const StyledCardHeader = styled(CardHeader)(
  (props: StyledComponentProps & StyledCardHeaderProps) => ({
    borderRadius: "12px",
    backgroundColor: props.headerColor,
    height: "52px",
    "@media only screen and (max-width: 1366px)": {
      height: "31px",
    },
    "@media only screen and (max-width: 1280px)": {
      height: "31px",
    },
    "@media only screen and (max-width: 1024px)": {
      height: "31px",
    },
  })
);

const StyledMainCard = styled("div")(
  (props: StyledComponentProps & StyledCardHeaderProps) => ({
    marginTop: "20px",
    width: "216px",
    height: "129px",
    backgroundColor: props.mainColor,
    borderRadius: "12px",
    "@media only screen and (max-width: 1366px)": {
      height: "98px",
    },
    "@media only screen and (max-width: 1280px)": {
      height: "98px",
    },
    "@media only screen and (max-width: 1024px)": {
      height: "98px",
    },
  })
);

const GridContainer = styled("div")({
  gridGap: 32,
  gridTemplateColumns: "0.6fr 1fr",
  marginTop: "30px",
  display: "grid",
  marginBottom: "20px",
  "@media only screen and (max-width: 1366px)": {
    gridGap: 14,
  },
  "@media only screen and (max-width: 1280px)": {
    gridGap: 14,
  },
  "@media only screen and (max-width: 1024px)": {
    gridGap: 14,
  },
});

const StyledLeftBox = styled(Box)({
  border: "1px solid",
  borderColor: colors().lightborder,
  borderRadius: "12px",
  padding: "16px",

  "& .leftheader": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  "& .viewmoreButton": {
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().white,
    height: "44px",
    width: "100%",
    borderRadius: "8px",
    textTransform: "unset",
    "@media only screen and (max-width: 1366px)": {
      fontSize: 11,
      height: "31px",
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: 11,
      height: "31px",
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: 11,
      height: "31px",
    },
  },
});

const StyledRightBox = styled("div")({
  border: "1px solid",
  borderColor: colors().lightborder,
  borderRadius: "12px",
  padding: "16px",
});

const StyledChartBox = styled("div")({
  border: "1px solid",
  borderColor: colors().lightborder,
  borderRadius: "12px",
  marginTop: "20px",
  "@media only screen and (max-width: 1366px)": {
    width: "360px",
  },
  "@media only screen and (max-width: 1280px)": {
    width: "360px",
  },
  "@media only screen and (max-width: 1024px)": {
    width: "360px",
  },
});

const StyledChartDIV = styled("div")({
  padding: "10px",
  display: "flex",
  marginTop: "20px",
  marginBottom: "-100px",
});

export const StyledTextField = styled(TextField)({
  borderRadius: 8,
  border: "1px solid var(--border, #ECECEC)",
  "& .MuiInputBase-input": {
    fontSize: "14px",
    width: "80px",
    height: "2px",
  },
  "@media only screen and (max-width: 1366px)": {
    "& .MuiInputBase-input": {
      padding: "14.5px 14px",
      fontSize: "12px",
      width: "45px !important",
    },
  },
  "@media only screen and (max-width: 1280px)": {
    "& .MuiInputBase-input": {
      padding: "14.5px 14px",
      fontSize: "12px",
      width: "45px !important",
    },
  },
  "@media only screen and (max-width: 1024px)": {
    "& .MuiInputBase-input": {
      padding: "14.5px 14px",
      fontSize: "12px",
      width: "45px !important",
    },
  },
});

export const StyledTextFieldModal = styled(TextField)({
  borderRadius: 8,
  border: "1px solid var(--border, #ECECEC)",
  width: "100%",
  "& .MuiInputBase-input": {
    fontSize: "14px",
    width: "100%",
    height: "0px",
    padding: "15.5px 0px",
  },
});

const CustomTableCell = styled(Cell1)({
  borderBottom: "none",
  fontSize: "19px",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 500,
  color: colors().darkjunglegreen,
  "@media only screen and (max-width: 1366px)": {
    fontSize: 11,
  },
  "@media only screen and (max-width: 1280px)": {
    fontSize: 11,
  },
  "@media only screen and (max-width: 1024px)": {
    fontSize: 11,
  },
});

const CustomHeaderTableCell = styled(Cell1)({
  borderBottom: "none",
  fontSize: "16px",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 400,
  color: colors().darkjunglegreen,
  "@media only screen and (max-width: 1366px)": {
    fontSize: 11,
  },
  "@media only screen and (max-width: 1280px)": {
    fontSize: 11,
  },
  "@media only screen and (max-width: 1024px)": {
    fontSize: 11,
  },
});

const MainDueBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
  "@media only screen and (max-width: 1366px)": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "none",
    marginTop: "20px",
    gap: "16px",
  },
  "@media only screen and (max-width: 1280px)": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "none",
    marginTop: "20px",
    gap: "16px",
  },
  "@media only screen and (max-width: 1024px)": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "none",
    marginTop: "20px",
    gap: "16px",
  },
});

export const OrderDetailsDialog = styled(Dialog)({
  "& .MuiDialog-paper.MuiPaper-rounded": {
    borderRadius: 24,
  },
  "& .p-24": {
    padding: 24,
  },
  "& .p-32": {
    padding: 32,
  },
});

const StyledCleanigTypograpgy = styled(Typography)(
  (props: StyledComponentProps & StyledCardHeaderProps) => ({
    backgroundColor: props.headerColor,
    padding: "12 12 8 12",
    borderRadius: "8px",
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    color: colors().background,
    width: "210px",
    "@media only screen and (max-width: 1366px)": {
      fontSize: 11,
      width: "151px",
      padding: "9 12 8 12",
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: 11,
      width: "151px",
      padding: "9 12 8 12",
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: 11,
      width: "151px",
      padding: "9 12 8 12",
    },
  })
);

const BottomContainer = styled("div")({
  gap: 32,
  display: "flex",
  paddingTop: 30,
  justifyContent: "center",
  "& > button": {
    fontSize: 16,
    padding: 16,
    minWidth: 126,
    border: "none",
    fontWeight: 700,
    borderRadius: 8,
    cursor: "pointer",
    lineHeight: "24px",
    alignItems: "center",
    display: "inline-flex",
    justifyContent: "center",
    fontFamily: "Montserrat",
    "&:disabled": {
      opacity: 0.5,
    },
  },
  "& > .cancel": {
    color: "#64748B",
    background: "var(--primary, #F1F5F9)",
    width: "186px",
    height: "44px",
    "@media only screen and (max-width: 1366px)": {
      width: "126px",
      height: "44px",
    },
    "@media only screen and (max-width: 1280px)": {
      width: "126px",
      height: "44px",
    },
    "@media only screen and (max-width: 1024px)": {
      width: "126px",
      height: "44px",
    },
  },
});

const StylesMainCharContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
  "& .piechartstyle": {
    zIndex: -1,
    bottom: "37px",
  },
  "& .paymentSummaryHeaderContainer": {
    padding: "20px",
  },
});

const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const ChartDiv = styled("div")({
  position: "relative",
});

const SkeletonDiv = styled("div")({
  display: "flex", 
  gap: "79px", 
  padding: "33px",
  "@media only screen and (max-width: 1366px)": {
    gap: "25px", 
    padding: "26px",
  },
  "@media only screen and (max-width: 1280px)": {
    gap: "29px", 
    padding: "12px",
  },
  "@media only screen and (max-width: 1024px)": {
    gap: "5px", 
    padding: "10px",
  },
  "& .skeletonline": {
    width: "180px",
    "@media only screen and (max-width: 1366px)": {
      width: "130px",
    },
    "@media only screen and (max-width: 1280px)": {
      width: "139px",
    },
    "@media only screen and (max-width: 1024px)": {
      width: "130px",
    },
  },
  "& .skeletoncircle": {
    width: "150px",
    height: "150px",
    "@media only screen and (max-width: 1366px)": {
      width: "140px",
      height: "140px",
    },
    "@media only screen and (max-width: 1280px)": {
      width: "130px",
      height: "130px",
    },
    "@media only screen and (max-width: 1024px)": {
      width: "100px",
      height: "100px",
      marginTop: "30px"
    },
  }
})

// Customizable Area End
