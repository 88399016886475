// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IFilterItem } from "../../promocodes/src/PromoCodeListController.web";
import { IFilter } from "../../../components/src/FilterPopover";
import { makeApiMessage } from "../../../components/src/common";
import moment from "moment";
import { isNumber, toString, uniq } from "lodash";
import { IUserContext } from "../../navigationmenu/src/PageContainerController.web";
import { handleDisplayRegion } from "./utils";
import html2pdf from "html2pdf.js";
import { customPermissionApiKey, DashboardPermissionStatus, checkForDashboardPermissonStatus, getDateRangeForQuery } from "../../utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

interface SectionData {
  current_data: {orders: number; pieces:number; amount: string}
  last_year_data: {orders: number; pieces:number; amount: string}
}

interface RevenueDataItem {payment_method_name: string; total_amount: string}

type ChartItem = {name: string; value_1: number; value_2: number;}

type OverviewData = {
  order_count: number,
  pieces_count: number,
  amount: string,
  sub_total: string,
  discount: string,
  vat: string,
  net: string,
  total: string
}

type RevenueChartItem = {
  name: string
  today: number
  lastYear: number
}

type SectionTableData = {name: string; orders: number; pieces: number; amount: string;}

export type OrderCountByYear = {
  key: string;
  categories: SectionTableData[]
  total: OverviewData,
  revenue: {name: string; value: number}[]
  range: {
    startDate: string
    endDate: string
  }
}

export const categoryColumns = [
  {
    header: "Category",
    binding: "name",
  },
  {
    header: "Order",
    binding: "orders",
  },
  {
    header: "Pieces",
    binding: "pieces",
  },
  {
    header: "Amount (SAR)",
    binding: "amount",
  }
] as {header: string; binding: keyof SectionTableData}[]

export const totalColumns = [
  {
    header: "Orders",
    binding: "order_count",
  },
  {
    header: "Pieces",
    binding: "pieces_count",
  },
  {
    header: "Amount (SAR)",
    binding: "amount",
  },
  {
    header: "Discount (SAR)",
    binding: "discount",
  },
  {
    header: "Sub Total (SAR)",
    binding: "sub_total",
  },
  {
    header: "VAT (SAR)",
    binding: "vat",
  },
  {
    header: "Net (SAR)",
    binding: "net",
  }
] as {header: string; binding: keyof OverviewData}[]

const initialSectionData : SectionData = {
  current_data: {
    orders: 0,
    pieces: 0,
    amount: "0.00"
  },
  last_year_data: {
    orders: 0,
    pieces: 0,
    amount: "0.00"
  }
} 

const initialTotalOverviewData : OverviewData = {
  order_count: 0,
  pieces_count: 0,
  amount: "0.00",
  discount: "0.00",
  sub_total: "0.00",
  vat: "0.00",
  net: "0.00",
  total: "0.00"
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: unknown;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean
  ordersByYear: OrderCountByYear[]
  carpet: SectionData
  clothes: SectionData
  barChartXAxisProps: {
    domain: number[] | undefined;
  },
  totalOverviewData: {
    today_data: OverviewData
    last_year_data: OverviewData
  }
  overviewChartData: ChartItem[]
  revenueChartData: RevenueChartItem[]
  filterAnchor: undefined | HTMLDivElement
  filters: IFilterItem[]
  regionId: string
  storeId: string
  areaId: string
  storeName: string
  regionName: string
  groupId: string
  groupName: string
  openPrintSummary: boolean
  currentRange: {
    startDate: string
    endDate: string
  },
  lastYearRange: {
    startDate: string
    endDate: string
  },
  todayLabel: string
  lastYearLabel: string
  openExportModal: boolean
  loadingReceipt: boolean
  receiptUrl: string
  periodText: string;
  isLoadingCarpet: boolean;
  isLoadingClothes: boolean;
  isLoadingOverview: boolean;
  isLoadingRevenue: boolean;
  permissionStatus: DashboardPermissionStatus | null;
  isAppliedFilter: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class DashboardOverviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCarpetOverviewCallId= "";
  getClothesOverviewCallId= "";
  getRevenueOverviewCallId= "getRevenueOverviewCallId";
  getSalesOverviewCallId= "";
  getSalesSummaryCallId = "";

  static noFormatFields = ["orders", "pieces", "order_count", "pieces_count", "name"]
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.BroadcastNavbarDataMessage),
      getName(MessageEnum.LayoutDataMessage)
    ];
    // Customizable Area End

    // Customizable Area Start
    this.state = {
      regionId: "",
      regionName: "",
      storeId: "",
      areaId: "",
      storeName: "",
      groupId: "",
      groupName: "",
      openPrintSummary: false,
      filterAnchor: undefined,
      barChartXAxisProps: {
        domain: undefined
      },
      filters: [
        {
          title: "Date",
          type: "dateselect",
          datevalue: { from: "", to: "" },
          value: "today",
          options: [
            {
              label: "Today",
              value: "today",
            },
            {
              label: "Last 7 days",
              value: "last_7_days",
            },
            {
              label: "Last 30 days",
              value: "last_30_days",
            },
            {
              label: "Specific Dates",
              value: "specific",
            },
          ],
        },
        {
          title: "Year",
          type: "yearselect",
          datevalue: { from: "", to: "" },
          value: "",
          options: [
            {
              label: "Current Year",
              value: "current_year",
            },
            {
              label: "Previous Year",
              value: "previous_year",
            },
            {
              label: "Period",
              value: "specific",
            },
          ],
        },
      ],
      totalOverviewData: {
        today_data: initialTotalOverviewData,
        last_year_data: initialTotalOverviewData
      },
      overviewChartData: [],
      clothes: initialSectionData,
      carpet: initialSectionData,
      ordersByYear: [{
        key: "initial",
        range: {
         startDate: "",
         endDate: "" 
        },
        categories: ["Clothes", "Carpet"].map(category => ({
          name: category,
          orders: 0,
          pieces: 0,
          amount: "0.00"
        })),
        total: initialTotalOverviewData,
        revenue: []
      }],
      revenueChartData: [],
      loading: false,
      currentRange: {
        startDate: "",
        endDate: ""
      },
      lastYearRange: {
        startDate: "",
        endDate: ""
      },
      todayLabel: "Today",
      lastYearLabel: "Last Year",
      periodText: "",
      openExportModal: false,
      loadingReceipt: false,
      receiptUrl: "",
      isLoadingCarpet: true,
      isLoadingClothes: true,
      isLoadingOverview: true,
      isLoadingRevenue: true,
      permissionStatus: null,
      isAppliedFilter: false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receiveDataFromLayout(message);
    if (message.id === getName(MessageEnum.BroadcastNavbarDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.BroadcastNavbarData)
      );

      const {storeId,areaId,regionMultiId,groupId,regionNames,regionSelectAll} = recievedData
      this.setState({regionId: regionMultiId, storeId,groupId,areaId,
        regionName: handleDisplayRegion(regionMultiId,regionNames,regionSelectAll).textDisplayed
      }, () => {
        if (regionMultiId) this.handleStorageFilter()
      })
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.getCarpetOverviewCallId:
          this.handleSectionResponse("carpet", responseJson)
          break;
        case this.getClothesOverviewCallId:
          this.handleSectionResponse("clothes", responseJson)
          break;
        case this.getSalesOverviewCallId: {
          const {today_data = initialTotalOverviewData, last_year_data = initialTotalOverviewData} = responseJson || {}
          const areaChartData = 
            [
              {
                name: 'Amount',
                value_1: Number(today_data.amount),
                value_2: Number(last_year_data.amount),
              },
              {
                name: 'Discount',
                value_1: Number(today_data.discount),
                value_2: Number(last_year_data.discount),
              },
              {
                name: 'Subtotal',
                value_1: Number(today_data.sub_total),
                value_2: Number(last_year_data.sub_total),
              },
              {
                name: 'VAT',
                value_1: Number(today_data.vat),
                value_2: Number(last_year_data.vat),
              },
              {
                name: 'Net',
                value_1: Number(today_data.net),
                value_2: Number(last_year_data.net),
              },
            ]
          this.setState({totalOverviewData: {today_data, last_year_data},overviewChartData: areaChartData, isLoadingOverview: false})
          break;
        }
        case this.getRevenueOverviewCallId: {
          const {today_revenue_data = [], last_year_revenue_data = []} = responseJson || {}
          const paymentMethods = uniq([
            ...today_revenue_data.map((item: RevenueDataItem) => item.payment_method_name),
            ...last_year_revenue_data.map((item: RevenueDataItem) => item.payment_method_name)
          ])
          const columnChartData = paymentMethods.map(paymentMethod => ({
            name: paymentMethod,
            today: this.getNumber(today_revenue_data.find((item: {payment_method_name: string; total_amount: string}) => item.payment_method_name === paymentMethod)?.total_amount),
            lastYear: this.getNumber(last_year_revenue_data.find((item: {payment_method_name: string; total_amount: string}) => item.payment_method_name === paymentMethod)?.total_amount)
          }))
          this.setState({
            revenueChartData: columnChartData,
            currentRange: {
              startDate: this.formatDate(responseJson?.start_date),
              endDate: this.formatDate(responseJson?.end_date),
            },
            lastYearRange: {
              startDate: this.formatDate(responseJson?.last_yr_start_date),
              endDate: this.formatDate(responseJson?.last_yr_end_date),
            },
            todayLabel: this.getTodayLabel(responseJson?.end_date),
            lastYearLabel: this.getChartLabel(responseJson?.last_yr_end_date),
            isLoadingRevenue: false
          }, () => this.updatePeriodYearRange())
          break;
        }
        case this.getSalesSummaryCallId:
          this.setState({loadingReceipt: false, receiptUrl: toString(responseJson?.url)})
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }

  handleFilterButton = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    this.setState({
      filterAnchor: event.currentTarget,
    });
  };

  handleCloseFilterButton = () => {
    this.setState({
      filterAnchor: undefined,
    });
  };

  getOverviewData = () => {
    const filterQueryString = this.getFilterQueryString()

    const apiCalls = [
      {
        endPoint: configJSON.sectionOverviewEndPoint + filterQueryString + "&section_type=clothes",
        apiCallIdField: "getClothesOverviewCallId",
      },
      {
        endPoint: configJSON.sectionOverviewEndPoint + filterQueryString + "&section_type=carpet",
        apiCallIdField: "getCarpetOverviewCallId",
      },
      {
        endPoint: configJSON.totalSalesOverviewEndPoint + filterQueryString,
        apiCallIdField: "getSalesOverviewCallId",
      },
      {
        endPoint: configJSON.revenueOverviewEndPoint + filterQueryString,
        apiCallIdField: "getRevenueOverviewCallId",
      },
    ] as {
      endPoint: string; 
      apiCallIdField: "getClothesOverviewCallId" | "getCarpetOverviewCallId" | "getSalesOverviewCallId" | "getRevenueOverviewCallId"
    }[]

    apiCalls.forEach((apiCall) => {
      const requestMessage = makeApiMessage({
        url: apiCall.endPoint,
        method: configJSON.dashboarApiMethodType
      })
      this[apiCall.apiCallIdField] = requestMessage.messageId
      this.send(requestMessage)
    })

    this.setState({
      isLoadingCarpet: true,
      isLoadingClothes: true,
      isLoadingOverview: true,
      isLoadingRevenue: true
    })
    
  }

  handleSectionResponse = (section: "clothes" | "carpet", responseJson?: SectionData) => {
    const updateData : Partial<S> = {
      [section]: responseJson ? {
        current_data: responseJson.current_data,
        last_year_data: responseJson.last_year_data
      }: initialSectionData
    }
    this.setState(updateData as Pick<S, keyof S>, () => this.updateDomain())
    this.setState({
      isLoadingCarpet: false,
      isLoadingClothes: false
    })
  }

  getSectionChartData = (inputSectionData: SectionData) =>  [
      {
        name: 'Order',
        value_1: inputSectionData.current_data.orders,
        value_2: inputSectionData.last_year_data.orders,
      },
      {
        name: 'Pieces',
        value_1: inputSectionData.current_data.pieces,
        value_2: inputSectionData.last_year_data.pieces,
      },
    ]

  getFilterQueryString = () => {
    const {storeId,areaId, regionId, filters,groupId} = this.state

    const regionParam = regionId ? `?region_ids=${regionId}` : ""
    const groupParam = groupId  ? `&group_ids=${groupId}` : ""
    const areaParam = areaId  ? `&area_ids=${areaId}` : ""
    const storeParam = storeId  ? `&store_ids=${storeId}` : ""

    const filterType = filters[0].value ? "date" : "year";
    const filterByRange = filterType === "date" ? this.getFilterByDate() : this.getFilterByYear()
    
    return regionParam + groupParam + areaParam+ storeParam  + `&filter_type=${filterType}` + filterByRange
  }

  getFilterByDate = () => {
    const { filters } = this.state
    const {datevalue, value} = filters[0] || {}
    const dateTypeParam = `&date_type=${value === "specific" ? "specific_dates" : value}`
    const {dateRangeQuery} = getDateRangeForQuery(datevalue?.from, datevalue?.to)
    return dateTypeParam + dateRangeQuery
  }

  getFilterByYear = () => {
    const { filters } = this.state
    const {datevalue, value} = filters[1] || {}
    const dateTypeParam = `&year_type=${value === "specific" ? "period" : value}`
    const startParam = datevalue?.from ? `&start_year=${moment(datevalue.from).format("YYYY")}` : ""
    const endParam = datevalue?.to ? `&end_year=${moment(datevalue.to).format("YYYY")}` : ""
    return dateTypeParam + startParam + endParam
  }

  dateRange = () => {
    const {currentRange} = this.state
    const {startDate, endDate} = currentRange
    if (!startDate && !endDate) return ""
    return `${startDate} to ${endDate}`
  }

  updatePeriodYearRange = () => {
    const {currentRange, lastYearRange, filters} = this.state
    const value = this.state.filters?.[1]?.value;
    let startDate = currentRange.startDate
    let endDate = currentRange.endDate
    if (value === "specific") {
      startDate = lastYearRange.startDate
    } 

    if (!startDate && !endDate) return this.setState({periodText: ""})
    const startYear = moment(startDate, "DD-MM-YYYY").year()
    const endYear = moment(endDate, "DD-MM-YYYY").year()
    const periodText = startYear === endYear ? `${startYear}` : `${startYear}-${endYear}`
    this.setState({periodText})
  }

  getTableData = () => {
    const {carpet, clothes, revenueChartData, totalOverviewData, currentRange, lastYearRange} = this.state
    const result: OrderCountByYear[] = [
      {
        key: "today",
        categories: [
          {
            name: "Carpet",
            ...carpet.current_data
          },
          {
            name: "Clothes",
            ...clothes.current_data
          },
        ],
        range: currentRange,
        total: totalOverviewData.today_data,
        revenue: revenueChartData.map(item => ({name: item.name, value: item.today}))
      },
      {
        key: "last_year",
        categories: [
          {
            name: "Carpet",
            ...carpet.last_year_data
          },
          {
            name: "Clothes",
            ...clothes.last_year_data
          },
        ],
        range: lastYearRange,
        total: totalOverviewData.last_year_data,
        revenue: revenueChartData.map(item => ({name: item.name, value: item.lastYear}))
      }
    ]
    return result
  }

  handleUserChange = (context: IUserContext) => {
    const dashboardOverviewKey = customPermissionApiKey.dashboardPermission;
    const userData = context.user?.attributes.permission_groups;
    const value = checkForDashboardPermissonStatus(dashboardOverviewKey, userData as unknown as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    });
  }

  formatDate = (inputString?: string) => inputString ? moment(inputString).format("DD-MM-YYYY") : ""

  getYear = (inputString?: string) => inputString ? moment(inputString, "DD-MM-YYYY").year() : ""

  formatValue = (inputValue: number | string, field: string) => {
    if (DashboardOverviewController.noFormatFields.includes(field)) return inputValue;
    const parsedInput = this.getNumber(inputValue)
    return parsedInput.toLocaleString("en-US",{
      minimumFractionDigits: 2, 
      style: 'decimal',
      currency: 'SAR',
    })
  }

  getNumber = (input?: string | number) => {
    if (!input) return 0;
    if (isNumber(input)) return input;
    return parseFloat(input);
  }

  togglePrintDialog = () => this.setState((prev) => ({openPrintSummary: !prev.openPrintSummary}))

  updateDomain = () => {
    this.setState((prev) => {
      const {carpet, clothes} = prev
      const highestValue = Math.max(
        clothes.current_data.orders,
        clothes.current_data.pieces,
        clothes.last_year_data.orders,
        clothes.last_year_data.pieces,
        carpet.current_data.orders,
        carpet.current_data.pieces,
        carpet.last_year_data.orders,
        carpet.last_year_data.pieces
      )
      return {
        barChartXAxisProps: {
          domain: [0, highestValue * 1.1]
        }
      }
    }) 
  }

  getTodayLabel = (inputDate?: string) => {
    const {value, options} = this.state.filters[0]
    if (value) return toString(options?.find(option => option.value === value)?.label)
    return this.getChartLabel(inputDate)
  }

  getChartLabel = (inputDate?: string) => {
    if (!inputDate) return "";
    const currentYear = moment().year()
    const lastYear = currentYear - 1
    const inputYear = moment(inputDate).year()
    const value =  this.state.filters?.[1]?.value;
    if (value) return `${inputYear}`
    
    switch(inputYear) {
      case currentYear:
        return "Today"
      case lastYear:
        return "Last Year"
      default:
        return `${inputYear}`
    }
  }

  handleOpenExportModal = () => {
    this.setState({openExportModal: !this.state.openExportModal})
  }

  handleExportConfirm = async(method: string) => {
    if (method === "pdf") {
      const wrapperDomElement = document.getElementById("print-wrapper-overview") as HTMLElement
      await html2pdf(wrapperDomElement, {
        pagebreak: { mode: ['css'] },
        filename: "overview-dashboard.pdf",
        margin: 24,
        jsPDF: {
          format: [1440, 1440],
          unit: "px"
        }
      })
      this.setState({ openExportModal: false })
    }
  }

  handleGetPrintSummary = (startDate: string, endDate: string) => {
    const {regionId, storeId,groupId,areaId} = this.state
    if (regionId && storeId) {
      this.setState({loadingReceipt: true})
      const regionParam = `?region_ids=${regionId}`
      const groupParam = groupId  ? `&group_ids=${groupId}` : ""
      const areaParam = areaId  ? `&area_ids=${areaId}` : ""
      const storeParam = `&store_ids=${storeId}`
      const message = makeApiMessage({
        url: configJSON.salesSummaryApi + regionParam + groupParam + areaParam + storeParam  + getDateRangeForQuery(startDate, endDate).dateRangeQuery,
        method: configJSON.dashboarApiMethodType
      })
      this.getSalesSummaryCallId = message.messageId
      this.send(message)
    }
    
  }

  handleStorageFilter = () => {
    const applied_profession_filter = localStorage.getItem("filter_value_overview");
    if (applied_profession_filter) {
      this.setState(
        {
          filters: JSON.parse(applied_profession_filter),
          isAppliedFilter: this.checkIsFilterApplied(
            JSON.parse(applied_profession_filter),"today"
          ),
        },
        () => {
          this.getOverviewData()
        }
      );
    } else {
      this.getOverviewData()
    }
  };

  checkIsFilterApplied = (filters: IFilter[], falsyValue?: string): boolean => {
    for (const filter of filters) {
      if (filter.value) {
        if (filter.value === falsyValue) {
          return false;
        }
        return true; 
      }
    }
    return false;
  };

  handleFilterChange = (filters: IFilter[]) =>  {
    if (this.checkIsFilterApplied(filters,"today")) {
      localStorage.setItem("filter_value_overview", JSON.stringify(filters));
    } else {
      localStorage.removeItem("filter_value_overview");
    };
    const  newFilters = filters.map(item => {
      if (item.value === "specific" && (!item.datevalue?.from || !item.datevalue.to)) return ({
        ...item,
        value: item.type === "yearselect" ? "current_year" : "today",
        datevalue: {
          from: "",
          to: ""
        }
      })
      return item
    })
    this.setState({filters: newFilters, isAppliedFilter: this.checkIsFilterApplied(filters,"today") }, () => this.getOverviewData())
  };

  // Customizable Area End
}