// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import _ , { debounce,concat,uniqBy } from "lodash";

type NestedObject<T, K extends keyof T> = K extends string
  ? T[K] extends Record<string, any>
    ? T[K]
    : undefined
  : undefined;

export interface CataloguePreference {
  id?: string | number;
  type?: string;
  attributes?: CataloguePreferenceAttributes;
}

export interface CataloguePreferenceSpecification {
  id: number | string;
  name: string;
  name_translation: string;
  options_attributes: {
    id: number;
    label: string;
    selected: boolean;
  }[];
}

export interface CataloguePreferenceData {
  id: string | number;
  name: string;
  checked: boolean;
  icon: string;
  name_translation: string;
}

export interface CataloguePreferenceAttributes {
  name: string | null | undefined;
  product_second_name?: string;
  id: null | string;
  specifications: CataloguePreferenceSpecification[];
  upcharges: CatalogueUpchargeData[];
  preferences: CataloguePreferenceData[];
  catalogue_variant_id?: number;
  customer_id?: number;
  meta_response?: string[];
  service?: {
    id: number;
    name: string;
    icon: string;
    name_translation: string;
  };
  save_for_future?: any;
  notes: string | null | undefined;
  label?: string | null;
  selectedServiceId?: number | string;
  productId?: number | string;
  selectedService?: string;
  services?: {
    id: number,
    name: string,
    selected: boolean,
    icon: null,
    name_translation: string
  }[];
  image: string;
}

export interface CatalogueUpchargeData {
  id: number;
  name: string;
  checked: boolean;
  price: number;
  upcharge_type: string;
  icon: string;
  currency: string;
  name_translation: string;
}

export interface ServiceDropdown {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    icon: string;
    name_translation: string;
  };
}

export interface ISpecificationData {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    name_translation: string;
  };
}

export interface ICatalogueVariantService {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      catalogue_variant_id: number;
      price: string;
      currency: string;
      default: boolean;
      service: {
        data: {
          id: string;
          type: string;
          attributes: {
            id: number;
            second_name: string;
            name: string;
            short_name: string;
            icon_id: number;
            online_order: boolean;
            active: boolean;
            order_number: number;
            icon: {
              data: {
                id: string;
                type: string;
                attributes: {
                  id: number;
                  name: string;
                  image: string;
                  second_name: string;
                  name_translation: string;
                  image_type: string;
                };
              };
            };
            sections: {
              data: {
                id: string;
                type: string;
                attributes: {
                  id: number;
                  section_name: string;
                  section_second_name: string;
                  online: boolean;
                  active: boolean;
                  order_number: number;
                  model_name: string;
                  updated_at: string;
                  created_at: string;
                  icon: {
                    data: {
                      id: string;
                      type: string;
                      attributes: {
                        id: number;
                        image: string;
                        name: string;
                        second_name: string;
                        name_translation: string;
                        image_type: string;
                      };
                    };
                  };
                  preference: {
                    data: {
                      id: string;
                      type: string;
                      attributes: {
                        id: number;
                        icon_id: number;
                        preference_first_name: string;
                        gallery_icon: {
                          id: number;
                          name_translation: string;
                          image: string;
                        };
                        name: string;
                      };
                    };
                  };
                  name: string;
                };
              }[];
            };
            name_translation: string;
          };
        };
      };
    };
  }[];
}

interface CatalogueVariantArray {
  id: string;
  type: string;
  attributes: {
    id: number;
    pieces: number | null;
    active: boolean;
    created_at: string;
    updated_at: string;
    measurement_type: string[];
    catalogue: {
      id: string;
      type: string;
      attributes: {
        id: number;
        product_name: string;
        product_second_name: string;
        name: string;
        product_type: {
          id: number;
          value: string;
        };
      };
    };
    name: string;
    image: {
      id: number;
      name: string;
      image: string;
    };
    measurement: {
      id: number | null;
      value: string | null;
    };
    product_currency_id: string;
    catalogue_variants_services: {
      data: any[];
    };
  };
}

export interface ICatalogueVariant {
  id: string;
  type: string;
  attributes: {
    id: number;
    price_list: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
      product_currency_id: string;
      tax_type: string;
      activated: boolean;
      price_list_type: string;
      price_list_to_copy: null | string;
      add_by: null | string;
    };
    catalogue_variants:CatalogueVariantArray[],
    pieces: null | string;
    active: boolean;
    updated_at: string;
    created_at: string;
    catalogue: {
      id: string;
      type: string;
      attributes: {
        id: number;
        product_name: string;
        name: string;
        product_second_name: string;
      };
    };
    name: string;
    image: {
      image: string;
      id: number;
    };
    measurement: {
      value: string;
      id: number;
    };
    product_currency_id: string;
    catalogue_variants_services: ICatalogueVariantService;
  };
}


interface ServiceDropdownAttributes {
  id: number | string;
  name: string;
  icon: string;
  name_translation: string;
}

interface ServiceDropdownData {
  id: string;
  type: string;
  data?: any;
  attributes?: {
    name: string;
    id: string | number;
    icon: string;
    name_translation: string;
  };
}

interface OptionsAttributes {
  id: number;
  label: string;
  selected: boolean;
  pref_spec_id?: {
    id: number
  }
}

export interface SpecificationsAttributes {
  id: number;
  name: string;
  name_translation: string;
  options_attributes: OptionsAttributes[];
}

interface CustomerPreferencesAttributes {
  id: string;
  type: string;
  attributes: {
    label: string | null;
    save_for_future: boolean;
    notes: any;
    customer: {
      id: number;
      first_name: string | null;
      last_name: string | null;
      email: string;
    };
    product: {
      id: string;
      type: string;
      attributes: {
        id: number;
        price_list: {
          id: number,
          name: string,
          created_at: string,
          updated_at: string,
          product_currency_id: string,
          tax_type: string,
          activated: true,
          price_list_type: string,
          price_list_to_copy: null,
          add_by: null
        };
        pieces: number;
        active: boolean;
        created_at: string;
        updated_at: string;
        catalogue: {
          id: string;
          type: string;
          attributes: {
            id: number;
            product_name: string;
            product_second_name: string;
            name: string;
          };
        };
        name: string;
        image: {
          id: number;
          image: string;
        } | null;
        measurement: {
          id: number;
          value: string;
        };
        product_currency_id: string;
        catalogue_variants_services: ICatalogueVariantService;
      };
    };
    upcharge_lists: IUpchargeLIstData[] | undefined;
    preferences: IPreferenceData[];
    service: ServiceDropdownData;
    specifications: SpecificationsAttributes[];
    services: {
      id: number;
      name: string;
      selected: boolean;
      icon: string | null;
      name_translation: string;
    }[];
  };
}

export interface IPreferenceData {
  id: number;
  name: string;
  checked: boolean;
  icon: string;
  name_translation: string;
}

export interface IUpchargeLIstData {
  id: number;
  name: string;
  checked: boolean;
  price: number;
  currency: string;
  upcharge_type: string;
  icon: string;
  name_translation: string;
}

export interface CustomerPrefData {
  id: string;
  type: string;
  attributes: {
    id: number;
    activated: boolean;
    country_code: number;
    email: string;
    full_phone_number: string;
    phone_number: number;
    created_at: string;
    updated_at: string;
    customer_preferences_attributes: CustomerPreferencesAttributes[]
  };
}
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    customerId?: any;
    fromAddCustomer?: boolean;
    open: boolean;
    onClose?: boolean;
    classes?: any;
    responseData: Data;
    editable?:boolean;
    saveForFuture?:boolean;
    headerTitle:string;
    data: Item[];
    handleClose?: any;
    preferenceData?: CustomerPrefData;
    selectedService?: string | Object;
    onCreate?: (data: any) => void;
    onCreateForCustomer?: (data: any[]) => void;
    editScreen?: boolean;
    renderOnPage?: boolean;
    priceId?: string;
    isDrawerOpen?: boolean;
    loading?: boolean
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isOpen: boolean;
    areApiCalled: boolean;
    isLoader: boolean;
    showProductError: boolean;
    showColorError: boolean;
    showDamageError: boolean;
    showStainError: boolean;
    showPatternError: boolean;
    showMaterialError: boolean;
    showCheckboxError: boolean;
    showRadioError: boolean;
    form: IFormPreference;
    selectedOptions: { specification_id: string | number, option_id: Array<string | number> }[];
    selectedOptionsList: { specification_id: string | number, option_id: Array<string | number> }[][];
    preferenceList: CataloguePreference[];
    autoCopmpleteValue: string;
    service_id: number;
    product_ids: number[];
    service_ids: number[];
    productList: {
        id: string,
        name: string,
        catalogue_id: string;
    }[];
    newProductList: {
        id: number,
        name: string,
        catalogue_id: string;
    }[];
    serviceList: {
        id: string,
        name: string,
    }[];
    specificationList: {
        id: number,
        name: string,
    }[];
    lastChangedIndex: number;
    collapsedData: boolean[];
    bulkUpdatePreferenceOptions: IPreferenceData[];
    bulkUpdateUpchargeOptions:{
      id:number,
      name:string,
    } [];
    bulkUpdateUpChargeSelectedOptions: (string | number)[];
    selectedBulkPreference: number | null;
    selectAllUpcharges: boolean;
    isLoading: boolean;
    productListPageNo: number;
    isLoadingProduct: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
// Customizable Area Start

export interface IFormPreference {
    customer_id: number;
    preferences_attributes: [
        {
            id: number;
            catalogue_variant_id: number;
            preference_id: number;
            upcharge_list_ids: number[];
            service_id: number;
            label?: string;
            notes: string;
            save_for_future: boolean;
            pref_spec_options_attributes: { specification_option_id: number[]; }[]
        }
    ]
}

export type Options = {
    id: number | string;
    label: string;
    selected: boolean;
};

export interface Item {
    showErrorCheckbox?: boolean;
    showRadioDataError?: boolean;
    product: string;
    productId?: string | number;
    color: string;
    colorId?: string | number;
    damage: string;
    damageId?: string | number;
    stain: string;
    stainId?: string | number;
    pattern: string;
    patternId?: string | number;
    material: string;
    materialId?: string | number;
    options: string[];
    radioOption: string;
    textNote?:string;
    isSaveFuture?:boolean
}

export type Specification = {
    id: number;
    name: string;
    options_attributes: Options[];
};

export interface IUpcharge {
  id: number | string;
  name: string;
  checked: boolean;
  price: number | string;
  upcharge_type: string;
  icon: string;
  currency: string;
  name_translation: string;
}

export interface IPreference {
  id: number | string;
  name: string;
  checked: unknown;
  icon: string;
  name_translation: string;
}

export type Service = {
    id: number;
    name: string;
    icon: string | null;
};

export type Data = {
    id: string;
    type: string;
    attributes: {
        id: number;
        name: string;
        product_second_name: string;
        specifications: Specification[];
        upcharges: IUpcharge[];
        preferences: IPreference[];
        services: Service[];
    };
};

// Customizable Area End

export default class CreateCustomerPreferenceController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getProductListCallId: string = "";
    getServiceListCallId : string = "";
    getProductPreferencesApiId: string = "";
    getSpecificationListCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
        ];

        this.state = {
          autoCopmpleteValue: '',
           isLoader: false,
            isOpen: false,
            areApiCalled: false,
            selectAllUpcharges: false,
            selectedBulkPreference: null,
            showProductError: false,
            showColorError: false,
            showDamageError: false,
            showStainError: false,
            showPatternError: false,
            showMaterialError: false,
            showCheckboxError: false,
            showRadioError: false,
            form: {} as IFormPreference,
            selectedOptions: [],
            preferenceList: [{}],
            service_id: 0,
            service_ids: [],
            product_ids: [],
            selectedOptionsList: [],
            productList: [],
            newProductList: [],
            serviceList: [],
            specificationList: [],
            lastChangedIndex: -1,
            collapsedData: [],
            bulkUpdatePreferenceOptions: [],
            bulkUpdateUpchargeOptions: [],
            bulkUpdateUpChargeSelectedOptions: [],
            isLoading: false,
            productListPageNo: 1,
            isLoadingProduct: false
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    componentDidMount(): any {
        // Customizable Area Start
        if(!this.props.renderOnPage) {
          this.getServiceList();
          this.getProductList();
        }
        this.getSpecificationList();
        this.mountPropChange();
        // Customizable Area End
    }
    // Customizable Area Start

    getPreferences = (customerPreferenceData: CustomerPrefData | undefined) => {
      const preferences: IPreferenceData[] = [];
      customerPreferenceData && customerPreferenceData.attributes.customer_preferences_attributes.forEach((item) => {
        item.attributes.preferences.forEach((preference: IPreferenceData) => {
          if (!preferences.find((preferenceItem) => preferenceItem.id === preference.id)) {
            preferences.push(preference)
          }
        })
      })
      return preferences
    }

    getUpcharges = (customerPreferenceData: CustomerPrefData | undefined) => {
      const upcharges: IUpchargeLIstData[] = [];
      customerPreferenceData && customerPreferenceData.attributes.customer_preferences_attributes.forEach((item) => {
        item?.attributes?.upcharge_lists?.forEach((upcharge: IUpchargeLIstData) => {
          if (!upcharges.find((upchargeItem: IUpchargeLIstData) => upchargeItem.id === upcharge.id)) {
            upcharges.push(upcharge)
          }
        })
      })
      return upcharges
    }
  
    handleSelectAllUpchargeChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const { checked } = event.target;
  
      if (checked) {
        const newPreferenceList: CataloguePreference[] = _.cloneDeep(this.state.preferenceList);

        newPreferenceList.forEach((preference) => {
          preference.attributes?.upcharges.forEach((upcharge: CatalogueUpchargeData) => {
            
              upcharge.checked = true;
          })
        });
        this.setState({
          preferenceList: newPreferenceList,
          selectAllUpcharges: true,
          bulkUpdateUpChargeSelectedOptions: this.state.bulkUpdateUpchargeOptions.map((item: {id: number, name: string}) => item.id)
        });
      } else {
        const newPreferenceList: CataloguePreference[] = _.cloneDeep(this.state.preferenceList);

        newPreferenceList.forEach((preference) => {
          preference.attributes?.upcharges.forEach((upcharge: CatalogueUpchargeData) => {
            
              upcharge.checked = false;
          })
        });
        this.setState({
          preferenceList: newPreferenceList,
          selectAllUpcharges: false,
          bulkUpdateUpChargeSelectedOptions: []
        });
      }
    };

    debouncedFunction = debounce(
      (newInputValue: string, inputFunction: (inputValue: string) => void) =>
        inputFunction(newInputValue),
      700,
      { maxWait: 2000 }
    );
  

    handleAutoCompleteChange = (getValue: string) => {
      if (getValue === this.state.autoCopmpleteValue) return;
      if(getValue.length < 1 || getValue.length > 2) {
        this.setState({
          autoCopmpleteValue: getValue,
          productListPageNo: 1,
        }, () => this.getProductList());
      }
    }

    getProductList = () => {
      this.setState({isLoadingProduct: true})
        const headers = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };
        const { autoCopmpleteValue } = this.state;

      const searchQuery = autoCopmpleteValue ? `&filter_by[name]=${this.state.autoCopmpleteValue}` : '';
      const apiUrl = `${configJSON.productListApiEndPoint}?page_no=${this.state.productListPageNo}&price_list_id=${this.props.priceId}&allow_access=true${searchQuery}&for_customer=true`;
    
        const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    
        message.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          apiUrl
        );
    
        message.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        message.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET",
        );
        this.getProductListCallId = message.messageId;
        runEngine.sendMessage(message.id, message);
    };
    
  getSpecificationList = () => {
    const headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const apiUrl = `${configJSON.specificationAPIEndPoint}?dropdown=true`;
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET",
    );
    this.getSpecificationListCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

    getServiceList = (catalogue_id?: string) => {
        const headers = {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        };
    
        const apiUrl = `${configJSON.servicesApiEndPoint}?dropdown=true&product_id=${catalogue_id || this.state.product_ids[0]}`;
    
        const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    
        message.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          apiUrl
        );
    
        message.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        message.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
        this.getServiceListCallId = message.messageId;
        runEngine.sendMessage(message.id, message);
    };

    getProductPreferencesCall = (
        catalogue_variant_id: number,
        service_id: number,
    ) => {
        this.setState({ areApiCalled: false, isLoader:true });
        const headers = {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        };
    
        const apiUrl =
        configJSON.productPreferenceAPIEndPoint +
          `?customer_id=${this.props?.customerId || null}&catalogue_variant_id=${catalogue_variant_id}&service_id=${service_id}`;
    
        const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    
        message.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          apiUrl
        );
    
        message.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        message.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
        this.getProductPreferencesApiId = message.messageId;
        runEngine.sendMessage(message.id, message);
      };

  handleScroll = (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1.3 && this.state.newProductList.length !== 0) {
      this.setState((prev) => ({ productListPageNo: prev.productListPageNo + 1 }), () => {
        this.getProductList()
      })
    }
  };

    componentDidUpdate(prevProps: Props, prevState: {product_ids: number[];service_ids: number[];}) {
        if (this.state.service_ids.length && this.state.product_ids.length) {
            this.state.service_ids.forEach((service_id: number, index: number) => {
                if (service_id !== prevState.service_ids[index] || this.state.product_ids[index] !== prevState.product_ids[index]) {
                    this.getProductPreferencesCall(this.state.product_ids[index], service_id)
                    this.setState({
                        lastChangedIndex: index,
                        selectedOptionsList: this.state.selectedOptionsList.map((item: { specification_id: string | number, option_id: Array<string | number> }[], i: number) => {
                            if (i === index) {
                                return []
                            }
                            else {
                                return item
                            }
                        })
                    })
                }
            })
        }

        this.propsChange(prevProps)
    }

    handleCreateClick = () => {
        const preferenceItemData = this.state.preferenceList.map((item: CataloguePreference, index :number) => {
                return {
                    id: item.attributes?.id,
                    upchargeListIds: item.attributes?.upcharges.filter((item: IUpcharge) => item.checked).map((item: IUpcharge) => item.id),
                    preferenceId: item.attributes?.preferences.find((item: IPreference) => item.checked == true)?.id,
                    selectedOptions: this.state.selectedOptionsList[index],
                    serviceId: this.state.service_ids[index] || item.attributes?.selectedServiceId,
                    variant_id: this.props.editScreen ? item.attributes?.productId : this.state.product_ids[index],
                    notes: item.attributes?.notes,
                    saveForFuture: true
                }
            }
        )
        if (this.props.onCreateForCustomer) {
            this.props.onCreateForCustomer(preferenceItemData)
            if(!this.props.editScreen) this.props.handleClose();
        }
    }
    
    propsChange = (prevProps: Props) => {
      if (prevProps.preferenceData !== this.props.preferenceData && this.props.editScreen) {
        this.setState({
          bulkUpdatePreferenceOptions: this.getPreferences(this.props.preferenceData),
          bulkUpdateUpchargeOptions: this.getUpcharges(this.props.preferenceData),
          preferenceList: this.perferenceListBody(),
          selectedOptionsList: this.selectedOptionBody()
        });
      }
    }

    mountPropChange = () => {
      if (this.props.editScreen) {
        this.setState({
          bulkUpdatePreferenceOptions: this.getPreferences(this.props.preferenceData),
          bulkUpdateUpchargeOptions: this.getUpcharges(this.props.preferenceData),
          preferenceList: this.perferenceListBody(),
          selectedOptionsList: this.selectedOptionBody()
        });
      }
    }

    safeAccess = <T, K extends keyof T>(obj: T, path: string): NestedObject<T, K> => {
      return path.split('.').reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj as any);
    };

    perferenceListBody = () => {
     return (this.props.preferenceData && this.props.preferenceData.attributes && this.props.preferenceData.attributes.customer_preferences_attributes ?
        this.props.preferenceData.attributes.customer_preferences_attributes.map((data)=>this.perfernceListBodyProccessed(data)) : []
      ) as CataloguePreference[]
    }

    perfernceListBodyProccessed = (data: CustomerPreferencesAttributes) => {
      const attributes = data.attributes;

      return {
        id: data.id,
        attributes: {
          id: data.id,
          name: this.safeAccess(attributes, 'product.attributes.name'),
          image: this.safeAccess(attributes, 'product.attributes.image.image'),
          preferences: this.safeAccess(attributes, 'preferences'),
          services: this.safeAccess(attributes, 'services'),
          specifications: this.safeAccess(attributes, 'specifications'),
          upcharges: this.safeAccess(attributes, 'upcharge_lists'),
          selectedService: this.safeAccess(attributes, 'service.attributes.name'),
          selectedServiceId: this.safeAccess(attributes, 'service.attributes.id'),
          productId: this.safeAccess(attributes, 'product.id'),
        }
      };
    }

    selectedOptionBody = () => {
      return (this.props.preferenceData && this.props.preferenceData.attributes && this.props.preferenceData.attributes.customer_preferences_attributes ? this.props.preferenceData.attributes.customer_preferences_attributes.map((item)=>this.selectedOptionDataProccessed(item))
         : []
      ) as { specification_id: string | number, option_id: Array<string | number> }[][]
    }

    selectedOptionDataProccessed = (item:CustomerPreferencesAttributes) => {
      return item.attributes && item.attributes.specifications ?
          item.attributes.specifications.map(function (specification) {
            const selectedOption = specification.options_attributes ?
              specification.options_attributes.filter(function (item) {
                return item.selected;
              }) : [];
            return {
              specification_id: specification.id,
              option_id: selectedOption.map(option => option.id)
            };
          }) : []; 
    }

    getSpecificationOptionList = (
      data: Array<{
        id: number;
        label: string;
        selected: boolean;
      }>
    ) => {
      const optionlist = data.map((spec) => {
        return {
          id: spec.id,
          option: spec.label,
        };
      });
      return optionlist;
    };

    getSelectedSpecificationIds = (
      preferenceIndex: number,
      specificationId: string,
    ) => {
      const { selectedOptionsList } = this.state;
      let currentValue = selectedOptionsList[preferenceIndex]?.find(item => item.specification_id === specificationId)?.option_id || []
      if (currentValue && !Array.isArray(currentValue)) {
        currentValue = [Number(currentValue)]
      }
      return currentValue
    }

    handleOnSelectSpecification = (
      preferenceIndex: number,
      specificationId: string,
      selectedOption: { id: number; option: string },
      currentSelectedIds: number[]
    ) => {
      const newValue = currentSelectedIds.includes(selectedOption.id) ? currentSelectedIds.filter(value => value !== selectedOption.id)
        : [...currentSelectedIds, selectedOption.id]
      this.setState((prev) => ({
        selectedOptionsList: prev.selectedOptionsList.map(
          (listItem, listItemIndex) => listItemIndex !== preferenceIndex 
          ? listItem : listItem.map(
            (specification) => specification.specification_id !== specificationId ? specification : ({...specification, option_id: newValue})
          ) 
        ),
      }));
    };

    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (apiRequestCallId === this.getProductListCallId) {
              const array = (responseJson?.data || []).map((item: ICatalogueVariant) => {
                return {
                  id: String(item.attributes?.catalogue_variants[0].id),
                  name: item.attributes.name,
                  catalogue_id: String(item.attributes?.id)
                }
              });
              let newPriceListToCopy = this.state.productListPageNo === 1 ? array : concat(this.state.productList, array)
              this.setState({
                productList: uniqBy(newPriceListToCopy, "id") as unknown as {
                  id: string,
                  name: string,
                  catalogue_id: string;
                }[],
                newProductList: array,
                isLoadingProduct: false
              })
            }
            else if (apiRequestCallId === this.getSpecificationListCallId) {
                this.setState({
                    specificationList: responseJson?.data.map((specification: ISpecificationData) => ({
                        id: specification.attributes.id,
                        name: specification.attributes.name,
                    })) 
                })
            }
            else if (apiRequestCallId === this.getServiceListCallId) {
                this.setState({
                    serviceList: responseJson?.data.map((service: ServiceDropdown) => ({
                        id: service.attributes.id,
                        name: service.attributes.name,
                    })) 
                })
            }
            else if (apiRequestCallId === this.getProductPreferencesApiId) {
                if(!responseJson?.data) return
                this.state.preferenceList[this.state.lastChangedIndex] = responseJson?.data;
                this.state.selectedOptionsList[this.state.lastChangedIndex] = responseJson?.data?.attributes?.specifications.map((specification: Specification) => {
                    return {
                        specification_id: specification?.id,
                        option_id: specification.options_attributes.find(item => item.selected)?.id || ""
                    }
                })

                this.setState(() => ({
                    areApiCalled: true,
                    isLoader:false,
                    preferenceList: [...this.state.preferenceList],
                    selectedOptionsList: [...this.state.selectedOptionsList],
                    isLoading: false
                }));
            }
        }

        // Customizable Area End
    }
}

