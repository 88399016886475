import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { makeApiMessage } from "../../../../packages/components/src/common";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { checkForPermissonStatus, conditionalString, customPermissionApiKey, getDateRangeForQuery, PermissionStatus } from "../../../../packages/blocks/utilities/src/CustomBlockHelpers";
import { IUserContext } from "../../../../packages/blocks/navigationmenu/src/PageContainerController.web";
import { PermissionGroupArray } from "../../../../packages/blocks/navigationmenu/src/utils";
import moment from "moment";

export interface ReportItem {
  id: string;
  label: string;
  checked: boolean;
}

export interface IOption {
  id: number;
  label: string;
}
export interface ReportItemWithSection {
  [Key: string]: {
    [key: string] : ReportItem[]
  }
}
export interface SalesAndRevenueReport {
  [key: string]: ReportItem[]
}

export interface RevenueReport {
  [key: string]: ReportItem[] | boolean
}

export interface SectionServiceSalesReport {
  [key: string]: ReportItem[] | SalesAndRevenueReport
}

export interface DropdownType {
  id: string;
  option: string;
}

interface FieldsData {
  [key: string]: ReportItem[] | SalesAndRevenueReport[] | SectionServiceSalesReport | RevenueReport
}

interface DailySalesData {
  name: string;
  orders?: boolean;
  pieces?: boolean;
  amount_without_vat?: boolean;
  amount?: boolean;
  vat?: boolean; 
  total_inc_vat?: boolean;
  total_revenue? : boolean;
  cash?: boolean;
  card?: boolean;
  online?: boolean;
  store_paid?: boolean;
  credit?: boolean;
  unpaid?: boolean;
  discount?: boolean;
  total_orders?: boolean;
  total_pieces?: boolean;
  total_amount?: boolean;
}

const generateData = (fields: string[]) => {
  return fields.map((field, index) => ({
    label: field,
    id: `${index}`,
    checked: true
  }))
}

const generateDatafalseStore = (fields: string[]) => {
  return fields.map((field, index) => ({
    label: field,
    id: `${index}`,
    checked: field === "store_iD" ? false : true, 
  }))
}

const generateDatafalse = (fieldItems: string[]) => {
  return fieldItems.map((item, index) => ({
    label: item,
    id: `${index}`,
    checked: false , 
  }))
}

const generateReportList = ((fields: string[]) => {
  return fields.map((fields, index) => ({
    id: `${index + 1}`,
    option: fields
  }))
})
const reportListData = generateReportList([
  "B2B Store Order Report",
  // "B2B Orders Report Company wise",
  // "B2B Client Order Report",
  "(B2B) Consolidated Orders Report"
])
export const fieldsData: FieldsData = {
  'B2B Store Order Report': generateData(["store_code", "store_name", "order_id", "order_placed", "customer", "mobile_number", "company_name","sub_company",
    "business_account_name", "business_account_no.", "department_name", "department_iD", "employee_iD", "cost_center", "sub_cost_center","pieces",
    "summary", "notes", "order_status",
    ]),
  'B2B Orders Report Company wise': {
    'store_information': generateData(['store_code', 'store_name']),
    'order_information': generateData(['order_id','placed', 'customer', 'mobile_number']),
    'company': generateData(["company_name","sub_company",
    "business_account_name", "business_account_no.", "department_name", "employee_iD", "cost_center", "sub_cost_center",]),
    'products': generateData(['shirt','pant', 'jacket', 'nornex_pant','nornex_shirt','coverall', 'gloves', 'fire_jacket',
      'fire_pant']),
  },
  'B2B Client Order Report': {
    'store_information': generateData(['store_code', 'store_name']),
    'order_information': generateData(['order_id','placed', 'customer', 'mobile_number']),
    'company': generateData(["company_name","sub_company",
    "business_account_name", "business_account_no.", "department_name", "employee_iD", "cost_center", "sub_cost_center",]),
    'products': generateData(['shirt','pant', 'jacket', 'nornex_pant','nornex_shirt','coverall', 'gloves', 'fire_jacket',
      'fire_pant']),
  },
  '(B2B) Consolidated Orders Report': {
    "": generateData(['Sr No.']),
    'store_information': generateDatafalseStore(['store_iD', 'store_name']),
    'selected_period': generateDatafalse(['orders','pieces',]),
    'previous_period': generateDatafalse(['orders','pieces',]),
    'difference_in_figure': generateDatafalse(["orders_difference","pieces_differences"]),
    'difference_in_percentage': generateDatafalse(["orders_difference","pieces_differences"]),
  },
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  permissionStatus: PermissionStatus;
    selectedFromDate: string;
    selectedToDate: string;
    selectedStore: Array<number>;
    selectedGroup: Array<number>;
    storeList: Array<IOption>,
    selectedRegion: Array<number>;
    selectedArea: Array<number>;
    regionList: Array<IOption>
    reportType: DropdownType | null;
    filterFields: SalesAndRevenueReport[] | ReportItemWithSection[] | ReportItem[] | SalesAndRevenueReport
    reportList: DropdownType[];
    showFilterOptions: boolean;
    message: {
      name: string;
      type: "error" | "success" | "info" | "warning" | undefined;
    };
    showLoading: boolean;
    isLoadingPermission: boolean
    isB2bActive: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class B2bOrderReportConroller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  storeListMessageId: string = "";
  regionListMessageId: string = "";
  exportOrderSalesReportMessageAPIId: string = "";
  dailySalesFilterListMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.BroadcastNavbarDataMessage),
      getName(MessageEnum.LayoutDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      selectedFromDate: '',
      selectedToDate: '',
      selectedRegion: [],
      regionList: [],
      selectedStore: [],
      selectedArea: [],
      selectedGroup: [],
      storeList: [],
      reportType: null,
      filterFields: [],
      reportList: reportListData,
      showFilterOptions: false,
      message: {
        name: '',
        type: 'info'
      },
      showLoading: false,
      isLoadingPermission: true,
      isB2bActive: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.receiveBroadcastedData(message);
    if (
      this.exportOrderSalesReportMessageAPIId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(!responseJson) {
        const errorMessage = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        return this.setState({
          message: {
            name: conditionalString(
              errorMessage && typeof errorMessage === "string",
              errorMessage,
              "An error has occurred. Please try again later."
            ),
            type: "error"
          },
          showLoading: false
        })
      }

      if(responseJson.error){
        return this.setState({
          message: {name:responseJson.error,type:"error"},
          showLoading: false,
        });
      }

      let responseType = this.state.message;
      responseType = { name: responseJson.message, type: "success" };

      this.setState({
        message: responseType,
        showLoading: false,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
   
  }


  handleFromDateInput(varDate: Date | null) {
    this.setState({
      selectedFromDate: moment(varDate).format("YYYY-MM-DD"),
    });
  }
  handleToDateInput(varDate: Date | null) {
    this.setState({
      selectedToDate: moment(varDate).format("YYYY-MM-DD"),
    });
  }

  handleReportChange = (newSelectedReportType: DropdownType | null) =>{
    this.setState({
      reportType: newSelectedReportType,
      showFilterOptions: false,
    }, () => {
      const reportType = newSelectedReportType?.option || '';
      const copyFilterFields = JSON.parse(JSON.stringify(fieldsData[reportType]));
      console.log("copyFilterFields", copyFilterFields, reportType)
        this.setState({
          filterFields: copyFilterFields
        })
    })
  }

  selectAllRevenueFields = (filterFields: RevenueReport, isCheckboxClicked: boolean, isReportChange: boolean) => {
    Object.keys(filterFields).forEach((key) => {
      if (key === 'total_for_each_column') {
       filterFields[key] = isCheckboxClicked
      } else {
        (filterFields[key] as ReportItem[]).forEach((field: ReportItem) => {
            field.checked = isCheckboxClicked
        })
      }
    })
    if (!isReportChange) {
      this.setState({
        filterFields: filterFields as SalesAndRevenueReport
      })
    }
  }

  updateCheckbox = (fields: ReportItem[], currentField: ReportItem | null, isSelectAll=false, filterType='', ) => {
    const selectedAll = fields.every(field => field.checked)

    fields.forEach((field: ReportItem) => {

      if(field.label === currentField?.label) {
        field.checked = !field.checked
      }
      if(isSelectAll) {
        field.checked = !selectedAll
      }
    })

    if(filterType === 'simpleFilter') {
      this.setState({
        filterFields: fields
      })
    }
  }

  handleFilter = (currentField: any, parentElement: any, subKey='') => {
    const copyFilterFields = JSON.parse(JSON.stringify(this.state.filterFields));
      if(parentElement === 'total_for_each_column') {
        copyFilterFields[parentElement] = !copyFilterFields[parentElement]
      } else {
        if(Array.isArray(copyFilterFields[parentElement])) {
          this.updateCheckbox(copyFilterFields[parentElement], currentField)
        } else {
          Object.keys(copyFilterFields[parentElement]).forEach((key) => {
            if(subKey && subKey === key) {
              this.updateCheckbox(copyFilterFields[parentElement][key], currentField)
            }
          })
        }
      }
 
    this.setState({
      filterFields: copyFilterFields
    })
  }

  validateFilters = () => {
    const { reportType, selectedFromDate, selectedToDate, selectedStore} = this.state;
    console.log("DasreportType", reportType, selectedFromDate, selectedToDate,moment(selectedToDate).isSameOrAfter(selectedFromDate))
    if(reportType && selectedFromDate && selectedToDate && moment(selectedToDate).isSameOrAfter(selectedFromDate)) {
      return true;
    }
    return false;
  }

  getAllTheCheckedColumns = () => {
    const copyFilterFields = JSON.parse(JSON.stringify(this.state.filterFields));
    console.log("copyFilterFields",copyFilterFields)
    if(Array.isArray(copyFilterFields)) {
      return copyFilterFields.filter((field) => field.checked).map(field => field.label);
    } else {
      const checkedFields: any = {}
      Object.keys(copyFilterFields).forEach(key => {
          if(key !== 'total_for_each_column') {
              checkedFields[key] = copyFilterFields[key].reduce((obj: any, field: ReportItem) => {                      
                    return { ...obj, 
                      ...(field.checked && {[field.label]: field.checked})
                    }
              }, {})
          } else {
            if(copyFilterFields[key]) {
              checkedFields[key] = copyFilterFields[key]
            }
          }
      })
      return checkedFields;
    }
    
  }

  checkIfAllFieldsChecked = () => {
    let fieldsNotSelected: boolean[] = [];
    const copyFilterFields = JSON.parse(JSON.stringify(this.state.filterFields));

    Object.keys(copyFilterFields).forEach(key => {
      if (key !== 'total_for_each_column') {
        fieldsNotSelected = [...fieldsNotSelected, ...copyFilterFields[key].filter((fieldObj: ReportItem) => !fieldObj.checked).map((fieldData: ReportItem) => fieldData.checked)]
      } else {
        if (!copyFilterFields[key]) {
          fieldsNotSelected.push(copyFilterFields[key])
        }
      }
    })
    if (fieldsNotSelected.length > 0) {
      return false;
    }
    return true
  }


  handleShowFilter = () => {
      this.setState({
        showFilterOptions: !this.state.showFilterOptions
      })
  }

  handleCloseSnackbar = () => {
    this.setState({
      message: {
        type: "info",
        name: "",
      },
    });
  };

  receiveBroadcastedData = (message: Message) => {
    if (message.id === getName(MessageEnum.BroadcastNavbarDataMessage)) {
      const receivedData = message.getData(
        getName(MessageEnum.BroadcastNavbarData)
      );
      const {storeId,regionMultiId,groupId,areaId} = receivedData
      const regionIdArray =regionMultiId ? regionMultiId.split(',').map(Number) : [];
      const storeIdArray = storeId ? storeId.split(',').map(Number): [];
      const groupIdArray = groupId ? String(groupId).split(',').map(Number): [];
      const areaIdArray = areaId ? String(areaId).split(',').map(Number): [];

      this.setState({selectedArea:areaIdArray ,selectedRegion: regionIdArray, selectedStore:storeIdArray,selectedGroup:groupIdArray})
    }
  }

  handleExportClick = () => {
      const { reportType } = this.state
        if (this.validateFilters()) {
          this.setState({
            showLoading: true
          })
          let url = configJSON.salesReportingEndPoint1[reportType ? reportType.option : ''];
          let headers = {
            token: localStorage.getItem(configJSON.token),
            "Content-Type": configJSON.exampleApiContentType,
          };
          const { selectedFromDate, selectedToDate, selectedStore,selectedGroup, selectedArea} = this.state;
          let filterColumns: Array<string> = [];
          const {startDate: start_date, endDate: end_date} = getDateRangeForQuery(selectedFromDate, selectedToDate)
            let body = {
            start_date,
            end_date,
            report_type: configJSON.salesReportType[reportType?.option || '']  ? configJSON.salesReportType[reportType?.option || ''] : reportType?.option,
            region_ids: this.state.selectedRegion,
            store_ids: selectedStore,
            group_ids: selectedGroup,
            area_ids: selectedArea,
            filter_columns: this.getAllTheCheckedColumns()
          };
  
          const getAccount = makeApiMessage({
            body: JSON.stringify(body),
            headers: headers,
            url: url,
            method: configJSON.examplePostAPiMethod
          });
          this.exportOrderSalesReportMessageAPIId = getAccount.messageId;
          runEngine.sendMessage(getAccount.id, getAccount);
        }
    }

  capitalizeFirstCharacter = (sentence: string) => {
    let label = sentence;
    if(label.includes('_id')) {
      label = label.replace('id', 'ID')
    }
    if(label === 'amount_without_vat') {
      return 'Amount (Without VAT)'
    }
    if(label.includes('vat')) {
      label = label.replace('vat', 'VAT')
    }
    let words = label.split('_');
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    let capitalizedSentence = words.join(' ');
    return capitalizedSentence;
  }

  formatDate = (inputString?: string) => inputString ? moment(inputString).format("DD-MM-YYYY") : ""
  // Customizable Area End
}
