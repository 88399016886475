import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { IFilter } from "../../../components/src/FilterPopover";
import { PermissionName } from "../../../blocks/navigationmenu/src/utils";
import momentTz from "moment-timezone"
import moment from "moment";

export interface INavigateTo {
  id?: string;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}

export function navigateTo({
  id,
  props,
  screenName,
  raiseMessage,
}: INavigateTo) {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationPropsMessage), props);
  message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);

  id && message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
  raiseMessage &&
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

  runEngine.sendMessage(message.id, message);
}

export function handle422Errors(responseJson: unknown, callBack?: Function) {
  const { errors, status } = responseJson as {
    status: number;
    errors?: {
      [key: string]: string | Array<string>;
    };
  };

  const errorObject = {} as {
    [key: string]: string;
  };

  if (status == 422 && errors) {
    Object.keys(errors).forEach((keyName: string) => {
      errorObject[keyName] = Array.isArray(errors[keyName])
        ? (errors[keyName] as string[]).join(",")
        : (errors[keyName] as string);
    });

    const hasErrors = Object.keys(errorObject).length > 0;

    hasErrors && callBack && callBack(errorObject);

    return !hasErrors;
  }

  return true;
}
export enum CustomEnums {
  ImportExportPopupMeassage = 100,
  ImportExportPopupDoneMessage,
  ImportExportPopupFileUploadMessage,
  ImportExportPopupFileUploadMessageP,
  ImportExportPopupFileMessage,
  ImportExportAPIResponse,
  ImportExportClearFileMessage,
  ImportExportPopupFileMessagetest,
  ImportExportPopupClose,
  ImportExportPopupCloseButtonClicked,
  ImportExportErrorPopupData,
  ImportExportErrorPopupGoBack,
  RewashModalOrderDataPass,
  CustomActionReducers,
  CustomReducerAction,
  CustomReducerPayload,
  PageContainerNavigateMessage,
  DataForRedirectionFromNotification,
  ImportAPIDoneRepsonseMessage,
  ReceiptPrinterSettingUpdated
}

export const getCustomEnumName = (myEnum: CustomEnums) => {
  return CustomEnums[myEnum];
};

export const randomNumberGenerator = (min: number, max: number) => {
  const timestamp = new Date().getTime();
  let seed = (timestamp % (max - min + 1)) + min;

  const next = () => {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };

  const range = max - min + 1;
  return Math.floor(next() * range) + min;
};

export const sortCondition = (
  flag: boolean,
  trueValue: unknown,
  falseValue: unknown
) => {
  return flag ? trueValue : falseValue;
};

export const sortStringCondition = (
  flag: boolean,
  trueValue: string,
  falseValue: string
) => {
  return flag ? trueValue : falseValue;
};

export const conditionalString = (
  condition: unknown,
  valueIfTrue: string,
  valueIfFalse: string
) => {
  return condition ? valueIfTrue: valueIfFalse;
};

export const renderBaseOnConditions = (
  flag: boolean,
  trueValue: React.ReactNode,
  falseValue: React.ReactNode
) => {
  return flag ? trueValue : falseValue;
};

export const renderBooleanConditions = (
  flag: boolean,
  trueValue: boolean,
  falseValue: boolean
) => {
  return flag ? trueValue : falseValue;
};

export const customPermissionApiKey = {
  upChargePermission: "Upcharge List Creation",
  homeCleanProductPermission: "Home Cleaning Product",
  company: "Company",
  regionPermissions: "Region Creation",
  loyaltySystem: "Loyalty System",
  sectionPermissions: "Sections Management",
  promocodePermissions: "Promo Code",
  homeCleanSettingPermissions: "Home cleaning setting",
  roleAssignment:"Role Assignment",
  servicesCreation: "Services Creation",
  activatePermissions: "Activate Permissions",
  orderCreation: "Order Creation",
  wallet: "Wallet",
  customerTypeManagement: "Customer Type Management",
  galleryModule: "Gallery Module",
  plantOrderManagement: "Plant Order Management",
  homecleaningsetting: "Home cleaning setting",
  manageDriverOrders: "Manage Driver Orders",
  ticketingTeamManagement: "Ticketing Team Management",
  transferRequest: "Transfer Request",
  homeCleaningProduct: "Home Cleaning Product",
  priceListPermissons: "Price List Management",
  taxcalculator: "Tax",
  inviteFriendModule: "Invite Friend",
  customerType: "Customer Type",
  areaPermissions: "Area",
  specificationMPermission: "Product Specifications",
  storeManagementPermission: "Store",
  customerPermission: "Customer",
  regionNewPermission: "Region",
  productManagement: "Product",
  professionPermission: "Profession",
  upChargeNewPermission: "Upcharge",
  galleryPermission: "Gallery",
  servicePermission: "Service",
  sectionNewPermissions: "Section",
  rolePermission: "Role",
  organisationPermission: "Organisation",
  accountPermission: "Account",
  preferenceListCreation: "Preference",
  priceList: "Price List",
  plantCreation:"Plant",
  cashierPermission: "Cashier",
  cashupPermission: "Cashup",
  orderPermission: "Order",
  plantManagement:"Plant management",
  dataImportExportPermission: "Data Import/Export",
  dashboardPermission: "Dashboard",
  hardwarePermission: "Hardware",
  b2bCustomerGroupPermission: "Customer Group",
  b2bCustomersPermission: "Business Account Customer",
  b2bProductMasterPermission: "Product Master",
  b2bBusinessAccountPermission: "Business Account",
  b2BPriceListPermission: "Catalogue",
  b2BOrderPermission: "B2b Order"
};

export interface PermissionStatus {
  mainPermission: boolean;
  createPermission: boolean;
  viewPermission: boolean;
  editPermission: boolean;
  deactivatePermission: boolean;
  activatePermission?: boolean;
  priceAddProdPermission?: boolean;
  exportPermission?: boolean;
  groupingPermission?: boolean;
  CashReceivePermission?: boolean;
  addPermission?: boolean;
  isHaveLinkPricelistPermission?: boolean;
  renewBusinessAccountPermission?: boolean;
}

export interface OrderPermissionStatus {
  mainPermission: boolean;
  createOrderPermission: boolean;
  viewOrderPermission: boolean;
  editOrderPermission: boolean;
  sendToDriverAction: boolean;
  cleanAction: boolean;
  rewashAction: boolean;
  sendToDeadStockAction: boolean;
  storeDropdownAction: boolean;
  cleaningDropdownAction: boolean;
  readyDropdownAction: boolean;
  zatcaLogsAccess: boolean;
  viewCreditOrderAccess: boolean;
  createCreditNoteAccess: boolean;
}
export interface PlantPermissionStatus {
  mainPermission: boolean;
  storeAccessPermission: boolean;
  storeActionPermission: boolean;
  receivingAreaAccessPermission: boolean;
  WashPressingAccessPermission: boolean;
  shortingAreaAccessPermission: boolean;
  loadingAreaAccessPermission: boolean;
  dispatchedAccessPermission: boolean;
  adjustmentAccessPermission: boolean;
  receivingAreaActionPermission: boolean;
  washAndPressingActionPermission: boolean;
  shortingAreaActionPermission: boolean;
  loadingAreaActionPermission: boolean;
  dispatchedActionPermission: boolean;
  adjustmentActionPermission: boolean;
  creditNotePermission: boolean;
  adjustPermission: boolean;
  cleanOrderActionPermission: boolean;
}

export interface DashboardPermissionStatus {
  mainPermission: boolean,
  dashboardAccessPermission: boolean;
  overviewAccessPermission: boolean;
  popularTimeAccessPermission: boolean;
  salesAccessPermission: boolean;
  revenueAccessPermission: boolean;
  discountCreditAccessPermission: boolean;
  sectionSalesAccessPermission: boolean;
  plantReportAccessPermission: boolean;
  customerAccessPermission: boolean;
}

export interface HardwarePermissionStatus {
  mainPermission: boolean,
  receiptPrinterActionPermission: boolean;
  garmentTagPrinterActionPermission: boolean;
  metalProgettiAccessPermission: boolean;
  metalProgettiActionPermission: boolean;
}

export interface AdvancedSearchPermissionStatus {
  mainPermission: boolean,
}

export const checkForPermissonStatus = (
  apiKey: string,
  permisionGroup: PermissionGroupArray[]
) => {
  const permissionStatus: PermissionStatus = {
    mainPermission: false,
    createPermission: false,
    viewPermission: false,
    editPermission: false,
    deactivatePermission: false,
    activatePermission: false
  };

  const permissionIndex = permisionGroup.findIndex((permission) => {
    return permission.name === apiKey;
  });

  if (permissionIndex !== -1) {
    const tempPermissionGroup =
      permisionGroup[permissionIndex].permission_sub_groups;
    tempPermissionGroup.forEach((subPermission) => {
      if (subPermission.name === "Create") {
        permissionStatus["createPermission"] = subPermission.permitted;
      } else if (subPermission.name === "Update") {
        permissionStatus["editPermission"] = subPermission.permitted;
      } else if (subPermission.name === "Read") {
        permissionStatus["viewPermission"] = subPermission.permitted;
      } else {
        permissionStatus["deactivatePermission"] = subPermission.permitted;
      }
    });
    permissionStatus["mainPermission"] =
      permisionGroup[permissionIndex].permitted;
  }

  return permissionStatus;
};

export const checkForNewPermissonStatus = (
  apiKey: string,
  permisionGroup: PermissionGroupArray[]
) => {
  const permissionStatus: PermissionStatus = {
    mainPermission: false,
    createPermission: false,
    viewPermission: false,
    editPermission: false,
    deactivatePermission: false,
    activatePermission: false,
    priceAddProdPermission: false,
    exportPermission: false,
    groupingPermission: false,
    CashReceivePermission: false,
    addPermission: false,
    isHaveLinkPricelistPermission: false,
    renewBusinessAccountPermission: false
  };

  const permissionIndex = permisionGroup.findIndex((permission) => {
    return permission.name === apiKey
  });

  if (permissionIndex !== -1) {
    const tempPermissionGroup =
      permisionGroup[permissionIndex].permission_sub_groups;
      tempPermissionGroup.forEach((subPermission) => {
      switch (subPermission.sub_group_key) {
        case "create":
          permissionStatus["createPermission"] = subPermission.permitted;
          break;
        case "update":
          permissionStatus["editPermission"] = subPermission.permitted;
          break;
        case "read":
          permissionStatus["viewPermission"] = subPermission.permitted;
          break;
        case "deactivate":
          permissionStatus["deactivatePermission"] = subPermission.permitted;
          break;
        case "activate":
          permissionStatus["activatePermission"] = subPermission.permitted;
          break;
        case "add_price_list":
          permissionStatus["priceAddProdPermission"] = subPermission.permitted;
          break;
        case "export":
          permissionStatus["exportPermission"] = subPermission.permitted;
          break;
        case "grouping_service":
          permissionStatus["groupingPermission"] = subPermission.permitted;
          break;
        case "grouping_section":
          permissionStatus["groupingPermission"] = subPermission.permitted;
          break;
        case "update_status":
          permissionStatus["CashReceivePermission"] = subPermission.permitted;
          break;
        case "add_permissions":
          permissionStatus["addPermission"] = subPermission.permitted;
          break;
        case "link_price_list":
          permissionStatus["isHaveLinkPricelistPermission"] = subPermission.permitted;
          break;
        case "renew_business_account":
          permissionStatus["renewBusinessAccountPermission"] = subPermission.permitted;
          break;
        default:
          break;
      }
    });
    permissionStatus["mainPermission"] =
      permisionGroup[permissionIndex].permitted;
  }

  return permissionStatus;
};

export const checkForOrderPermissonStatus = (
  apiKey: string,
  permisionGroup: PermissionGroupArray[]
) => {
  const permissionStatus: OrderPermissionStatus = {
    mainPermission: false,
    createOrderPermission: false,
    viewOrderPermission: false,
    editOrderPermission: false,
    sendToDriverAction: false,
    cleanAction: false,
    rewashAction: false,
    sendToDeadStockAction: false,
    storeDropdownAction: false,
    cleaningDropdownAction: false,
    readyDropdownAction: false,
    zatcaLogsAccess: false,
    viewCreditOrderAccess: false,
    createCreditNoteAccess: false
  };

  const permissionIndex = permisionGroup.findIndex((permission) => {
    return permission.name === apiKey;
  });

  if (permissionIndex !== -1) {
    const tempPermissionGroup =
      permisionGroup[permissionIndex].permission_sub_groups;
    tempPermissionGroup.forEach((subPermission) => {
      switch (subPermission.sub_group_key) {
        case "create":
          permissionStatus["createOrderPermission"] = subPermission.permitted;
          break;
        case "read":
          permissionStatus["viewOrderPermission"] = subPermission.permitted;
          break;
        case "update":
          permissionStatus["editOrderPermission"] = subPermission.permitted;
          break;
        case "send_to_driver_action":
          permissionStatus["sendToDriverAction"] = subPermission.permitted;
          break;
        case "clean_action":
          permissionStatus["cleanAction"] = subPermission.permitted;
          break;
        case "rewash_action":
          permissionStatus["rewashAction"] = subPermission.permitted;
          break;
        case "send_to_dead_stock_action":
          permissionStatus["sendToDeadStockAction"] = subPermission.permitted;
          break;
        case "store_action":
          permissionStatus["storeDropdownAction"] = subPermission.permitted;
          break;
        case "ready_action":
          permissionStatus["readyDropdownAction"] = subPermission.permitted;
          break;
        case "cleaning_action":
          permissionStatus["cleaningDropdownAction"] = subPermission.permitted;
          break;
        case "zatca_logs_access":
          permissionStatus["zatcaLogsAccess"] = subPermission.permitted;
          break;
        case "view_credit_order":
          permissionStatus["viewCreditOrderAccess"] = subPermission.permitted;
          break;
        case "create_credit_note":
          permissionStatus["createCreditNoteAccess"] = subPermission.permitted;
          break;
        default:
          break;
      }
    });
    permissionStatus["mainPermission"] =
      permisionGroup[permissionIndex].permitted;
  }

  return permissionStatus;
};

export const checkForPlantPermissonStatus = (
  apiKey: string,
  permisionGroup: PermissionGroupArray[]
) => {
  const permissionStatus: PlantPermissionStatus = {
    mainPermission: false,
    storeAccessPermission: false,
    storeActionPermission: false,
    receivingAreaAccessPermission: false,
    WashPressingAccessPermission: false,
    shortingAreaAccessPermission: false,
    loadingAreaAccessPermission: false,
    dispatchedAccessPermission: false,
    adjustmentAccessPermission: false,
    receivingAreaActionPermission: false,
    washAndPressingActionPermission: false,
    shortingAreaActionPermission:false,
    loadingAreaActionPermission:false,
    dispatchedActionPermission:false,
    adjustmentActionPermission:false,
    creditNotePermission:false,
    adjustPermission: false,
    cleanOrderActionPermission: false
  };
 
  const permissionIndex = permisionGroup.findIndex((permission) => {
    return permission.name === apiKey
  });
 
  if (permissionIndex !== -1) {
    const tempPermissionGroup =
      permisionGroup[permissionIndex].permission_sub_groups;
      tempPermissionGroup.forEach((subPermission) => {
      switch (subPermission.sub_group_key) {
        case "store_access":
          permissionStatus["storeAccessPermission"] = subPermission.permitted;
          break;
        case "store_action":
          permissionStatus["storeActionPermission"] = subPermission.permitted;
          break;
        case "recieving_area_access":
          permissionStatus["receivingAreaAccessPermission"] = subPermission.permitted;
          break;
        case "washing_access":
          permissionStatus["WashPressingAccessPermission"] = subPermission.permitted;
          break;
        case "shorting_area_access":
          permissionStatus["shortingAreaAccessPermission"] = subPermission.permitted;
          break;
        case "loadin_area_access":
          permissionStatus["loadingAreaAccessPermission"] = subPermission.permitted;
          break;
        case "dispatched_access":
          permissionStatus["dispatchedAccessPermission"] = subPermission.permitted;
          break;
        case "adjustment_access":
          permissionStatus["adjustmentAccessPermission"] = subPermission.permitted;
          break;
        case "receving_area_action":
          permissionStatus["receivingAreaActionPermission"] = subPermission.permitted;
          break;
        case "washings_action":
          permissionStatus["washAndPressingActionPermission"] = subPermission.permitted;
          break;
        case "shorting_area_action":
          permissionStatus["shortingAreaActionPermission"] = subPermission.permitted;
          break;
        case "loading_area_action":
          permissionStatus["loadingAreaActionPermission"] = subPermission.permitted;
          break;
        case "dispatched_action":
          permissionStatus["dispatchedActionPermission"] = subPermission.permitted;
          break;
        case "adjustment_action":
          permissionStatus["adjustmentActionPermission"] = subPermission.permitted;
          break;
        case "credit_note":
          permissionStatus["creditNotePermission"] = subPermission.permitted;
          break;
        case "adjust":
          permissionStatus["adjustPermission"] = subPermission.permitted;
          break;
        case "clean_action":
          permissionStatus["cleanOrderActionPermission"] = subPermission.permitted;
          break;
        default:
          break;
      }
    });
    permissionStatus["mainPermission"] =
      permisionGroup[permissionIndex].permitted;
  }
 
  return permissionStatus;
};

export const checkForImportExportPermissionStatus = (
  apiKey: string,
  permisionGroup: PermissionGroupArray[]
) => {
  const permissionStatus = {
    exportPermission: false
  };

  const permissionIndex = permisionGroup.findIndex((permission) => {
    return permission.name === apiKey;
  });

  if (permissionIndex !== -1) {
    const tempPermissionGroup =
      permisionGroup[permissionIndex].permission_sub_groups;
    tempPermissionGroup.forEach((subPermission) => {
      switch (subPermission.sub_group_key) {
        case "import_export":
          permissionStatus["exportPermission"] = subPermission.permitted;
          break;
        default:
          break;
      }
    });
  }

  return permissionStatus;
};


export const checkForDashboardPermissonStatus = (
  apiKey: string,
  permisionGroup: PermissionGroupArray[]
) => {
  const permissionStatus: DashboardPermissionStatus = {
    mainPermission: false,
    dashboardAccessPermission: false,
    overviewAccessPermission: false,
    popularTimeAccessPermission: false,
    salesAccessPermission: false,
    revenueAccessPermission: false,
    discountCreditAccessPermission: false,
    sectionSalesAccessPermission: false,
    plantReportAccessPermission: false,
    customerAccessPermission: false
  };

  const permissionIndex = permisionGroup.findIndex((permission) => {
    return permission.name === apiKey;
  });

  if (permissionIndex !== -1) {
    const tempPermissionGroup =
      permisionGroup[permissionIndex].permission_sub_groups;
    tempPermissionGroup.forEach((subPermission) => {
      switch (subPermission.sub_group_key) {
        case "view_dashboard":
          permissionStatus["dashboardAccessPermission"] = subPermission.permitted;
          break;
        case "view_overview":
          permissionStatus["overviewAccessPermission"] = subPermission.permitted;
          break;
        case "view_popular":
          permissionStatus["popularTimeAccessPermission"] = subPermission.permitted;
          break;
        case "view_sales":
          permissionStatus["salesAccessPermission"] = subPermission.permitted;
          break;
        case "view_revenue":
          permissionStatus["revenueAccessPermission"] = subPermission.permitted;
          break;
        case "view_discount":
          permissionStatus["discountCreditAccessPermission"] = subPermission.permitted;
          break;
        case "view_section_sales":
          permissionStatus["sectionSalesAccessPermission"] = subPermission.permitted;
          break;
        case "view_plant_report":
          permissionStatus["plantReportAccessPermission"] = subPermission.permitted;
          break;
        case "view_customer":
          permissionStatus["customerAccessPermission"] = subPermission.permitted;
          break;
        default:
          break;
      }
    });
    permissionStatus["mainPermission"] =
      permisionGroup[permissionIndex].permitted;
  }

  return permissionStatus;
};

export const checkForHardwarePermissonStatus = (
  apiKey: string,
  permisionGroup: PermissionGroupArray[]
) => {
  const permissionStatus: HardwarePermissionStatus = {
    mainPermission: false,
    receiptPrinterActionPermission: false,
    garmentTagPrinterActionPermission: false,
    metalProgettiAccessPermission: false,
    metalProgettiActionPermission: false,
  };

  const permissionIndex = permisionGroup.findIndex((permission) => {
    return permission.name === apiKey;
  });

  if (permissionIndex !== -1) {
    const tempPermissionGroup =
      permisionGroup[permissionIndex].permission_sub_groups;
    tempPermissionGroup.forEach((subPermission) => {
      switch (subPermission.sub_group_key) {
        case "receipt_printer_action":
          permissionStatus["receiptPrinterActionPermission"] = subPermission.permitted;
          break;
        case "garment_tag_printer_action":
          permissionStatus["garmentTagPrinterActionPermission"] = subPermission.permitted;
          break;
        case "metal_progetti_action":
          permissionStatus["metalProgettiActionPermission"] = subPermission.permitted;
          break;
        case "metal_progetti_access":
          permissionStatus["metalProgettiAccessPermission"] = subPermission.permitted
        default:
          break;
      }
    });
    permissionStatus["mainPermission"] =
      permisionGroup[permissionIndex].permitted;
  }

  return permissionStatus;
};

export const checkForAdvancedSearchPermissionStatus = (
  permisionGroups: PermissionGroupArray[],
  apiKey = "advance_search"
) => {
  const permissionGroup = permisionGroups.find((permission) => {
    return permission.permission_group_key === apiKey;
  });
  const accessPermission = Boolean(permissionGroup?.permission_sub_groups.find(subPermission => subPermission.sub_group_key === "read")?.permitted)
  return {
    mainPermission: accessPermission
  } as AdvancedSearchPermissionStatus
}

export const formatNumberWithCommas = (digits: string | number) => {
  return digits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getOptionByValue = (
  searchValue: string | number | null,
  dataArray: { id: string | number }[],
): { id: string | number } | null => {
  return dataArray.find(dataItem => dataItem.id === searchValue) || null;
};

export const getOptionLabelByValue = (
  searchValue: string,
  searchProperty: string,
  dataArray: { [key: string]: string }[],
  returnProperty: string
): string => {
  const foundObject = dataArray.find((obj) => obj[searchProperty] == searchValue);
  return foundObject ? foundObject[returnProperty] : (searchValue || "");
};

export const safeExtraction = (
  value: { [key: string]: unknown } | null | undefined,
  property: string
): string => {
  if (value) {
    return String(value?.[property]);
  } else {
    return "";
  }
}

export function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout> | null;

  return function (this: unknown, ...args: Parameters<T>) {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      timeout = null;
      func.apply(this, args);
    }, wait);
  };
}

export const checkIsFilterApplied = (filters: IFilter[],falsyValue?:string) => {
  let value = false;
  filters.forEach((filter) => {
    if (value) {
      return;
    } else {
      if (filter.value) {
        console.log(falsyValue == filter.value,"falsyValue == filter.value")
        if(falsyValue == filter.value){
          value = false
        }else{
          value = true;
        }
      }
    }
  });
  return value;
};

export const getLandingPageRedirection = (userData: {
  role: {
    name: string
  },
  permission_groups: PermissionGroupArray[]
}) => {
  const { role = { name : "" } , permission_groups = []} = userData || {}
  const roleName = role.name
  if (["Plant User", "Plant Management User"].includes(roleName)) return "Cfplantdashboard2";
  const pages: Array<{
    permission: PermissionName
    subPermission?: string
    additionalPermission?: PermissionName
    screenName: string
  }> = [
    { permission: "Dashboard", subPermission: "view_dashboard", screenName: "MainDashboard" },
    { permission: "Dashboard", subPermission: "view_overview", screenName: "DashboardOverview" },
    { permission: "Dashboard", subPermission: "view_section_sales", screenName: "SectionSales" },
    { permission: "Dashboard", subPermission: "view_plant_report", screenName: "PlantReport" },
    { permission: "Dashboard", subPermission: "view_popular", screenName: "PopularTimesDashboard" },
    { permission: "Dashboard", subPermission: "view_sales", screenName: "SalesDashboard" },
    { permission: "Dashboard", subPermission: "view_revenue", screenName: "RevenueDashboard" },
    { permission: "Dashboard", subPermission: "view_customer", screenName: "CustomerDashboard" },
    { permission: "Dashboard", subPermission: "view_discount", screenName: "DiscountCreditSection" },
    { permission: "Advance Search", screenName: "AdvancedSearch" },
    { permission: "Report Export", subPermission: "export", screenName: "SalesReporting" },
    { permission: "Plant Order Management", screenName: "Cfplantdashboard2" }
  ]
  const pageToRedirect = pages.find(page => {
    const permissionGroup = permission_groups.find(permission_group => permission_group.name === page.permission)
    const additionalPermission = page.additionalPermission && permission_groups.find(permission_group => permission_group.name === page.additionalPermission)
    const subGroupReqPermission = page.subPermission ? Boolean(permissionGroup?.permission_sub_groups.find(permission_sub_group => permission_sub_group.sub_group_key === page.subPermission)?.permitted) : true
    return Boolean(permissionGroup?.permitted || additionalPermission?.permitted) && subGroupReqPermission
  })
  return pageToRedirect?.screenName || "AdvancedSearch";
}

export const getDateRangeForQuery = (start_date = "", end_date = "") => {
  const timeZone = 'Asia/Riyadh';
  const timeFormat = "YYYY-MM-DDTHH:mm:ssZ";
  const startDate = start_date ? momentTz(start_date).tz(timeZone, true).startOf('day').format(timeFormat) : "";
  const endDate = end_date ? momentTz(end_date).tz(timeZone, true).endOf('day').format(timeFormat) : "";
  const dateRangeQuery = (startDate ? `&start_date=${encodeURIComponent(startDate)}` : "") + (endDate ? `&end_date=${encodeURIComponent(endDate)}` : "")

    return {
        startDate,
        endDate,
        dateRangeQuery
    };

}