// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import { IMyUser, headerConfig } from "./utils";
import lodash, { debounce } from "lodash";
const baseURL = require("../../../framework/src/config").baseURL;
import {
  CustomEnums,
  getCustomEnumName,
  sortCondition,
  renderBooleanConditions
} from "../../utilities/src/CustomBlockHelpers";
import { handleLogout, makeApiMessage } from "../../../components/src/common";
import PageContainerWebAdapter from "../../adapters/src/PageContainerWebAdapter";
import {
  getStorageData,
  setStorageData,
  getEnvironment
} from "../../../framework/src/Utilities";
export const configJSON = require("./config");

export interface ReceiptPrinterSetting {
  id: number,
  account_id: number,
  printer_name: string | null,
  printer_type: null,
  station: null,
  auto_print_receipt: boolean,
  auto_print_pay_on_pickup: boolean,
  receipt_print_new_order: boolean,
  receipt_print_clean_page: boolean,
  receipt_print_ready_page: boolean,
  receipt_print_delivered_page: boolean,
  no_of_copies: number | null
  printer_setting_type?: string,
  created_at?: string
  updated_at?: string
}

export interface IUserContext {
  isSuperAdmin: boolean;
  user: IMyUser | undefined;
  refreshUser: () => void;
}
export interface ISidebarDropdown {
  store: boolean;
  settings: unknown;
  general: unknown;
  products: boolean;
  marketing: boolean;
  customer: boolean;
  homeCleanProduct: boolean;
  hardware: boolean;
}

interface RegionAttributes {
  id: number;
  branch_name: string;
  name: string;
}

interface RegionDropdown {
  id: string;
  type: string;
  attributes: RegionAttributes;
}

interface StoreAttributes {
  id: number;
  store_name: string;
  name_translation: string;
  from_store_group: boolean
}

interface StoreManagementDropdown {
  id: string;
  type: string;
  attributes: StoreAttributes;
}

type RecordsToShowByGroup = {
  [groupId: string]: number;
};

interface AreaManagementDropdown {
  id: string;
  type: string;
  attributes:  {
    "id": number,
    "area_name": string,
    "store_managements": StoreManagementDropdown
  };
}

interface GroupManagementDropdown {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    "store_managements_count": boolean,
    areas: {
      id: number;
      name: string;
      "store_managements": {
        data: StoreManagementDropdown[]
      }
    }[],
    "store_managements": {
      data: StoreManagementDropdown[]
    }
  };
}

interface SuggestionData {
    store_group: string[];
    area: string[];
    store: string[];
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: {
    location: { pathname: string },
    goBack: Function;
    navigate: Function;
    getParam: Function;
  };
  id?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  popoverOpen: boolean;
  searchText: string;
  myUser?: IMyUser;
  userShortName: string;
  tokenReady: boolean;
  isNotificationPopoverAnchor: HTMLElement | null;
  notificationCount: number;
  socket: WebSocket | null;
  newOrderNotificationSnackbar: boolean;
  regionListData: RegionDropdown[];
  regionId: string;
  storeListData: StoreManagementDropdown[];
  areaListData: AreaManagementDropdown[];
  searchValue: string;
  allStoreListData: StoreManagementDropdown[];
  storeId: string[];
  selectedStoresByArea: Record<number, string[]>; 
  storeIdByGroups: string[];
  areaId: string[]
  selectedAllStoreId: string[];
  selectAllStoreId: { id: string, option: string } | undefined
  selectAllStoreWIthGrpId: { id: string, option: string, type: string } | undefined
  groupId: string
  groupListData: GroupManagementDropdown[];
  storeListBygroupData: StoreManagementDropdown[]
  areaListByGroupData:  {
    "id": number,
    "name": string,
    "store_managements": {
      data: StoreManagementDropdown[]
    }
  }[];
  allAreaListByGroupData:  {
    "id": number,
    "name": string,
    "store_managements": {
      data: StoreManagementDropdown[]
    }
  }[];
  recordsToShowByGroup:RecordsToShowByGroup;
  storeCountByArea: RecordsToShowByGroup;
  recordsToShowArea:number;
  regionMultiId: string[];
  selectAllRegionId: { id: string, option: string } | undefined
  afterPermissionComponent?: React.ReactNode
  singleStoreSelectionId: string;
  storeSearchText: string;
  storeListPageNo: number;
  storeListOptions: { id: string; option: string }[];
  selectedGroupName: string;
  selectedSearchName: string;
  selectedSearchType: string;
  isB2bActive: boolean;
  isStoreGroupDropdownLoading: boolean;
  isLoadMoreGroupLoading: boolean;
  storeGrpSearch: SuggestionData;
  storeGroupPageNo: number
  storeGroupMeta: {
    "total_pages": number,
    "total_count": number,
    "current_page": number,
    "next_page": number | null,
    "pervious_page": number | null
  },
  storeGrpApiCalled: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class HeaderController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  popoverRef: HTMLElement | null = null;
  debounceTimer: NodeJS.Timeout | number = 0;
  getMyUserCallId: string = "";
  getNotificationCountApiCallId: string = "";
  getAllRegionApiCallId: string = "";
  getAllStoreApiCallId: string = "";
  getAllStoreAndGroupApiCallId: string = "";
  searchStoreAndGroupApiCallId: string = "";
  getPrinterSettingApiCallId: string = "";
  pageAdapter: PageContainerWebAdapter;
  storeListEmptyFlag: boolean = false;
  getStoreListApiId: string = "";
  storeGrpSearchApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.LayoutDataMessage),
      getName(MessageEnum.setEmptyHeaderSearchMessage),
      getCustomEnumName(CustomEnums.DataForRedirectionFromNotification),
    ];
    // Customizable Area End

    // Customizable Area Start
    this.pageAdapter = new PageContainerWebAdapter();

    this.state = {
      selectAllStoreId: { id: "-1", option: "Select All" },
      selectAllStoreWIthGrpId: undefined,
      groupListData: [],
      storeListBygroupData: [],
      areaListByGroupData: [],
      allAreaListByGroupData: [],
      storeId: [],
      storeIdByGroups: [],
      areaId: [],
      selectedAllStoreId: [],
      groupId: "",
      selectedStoresByArea: {},
      regionMultiId: [],
      singleStoreSelectionId: "",
      selectedSearchName: "",
      selectAllRegionId: undefined,
      popoverOpen: false,
      searchText: "",
      userShortName: "NA",
      tokenReady: false,
      isNotificationPopoverAnchor: null,
      notificationCount: 0,
      socket: null,
      newOrderNotificationSnackbar: false,
      isStoreGroupDropdownLoading: false,
      regionListData: [],
      regionId: "",
      storeListData: [],
      areaListData: [],
      searchValue: "",
      allStoreListData: [],
      storeSearchText: "",
      storeListPageNo: 1,
      storeListOptions: [],
      selectedGroupName: "",
      isB2bActive: false,
      storeGrpSearch: {
        area: [],
        store_group: [],
        store: []
      },
      recordsToShowByGroup: {} as RecordsToShowByGroup,
      storeCountByArea: {} as RecordsToShowByGroup,
      recordsToShowArea:10,
      storeGroupPageNo:1,
      isLoadMoreGroupLoading: false,
      selectedSearchType: "",
      storeGroupMeta: {
        "total_pages": 0,
        "total_count": 0,
        "current_page": 0,
        "next_page": null,
        "pervious_page": null
      },
      storeGrpApiCalled: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    this.setStoreOnNotificationNavigation(message)
    this.receiveDataFromLayout(message);
    this.handleResForGetStore(from, message);
    this.handleResForStoreGrpSearch(from,message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.errors) {
        handleLogout(this.props.navigation, responseJson?.errors);
      }
      // this.handleResForGetUser(from,message)
      if (apiRequestCallId === this.getNotificationCountApiCallId) {
        if (responseJson) {
          this.setState({ notificationCount: responseJson.count });
        }
      }
    }
    this.handleResForGetAllRegion(from, message)
    this.handleResForGetAllStore(from, message)
    this.handleResGetAllStoreGroup(from, message)
    this.hanldeResForPrinterSetting(from, message)
    this.setEmptyHeaderSearchData(message);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const token = await getStorageData("token");
    if (!token) {
      this.handleNavigateTo("EmailAccountLoginPage");
    }
    this.handleSocketNotification(token)
    this.setAccountTypeFromStroage()
    this.getNotificationCount();
    this.handleStorageSet();
    this.getAllRegion()
    this.getAllMultipleSelectRegion();
    this.getAllStore()
    this.getStoreList()
    this.sendData()
    // Customizable Area End
  }

  // Customizable Area Start

  setEmptyHeaderSearchData = (message: Message) => {
    if (message.id === getName(MessageEnum.setEmptyHeaderSearchMessage)) {
      this.setState({ searchText: "" });
    }
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevProps.navigation.location.pathname !== this.props.navigation.location.pathname) {
      if(this.checkHeaderConfig("storeAndGroupChange")){
        return 
      }
      if (!this.checkHeaderConfig("multiSelectStore")) {
        this.setState({
          storeGrpApiCalled:false,
          regionId: '',
          groupId: '',
          regionMultiId: [],
          singleStoreSelectionId: '',
          searchValue: '',
          searchText: '',
          selectAllRegionId: undefined,
          selectedAllStoreId: [],
          selectAllStoreWIthGrpId: undefined,
          storeId: [],
          storeIdByGroups:[]
        }, () => {
            this.sendData();
        })
      }
      if (this.checkHeaderConfig("singleSelectStore")) {
        this.getStoreList()
      }
    }
  }

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.LayoutMessageData)
      );
      if (recievedData.userContext) {
        const myUser = recievedData.userContext.user
        this.setState({
          myUser,
          userShortName: this.getShortName(myUser)
        }, () => {
          this.setInitialData();
        })
      }
    }
  }
  
  setInitialData = () => {
    if (this.state.myUser && this.state.myUser.attributes.employee_proifle.data.attributes.region_ids) {
      let regionID = this.state.myUser.attributes.employee_proifle.data.attributes.region_ids.join(',')
      let multiRegId = this.state.myUser.attributes.employee_proifle.data.attributes.region_ids.map(String)
      if(this.checkHeaderConfig('storeAndGroupChange') && this.state.regionMultiId.length > 0){
        this.sendData();
        this.getStoreApiCallOnRegChange(regionID)
        return
      }
      this.setState({ regionId: regionID, regionMultiId: multiRegId }, () => {
        this.getStoreApiCallOnRegChange(regionID)
        this.sendData();
      }
      )
    }
    if (this.checkHeaderConfig('printerSettings')) {
      this.getPrinterSettings()
    }
  }

  getStoreApiCallOnRegChange = (regionID: string) => {
    if (!this.checkHeaderConfig('multiRegionDropdown')) {
      this.getAllStore(regionID)
    } else {
      if (!this.checkHeaderConfig('storeAndGroupChange')) {
        this.getAllStoreByMutliRegion(regionID)
      } else {
        this.getAllStoreAndGroupByMutliRegion(regionID)
      }
    }
  }

  handleSocketNotification = (token: string) => {
    let updateBaseUrl = baseURL.split("//")[1];
    const socket = new WebSocket(`wss://${updateBaseUrl}/cable?token=${token}`);

    socket.onopen = () => {
      let getUserId = localStorage.getItem("userId");
      const subscribeMessage = {
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "NotificationChannel",
          current_user_id: getUserId,
        }),
      };
      socket.send(JSON.stringify(subscribeMessage));
    };

    socket.onmessage = (event) => {
      let notificationCount = JSON.parse(event.data);
      if (notificationCount?.message?.count) {
        this.setState({
          newOrderNotificationSnackbar: true,
          notificationCount: notificationCount.message.count,
        });
      }
    };
  };

  handleStorageSet = async () => {
    const userName = await getStorageData(configJSON.userShortNameKey);
    const tokenReady = !!(await getStorageData(configJSON.tokenKey));
    this.setState({
      userShortName: userName,
      tokenReady: tokenReady,
    });
  };

  debounce = (func: Function, delay: number) => {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(func, delay.valueOf());
  };

  componentWillUnmount(): Promise<void> {
    clearTimeout(this.debounceTimer);
    // Return a resolved Promise
    return Promise.resolve(undefined);
  }

  handleCloseNotificationPopover = () => {
    this.setState({ isNotificationPopoverAnchor: null });
  };

  handleSnackbarClose = () => {
    this.setState({ newOrderNotificationSnackbar: false });
  };

  handleSearchTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    this.setState({ searchText: event.target.value }, () => {
      this.debounce(this.handleInputChangeDebounced, 500);
    });
  };

  handlePopOverOpen = () => {
    this.setState({ popoverOpen: true });
  };

  handlePopOverClose = () => {
    this.setState({ popoverOpen: false });
  };

  handleViewProfile = () => {
    this.handleNavigateTo("CustomisableUserProfiles");
  };

  handleInputChangeDebounced = async () => {
    this.sendSearchText();
  };

  handleNavigateTo(route: string) {
    const navigateMsg: Message = new Message(
      getCustomEnumName(CustomEnums.PageContainerNavigateMessage)
    );
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigateMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateMsg);
  }

  checkIsUserUndefined(user: IMyUser | undefined) {
    if (user !== undefined) {
      return user.attributes.last_name;
    } else {
      return "";
    }
  }

  renderUserName(user: IMyUser | undefined) {
    if (user) {
      return user.attributes.first_name;
    } else {
      return "" + " " + this.checkIsUserUndefined(user);
    }
  }

  getNotificationCount = () => {
    const urlStr = configJSON.getNotificationCountEndPoint;
    const requestMessage = makeApiMessage({
      url: urlStr,
      method: configJSON.getApiMethodType,
    });
    this.getNotificationCountApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClickNotification = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    this.setState({ isNotificationPopoverAnchor: event.currentTarget });
  };

  getAllRegion = () => {
    const requestMessage = makeApiMessage({
      url: configJSON.getAllRegionEndPoint,
      method: configJSON.getApiMethodType,
    });
    this.getAllRegionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllMultipleSelectRegion = () => {
    if (!this.checkHeaderConfig('multiRegionDropdown')) return;
    const requestMessage = makeApiMessage({
      url: configJSON.getAllRegionEndPoint,
      method: configJSON.getApiMethodType,
    });
    this.getAllRegionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForGetAllRegion = async (from: string, message: Message) => {
    if (this.getAllRegionApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        // this.setState({ regionListData : responseJson.data },()=>this.sendData())
        this.setState({ regionListData: responseJson.data })
      }
    }
  }

  handleRegionSelection = (value: { id: string; option: string }) => {
    this.setState({ regionId: value.id, storeId: [], groupId: "",storeIdByGroups:[] });
    this.getAllStore(value.id)
  }

  getAllStore = (regionID?: string) => {
    if (this.checkHeaderConfig("storeAndGroupChange")) return;
    let regionParam = (regionID && this.checkHeaderConfig("multiRegionDropdown")) ? `&region_id=${regionID}` : "";
    const requestMessage = makeApiMessage({
      url: `${configJSON.getAllStoreEndPoint}${regionParam}`,
      method: configJSON.getApiMethodType,
    });
    this.getAllStoreApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllStoreByMutliRegion = (regionID: string) => {
    if (!this.checkHeaderConfig("multiSelectStore")) return;
    let mainUrl = `${configJSON.getAllStoreEndPoint}&region_ids=${regionID}`
    if (!regionID) {
      mainUrl = `${configJSON.getAllStoreEndPoint}&region_ids=0`
    }
    const requestMessage = makeApiMessage({
      url: mainUrl,
      method: configJSON.getApiMethodType,
    });
    this.getAllStoreApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleLoadMoreGroupData = (pageNo: number) => {
    this.setState({
      storeGroupPageNo:pageNo,
      storeGrpApiCalled:false
    },()=>this.getAllStoreAndGroupByMutliRegion(this.state.regionMultiId.join(','),this.state.selectedSearchName,this.state.selectedSearchType,true))
  }


  getAllStoreAndGroupByMutliRegion = (regionID: string, searchTxt:string="",type?:string,isLoadingMore:boolean = false) => {
    if(this.state.storeGrpApiCalled) return
    if(!isLoadingMore){
      this.setState({isStoreGroupDropdownLoading : true})
    }else{
      this.setState({isLoadMoreGroupLoading : true})
    }
    let mainUrl = `${configJSON.getAllStoreEndPoint}&region_ids=${regionID}&is_store_group=true&store_group_page_no=${this.state.storeGroupPageNo}`
    if (!regionID) {
      mainUrl = `${configJSON.getAllStoreEndPoint}&region_ids=0&is_store_group=true`
    }
    if (searchTxt) {
      mainUrl = `${configJSON.getAllStoreEndPoint}&region_ids=${regionID}&is_store_group=true&filter_by[store_group_query]=${searchTxt}&search_from=${type}&store_group_page_no=${this.state.storeGroupPageNo}`
    }
    const requestMessage = makeApiMessage({
      url: mainUrl,
      method: configJSON.getApiMethodType,
    });
    if(searchTxt){
      this.searchStoreAndGroupApiCallId = requestMessage.messageId;
    }else{
      this.getAllStoreAndGroupApiCallId = requestMessage.messageId;
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResGetAllStoreGroup = async (from: string, message: Message) => {
    if (this.getAllStoreAndGroupApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState(prevState => ({
          storeGrpApiCalled:true,
          groupListData: [
            ...prevState.groupListData,
            ...responseJson.data.store_groups.data
          ],
          storeListData: [],
          allStoreListData: [],
          areaListData:  responseJson.data.areas.data,
          isStoreGroupDropdownLoading: false,
          isLoadMoreGroupLoading: false,
          storeGroupMeta: responseJson.data.store_groups.meta
        }))
      }
    } else if (this.searchStoreAndGroupApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState(prevState=>({
          groupListData: this.getGroupListData([
            ...prevState.groupListData,
            ...responseJson.data.store_groups.data
          ],responseJson.data.store_groups.data),
          storeListData: [],
          allStoreListData: [],
          areaListData:  responseJson.data.areas.data,
          isStoreGroupDropdownLoading: false,
          isLoadMoreGroupLoading: false,
          storeGroupMeta: responseJson.data.store_groups.meta
        }), () => {
          const groupFirstId = responseJson.data?.store_groups?.data.length > 0 ? responseJson.data?.store_groups?.data[0].id : ""
          const groupFirstName = responseJson.data?.store_groups?.data.length > 0 ? responseJson.data?.store_groups?.data[0].attributes.name : ""
          if(this.state.groupId !== groupFirstId){
            this.handleGroupSelection(groupFirstId,groupFirstId,groupFirstName)
          }
        })
      }
    } 
  }


  getGroupListData = (AllMergeData:GroupManagementDropdown[],reponseGroupData:GroupManagementDropdown[]) => {
    const isTypeAreaStore = this.state.selectedSearchType === "area" || this.state.selectedSearchType === "store"
    if(isTypeAreaStore){
      return AllMergeData
    }else{
      return reponseGroupData
    }
  }


  handleResForGetAllStore = async (from: string, message: Message) => {
    if (this.getAllStoreApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ storeListData: responseJson.data, storeId: responseJson.data.map((item: any) => item.id) }, () => this.sendData())
      }
    }
  }

  sendData = () => {
    let msgData = new Message(getName(MessageEnum.BroadcastNavbarDataMessage))
    msgData.addData(getName(MessageEnum.BroadcastNavbarData), {
      regionID: this.state.regionId,
      storeId: this.checkHeaderConfig("storeAndGroupChange") ? this.state.storeIdByGroups.join(',') : this.state.storeId.join(','),
      groupId: this.state.groupId,
      areaId: this.state.areaId.join(','),
      regionMultiId: this.state.regionMultiId.join(','),
      regionName: this.getBranchNameById(this.state.regionId),
      storeNames: this.getStoreNames(this.checkHeaderConfig("storeAndGroupChange") ? this.state.storeIdByGroups : this.state.storeId),
      regionNames: this.getRegionNames(this.state.regionMultiId),
      regionSelectAll: this.state.regionMultiId.length === this.state.regionListData.length,
      singleStoreID: this.state.singleStoreSelectionId,
      singleStoreName: this.getSingleStoreName(this.state.singleStoreSelectionId)
    })
    this.send(msgData)
  }

  sendSearchText = () => {
    let msgData = new Message(getName(MessageEnum.SearchTextMessage))
    msgData.addData(getName(MessageEnum.SearchMessageText), {
      searchText: encodeURIComponent(this.state.searchText)
    })
    this.send(msgData)
  }

  sendPrintSettings = (settings: ReceiptPrinterSetting) => {
    let msgData = new Message(getName(MessageEnum.PrintSettingsMessage))
    msgData.addData(getName(MessageEnum.PrintSettngsData), {
      receiptPrinterSetting: settings
    })
    this.send(msgData)
  }

  onChangeMultiSelect(event: { id: string, option: string }) {
    const { storeListData, storeId } = this.state;

    const isSelectAll = storeId.length === storeListData.length;
    const idLists = JSON.parse(JSON.stringify(event.id));
    const allIds = storeListData.map((varItem) => varItem.id);
    let selectedIds = [];
    if (idLists.includes("-1")) {
      if (!isSelectAll) {
        selectedIds = allIds
        this.setState({ selectAllStoreId: event })
      } else {
        this.setState({ selectAllStoreId: undefined })
      }
    } else {
      this.setState({ selectAllStoreId: undefined })
      if (this.state.storeId.includes(idLists)) {
        selectedIds = this.state.storeId.filter(id => id !== idLists);
      } else {
        selectedIds.push(...this.state.storeId, idLists)
        if (selectedIds.length == storeListData.length) {
          this.setState({ selectAllStoreId: { id: "-1", option: "Select All" } })
        }
      }
    }

    this.setState({
      storeId: selectedIds,
    }, () => {
      if (!this.checkHeaderConfig('multiSelectStore')) return;
      this.sendData()
    });
  }

  handleClearAllStoreGroup = () => {
    this.setState({    
      storeId: [],
      groupId: "",
      storeListBygroupData: [],
      selectedGroupName: "",
      areaId:[],
      storeIdByGroups: [],
      areaListByGroupData:[],
      allAreaListByGroupData: [],
      recordsToShowArea: 10,
    },()=>{
        if(this.state.selectedSearchName){
          this.setState({
            selectedSearchName: "",selectedSearchType: "",storeGroupPageNo:1
          },()=>this.getAllStoreAndGroupByMutliRegion(this.state.regionMultiId.join(',')))
        }else{
          this.sendData()
        }
      })
  }

  onChangeMulSelectStoreAndGroup(event: { id: string, name: string},areaId?:string, type?: string ) {
    const { groupId } = this.state;
    if (type === "group") {
      this.handleGroupSelection(event.id, groupId, event.name);
    } else if(type === "area"){
      this.handleAreaSelection(event.id, groupId, event.name);
    } else if(type === "store"){
      this.handleStoreSelection(event.id, areaId as string, event.name);
    }
  }

  handleGroupSelection(idList: string, groupId: string, groupName: string) {
    let selectedGroupIds = idList;
    
    if (this.state.groupId === String(selectedGroupIds)) {
      selectedGroupIds = ""
    }
    const group = this.state.groupListData.find((group: GroupManagementDropdown) => group.id === String(selectedGroupIds));
    if (group) {
      const areaManagements = group.attributes.areas
      
      const regionWiseArea = this.state.areaListData.map((areadata)=>{
        return {
          id: areadata.attributes.id,
          name: areadata.attributes.area_name,
          store_managements: areadata.attributes.store_managements
        }
      })

      const mergedAreas = [...areaManagements, ...regionWiseArea].reduce((acc, current) => {
        if (!acc.some((item:{id:number}) => item.id === current.id)) {
          acc.push(current as never);
        }
        return acc;
      }, []);

      const visibleAreas = mergedAreas.slice(0, 10);

      const recordsToShow : { [key: number]: number }  = {};
      areaManagements.forEach(group => {
        recordsToShow[group.id] = 10; 
      });  

      const updatedSelectedStores = areaManagements.reduce((acc, area) => {
        const storeIds = area.store_managements.data
        .filter((store: any) => store.attributes.from_store_group)
        .map((store: any) => store.attributes.id);
       return {
          ...acc,
          [area.id]: storeIds,
        };
      }, {});

      const storeId = Array.from(
        new Set(
          Object.values(updatedSelectedStores).flat()
        )
      );

      const storeCountByArea = areaManagements.reduce((acc: { [key: number]: number }, area) => {
        acc[area.id] = area.store_managements.data.length;
        return acc;
      }, {} as { [key: number]: number });

      this.setState({ recordsToShowByGroup: recordsToShow });
      const areaIds = areaManagements.map(area => String(area.id));
      this.setState({
        recordsToShowByGroup: recordsToShow ,
        areaId:this.state.groupId === selectedGroupIds ? [] : areaIds,
        storeIdByGroups:this.state.groupId === selectedGroupIds ? [] : storeId as string[], 
        groupId:this.state.groupId === selectedGroupIds ? "" : selectedGroupIds,
        areaListByGroupData:visibleAreas,
        allAreaListByGroupData: mergedAreas,
        selectedStoresByArea: updatedSelectedStores,
        selectedGroupName: groupName,
        recordsToShowArea: 10,
        storeCountByArea
      }, () => this.sendData())
    }else{
      this.setState({
        areaId:[],
        storeId:[], 
        storeIdByGroups: [],
        groupId: "" ,
        areaListByGroupData:[] ,
        allAreaListByGroupData: [],
        selectedGroupName: "",
        recordsToShowArea: 10
      }, () => this.sendData())
    }
  }


  handleAreaSelection(idList: string, areaId: string, areaName: string) {
    const recordsToShow: { [key: number]: number } = {};
    this.state.areaListByGroupData.forEach((group) => {
      recordsToShow[group.id] = 10;
    });
  
    this.setState((prevState) => {
      const isSelectAll = idList === "-1";
      let updatedAreaId = prevState.areaId;
      let updatedStoreIdByGroups = prevState.storeIdByGroups;
      let updatedSelectedStoresByArea = { ...prevState.selectedStoresByArea };
  
      if (isSelectAll) {
        if (prevState.areaId.length === this.state.allAreaListByGroupData.length) {
          updatedAreaId = [];
          updatedStoreIdByGroups = [];
          updatedSelectedStoresByArea = {};
        } else {
          updatedAreaId = this.state.allAreaListByGroupData.map((area) => String(area.id));
          updatedStoreIdByGroups = Array.from(
            new Set(
              this.state.allAreaListByGroupData.flatMap((area) =>
                area.store_managements?.data.map((store: any) => store.attributes.id)
              )
            )
          );
          updatedSelectedStoresByArea = this.state.allAreaListByGroupData.reduce((acc, area) => {
            const storeIds = area.store_managements?.data.map((store: any) => store.attributes.id);
            return { ...acc, [area.id]: storeIds };
          }, {});
        }
      } else {
        const isAreaSelected = prevState.areaId.includes(String(idList));
        updatedAreaId = isAreaSelected
          ? prevState.areaId.filter((id) => id !== String(idList))
          : [...prevState.areaId, String(idList)];
  
        const storeIds = this.getStoreIdByArea(idList)
      
        if (isAreaSelected) {
          updatedStoreIdByGroups = updatedStoreIdByGroups.filter((id) => !storeIds.includes(id));
          delete updatedSelectedStoresByArea[Number(idList)];
        } else {
          updatedStoreIdByGroups = [...new Set([...updatedStoreIdByGroups, ...storeIds])];
          updatedSelectedStoresByArea[Number(idList)] = storeIds;
        }
      }

      const storeCountByArea = updatedAreaId.reduce((acc: { [key: number]: number }, areaId) => {
        const area = this.state.allAreaListByGroupData.find((area) => area.id === Number(areaId));
        if(area){
          acc[Number(areaId)] = area.store_managements.data.length;
        }
        return acc;
      }, {});

      return {
        recordsToShowByGroup: recordsToShow,
        areaId: updatedAreaId,
        storeIdByGroups: updatedStoreIdByGroups,
        selectedStoresByArea: updatedSelectedStoresByArea,
        storeCountByArea
      };
    }, () => this.sendData());
  }


  getStoreIdByArea = (idList: string): string[] => {
    const area = this.state.areaListByGroupData.find((area) => area.id === Number(idList));
  
    const storeIdFromStoreGroup = area 
      ? area.store_managements.data
          .filter((store: { attributes: { from_store_group: boolean } }) => store.attributes.from_store_group)
          .map((store: { attributes: { id: string | number } }) => store.attributes.id)
      : [];
  
    if (area) {
      if (storeIdFromStoreGroup.length > 0) {
        return storeIdFromStoreGroup as string[];
      } else {
        return area.store_managements.data.map((store: any) => store.attributes.id);
      }
    }
  
    return [];
  }
  
  
  handleLoadMoreAreaData = () => {
    this.setState(
      (prevState) => ({ recordsToShowArea: prevState.recordsToShowArea + 10 }),
      () => {
        const group = this.state.groupListData.find(
          (group: GroupManagementDropdown) => group.id === String(this.state.groupId)
        );

        if (group) {
          const areaManagements = group.attributes.areas;
          const regionWiseArea = this.state.areaListData.map((areadata) => ({
            id: areadata.attributes.id,
            name: areadata.attributes.area_name,
            store_managements: areadata.attributes.store_managements,
          }));

          const mergedAreas = [...areaManagements, ...regionWiseArea].reduce(
            (acc, current) => {
              if (!acc.some((item: { id: number }) => item.id === current.id)) {
                acc.push(current as never);
              }
              return acc;
            },
            []
          );

          const visibleAreas = mergedAreas.slice(0, this.state.recordsToShowArea);
          this.setState({ areaListByGroupData: visibleAreas });
        }
      }
    );
  };
  
  handleStoreSelection(idList: string, areaId: string, aresName: string) {
    this.setState((prevState) => {
      const isSelected = prevState.selectedStoresByArea[Number(areaId)]?.includes(idList);

      return {
        selectedStoresByArea: {
          ...prevState.selectedStoresByArea,
          [areaId]: isSelected
            ? prevState.selectedStoresByArea[Number(areaId)].filter((id) => id !== idList)
            : [...(prevState.selectedStoresByArea[Number(areaId)] || []), idList],
        },
        storeIdByGroups: isSelected
          ? prevState.storeIdByGroups.filter((id) => id !== idList)
          : [...prevState.storeIdByGroups, idList]
      };
    },() => this.sendData());

  }

  updateSelectionState(storeId: string[]
    , groupId: string) {
    this.setState({
      storeIdByGroups: storeId.map(id => id.toString()),
      groupId: groupId
    }, () => {
      this.sendData()
    });
  }

  onChangeMultiRegionSelect = (event: { id: string, option: string }) => {
    const { regionListData, regionMultiId } = this.state;
    const isSelectAll = regionMultiId.length === regionListData.length;
    const idLists = JSON.parse(JSON.stringify(event.id));
    const allIds = regionListData.map((varItem) => varItem.id);
    let selectedIds: any = [];
    if (idLists.includes("-1")) {
      if (!isSelectAll) {
        selectedIds = allIds
        this.setState({ selectAllRegionId: event })
      } else {
        this.setState({ selectAllRegionId: undefined })
      }
    } else {
      this.setState({ selectAllRegionId: undefined })

      if (this.state.regionMultiId.includes(idLists)) {
        selectedIds = this.state.regionMultiId.filter(id => id !== idLists);
      } else {
        selectedIds.push(...this.state.regionMultiId, idLists)
        if (selectedIds.length == regionListData.length) {
          this.setState({ selectAllRegionId: { id: "-1", option: "Select All" } })
        }
      }
    }
    this.setState({
      regionMultiId: selectedIds,
      storeId: [],
      groupId: "",
      storeListBygroupData: [],
      selectedGroupName: "",
      areaId:[],
      storeIdByGroups: [],
      areaListByGroupData:[] ,
      storeGroupPageNo: 1,
      selectedSearchName:'',selectedSearchType:'',
      storeGrpApiCalled:false,
      groupListData: []
    },()=>{
      this.sendData()
      this.getStoreApiCallOnRegChange(selectedIds.join(','))
    });
  }

  debouncedFunction = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) => inputFunction(newInputValue),
    700,
    { maxWait: 2000 }
  );

  getBranchNameById(id: string): string {
    const region = this.state.regionListData.find((region) => region.id === id);
    return region ? region.attributes.branch_name : "No Region Selected";
  }

  getRegionNames(ids: string[]) {
    const storeNames = ids.map(id => {
      const store = this.state.regionListData.find(store => store.id === id);
      return store ? store.attributes.name : null;
    }).filter(name => name !== null);

    if (storeNames.length === this.state.regionListData.length) {
      return "All Region";
    }

    return storeNames.join(",");
  }

  getStoreNames(ids: string[]) {
    const storeNames = ids.map(id => {
      const store = this.state.storeListData.find(store => store.id === id);
      return store ? store.attributes.store_name : null;
    }).filter(name => name !== null);

    if (storeNames.length === this.state.storeListData.length) {
      return "All Stores";
    }

    return storeNames.join(",");
  }

  getSingleStoreName(id: string) {
    const store = this.state.storeListData.find(store => store.id === id);
    return store ? store.attributes.store_name : "";
  }

  handleStoreFilter = (value: string, uniqueStoreList: any) => {
    this.setState({ searchValue: value });
  }

  sortStoreList(storeList: { id: string, option: string, type: string }[], selectedStoreIds: Set<number | string>): { id: string, option: string, type: string }[] {
    return storeList.sort((a: { id: number | string }, b: { id: number | string }) => {
      if (selectedStoreIds.has(a.id) && !selectedStoreIds.has(b.id)) {
        return -1;
      }
      if (!selectedStoreIds.has(a.id) && selectedStoreIds.has(b.id)) {
        return 1;
      }
      return 0;
    });
  }

  getPrinterSettings = () => {
    const apiMessage = makeApiMessage({
      method: "GET",
      url: configJSON.printerSettingApi
    })
    this.getPrinterSettingApiCallId = apiMessage.messageId
    this.send(apiMessage)
  }

  hanldeResForPrinterSetting = async (from: string, message: Message) => {
    if (this.getPrinterSettingApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.sendPrintSettings(responseJson.data);
    }
  }

  handleSingleStoreSelection = (value: { id: string; option?: string }) => {
    this.setState({ singleStoreSelectionId: value.id }, () => this.sendData());
  }

  checkHeaderConfig = (showPermission: keyof typeof headerConfig) => {
    return headerConfig[showPermission].includes(this.props.navigation.location.pathname)
  }

  handelStoreDropdownScroll = (event: React.SyntheticEvent) => {
    if (this.storeListEmptyFlag) return;
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - position <= 1.3) {
      this.setState(
        (prev) => ({ storeListPageNo: prev.storeListPageNo + 1 }),
        () => {
          this.getStoreList();
        }
      );
    }
  };

  getStoreList = () => {
    if (!this.checkHeaderConfig("singleSelectStore")) return;
    const searchQuery = this.state.storeSearchText
      ? `&filter_by[query]=${this.state.storeSearchText}`
      : "";
    const apiUrl =
      configJSON.getAllStoreEndPoint +
      `${searchQuery}&page_no=${this.state.storeListPageNo}`;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.apiMethodTypeGet,
    });

    this.storeListEmptyFlag = true;
    this.getStoreListApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  debouncedStoreSearch = lodash.debounce(
    (newInputValue: string) => this.handleSearchStore(newInputValue),
    700,
    { maxWait: 2000 }
  );

  handleSearchStore = (inputValue: string) => {
    if (inputValue === this.state.storeSearchText) return;
    this.setState(
      {
        storeListPageNo: 1,
        storeSearchText: inputValue,
      },
      () => this.getStoreList()
    );
  };

  handleResForGetStore = async (from: string, message: Message) => {
    if (this.getStoreListApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson: { data: StoreManagementDropdown[] } = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
      if(responseJson.data.length == 1 && this.state.storeListOptions.length == 0){
        let optionStructure = responseJson.data.map((value)=>({id:value.id,option:value.attributes.store_name}))
        console.log(optionStructure)
        return this.setState({singleStoreSelectionId:optionStructure[0].id,storeListOptions:optionStructure},()=>this.sendData())
      }

      if (responseJson.data) {
        const { myUser, storeListPageNo, storeListOptions } = this.state;
        const { store_id, store_name } =
          myUser?.attributes?.employee_proifle?.data?.attributes
            ?.store_management || {};
        let currentStore = storeListOptions.find(
          (store) => store.id === lodash.toString(store_id)
        );
        if (store_id !== undefined && currentStore === undefined) {
          currentStore = {
            id: lodash.toString(store_id),
            option: lodash.toString(store_name),
          };
        }
        const array = (responseJson?.data || []).map((item) => ({
          id: item.id,
          option: item.attributes.store_name,
        }));
        const newStoreList =
          storeListPageNo === 1 ? array : [...storeListOptions, ...array];
        currentStore && newStoreList.unshift(currentStore);
        this.storeListEmptyFlag = array.length < 10;
        this.setState({
          storeListOptions: lodash.uniqBy(newStoreList, "id"),
        });
      }
    }
  }

  setAccountTypeFromStroage = async() => {
    const isDev = ["dev", "uat", "stage"].includes(getEnvironment())
    let accountType = await getStorageData("accountType")
    let status = sortCondition(accountType == "b2b",true,false) as boolean
    this.setState({ isB2bActive: renderBooleanConditions(isDev, status, false)},()=>this.broadcastUserAccountTypeChange())
  }

  toggleB2bUserChange = (status: boolean) => {
    let accountType = sortCondition(status,"b2b","b2c")
    setStorageData("accountType",accountType)
    this.setState({ isB2bActive: status},()=>this.broadcastUserAccountTypeChange())
    this.goToAnyPage(status)    
  }

  broadcastUserAccountTypeChange = () => {
    let msgData = new Message(getName(MessageEnum.AccountTypeInfoMessage))
    msgData.addData(getName(MessageEnum.AccountTypeInfoData), { 
      accountType : sortCondition(this.state.isB2bActive,"b2b","b2c"),
      isB2bActive: this.state.isB2bActive,
      isB2cActive: !this.state.isB2bActive
    })
    this.send(msgData)
  }


  goToAnyPage = (isB2B:boolean) => {
    const gotoOrders: Message = new Message(getName(MessageEnum.NavigationMessage));
    gotoOrders.addData(getName(MessageEnum.NavigationTargetMessage), isB2B ? "OrderCreation" : "MainDashboard");
    gotoOrders.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(gotoOrders);
}

  storeGrpSearch = (searchTxt: string) => {
    if(!searchTxt && this.state.selectedSearchName){
      this.setState({storeGrpSearch: {
        area: [],
        store_group: [],
        store: [],
      },selectedSearchName:'',selectedSearchType:''})
      this.getAllStoreAndGroupByMutliRegion(this.state.regionMultiId.join(','))
    }else{
      const requestMessage = makeApiMessage({
        url: `${configJSON.storeGrpSuggestionEndpoint}?name=${searchTxt}`,
        method: configJSON.getApiMethodType,
      });
      this.storeGrpSearchApiCallId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  handleResForStoreGrpSearch = async (from: string, message: Message) => {
    if (this.storeGrpSearchApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.suggestion) {
        this.setState({ storeGrpSearch: responseJson.suggestion })
      }
    }
  }

  handleSelectSuggestion = (selectedValue:string, type:string) => {
    this.setState({
      groupListData:[],
      selectedSearchName:selectedValue,selectedSearchType:type,storeGroupPageNo:1,storeGrpApiCalled:false
    },() => this.getAllStoreAndGroupByMutliRegion(this.state.regionMultiId.join(','),selectedValue,type)
  )
  }

  setStoreOnNotificationNavigation = (message: Message) => {
    if (message.id === getCustomEnumName(CustomEnums.DataForRedirectionFromNotification)) {
      const { store_id } = message.properties.orderData;
      if (store_id) {
        this.setState({
          singleStoreSelectionId: store_id
        })
      }
    }
  }

  getShortName = (myUser?: IMyUser) => 
    myUser ? (
      myUser.attributes.first_name[0] + myUser.attributes.last_name[0]
    )
      .toUpperCase()
      .substring(0, 2)
    : "NA"

  getStoreOptions = (currentOptions: S["storeListOptions"], selectedStore?: {store_id: number; store_name: string}) => {
    if ((!(selectedStore?.store_id) || currentOptions.some(option => option.id === lodash.toString(selectedStore?.store_id)))) return currentOptions
    return [
      {
        id: lodash.toString(selectedStore.store_id), 
        option: lodash.toString(selectedStore.store_name)
      },
      ...currentOptions
    ]
  }
  // Customizable Area End
}
