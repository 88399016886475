import React from "react";

// Customizable Area Start

import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, Paper, Snackbar, styled, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { colors } from "../../../../packages/blocks/utilities/src/Colors";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import { CalendarTodayRounded }  from '@material-ui/icons';
import Alert from "@material-ui/lab/Alert";

import { renderBaseOnConditions } from "../../../../packages/blocks/utilities/src/CustomBlockHelpers";
import B2bOrderReportConroller,{
    DropdownType,
    Props,
    ReportItem,
    RevenueReport,
    SalesAndRevenueReport,
    configJSON,
} from "./B2bOrderReportController.web";
import ReactDatePicker from "react-datepicker";
import { isArray } from "lodash";

// Customizable Area End
export default class B2bOrderReport extends B2bOrderReportConroller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTaskbar(){
    const { reportType } = this.state;
    return (
      <StyledWrapper>
          <Box sx={webStyle.taskbar} data-test-id={configJSON.taskbarTest}>
        <div className="reportSelectContainer">
          <AutocompleteSelect
            data-test-id='reportDropdown'
            placeholder="Select Report"
            fieldName="report"
            inputStyle={webStyle.autoCompleteInputStyle}
            options={this.state.reportList}
            value={reportType?.id || ''}
            disableClear={true}
            listBoxStyle={{ maxHeight: 310 }}
            onChange={(_blank: React.ChangeEvent<HTMLInputElement>, value: DropdownType | null) => {
              this.handleReportChange(value)
            }}
          />
        </div>
        <Box sx={webStyle.headerDropdown}>
          <Box sx={webStyle.datePicker}>
            <ReactDatePicker
              showIcon
              dateFormat="dd/MM/yyyy"
              data-test-id="fromDate"
              toggleCalendarOnIconClick
              placeholderText={configJSON.fromDataPlaceholder}
              maxDate={new Date()}
              selected={this.state.selectedFromDate ? new Date(this.state.selectedFromDate) : null}
              onChange={(date) => this.handleFromDateInput(date)}
              icon={<CalendarTodayRounded/>}
            />
          </Box>
          <Box sx={webStyle.datePicker}>
            <ReactDatePicker
              showIcon
              data-test-id="toDate"
              dateFormat="dd/MM/yyyy"
              toggleCalendarOnIconClick
              placeholderText={configJSON.toDatePlaceholder}
              minDate={new Date(this.state.selectedFromDate)}
              maxDate={new Date()}
              selected={this.state.selectedToDate ? new Date(this.state.selectedToDate) : null}
              onChange={(date) => this.handleToDateInput(date)}
              icon={<CalendarTodayRounded/>}
            />
          </Box>
          <Button
            data-test-id="PageHeaderButton"
            disabled={!this.validateFilters()}
            style={webStyle.buttonStyle}
            onClick={this.handleExportClick}
          >
            {
              this.state.showLoading ?
                (
                  <CircularProgress
                    disableShrink
                    style={webStyle.loader}
                    size={20}
                    thickness={4}
                    value={100}
                  />
                ) : configJSON.exportText
            }

          </Button>
        </Box>
      </Box>
      </StyledWrapper>
    )
  }

  renderFields(fields: any){
    return (
      <Paper>
      <Box sx={webStyle.checkBoxFieldWrapper}>
      <FormGroup>
        <FormControlLabel control={<Checkbox checked={this.getAllTheCheckedColumns()?.length === this.state.filterFields.length} onChange={(e) => this.updateCheckbox(fields, null, true, 'simpleFilter')} />} label={configJSON.selectAll} />
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {fields.map((elem: ReportItem) => (
            <Grid item xs={12} sm={6} md={3} key={elem.id}>
              <FormControlLabel control={<Checkbox checked={elem.checked} onChange={(e) => this.updateCheckbox(fields, elem, false, 'simpleFilter')} />} label={this.capitalizeFirstCharacter(elem.label)} />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
    </Box>
    </Paper>
    )
  }

  renderRevenueFields(filterField: RevenueReport) {
    return (
      <Paper>
        <Table style={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell colSpan={2}><FormControlLabel control={<Checkbox checked={this.checkIfAllFieldsChecked()} onChange={(event) => { this.selectAllRevenueFields(filterField, event.target.checked, false)}} />} label={configJSON.selectAll} /></TableCell>
            </TableRow>
            {
              Object.keys(filterField).map((key) => {
                if(key !== 'total_for_each_column') {
                  return (
                    <TableRow
                    key={key}
                  >
                    <TableCell component="th" scope="row">
                      <h4 style={{ wordWrap: 'break-word', width: '8rem' }}><strong>{key === 'other' ? '' : this.capitalizeFirstCharacter(key)}</strong></h4>
                    </TableCell>
                    <TableCell align="left">
                      <FormGroup>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
  
                          {
                            (filterField[key] as ReportItem[]).map((field: ReportItem, index: number) => (
                              <Grid item xs={4} key={index}>
                                <FormControlLabel control={<Checkbox data-test-d={`option-${index}-${key}`} checked={field.checked} onChange={() => this.handleFilter(field, key, '')} />} label={this.capitalizeFirstCharacter(field.label)} />
                              </Grid>
                            ))
                          }
  
                        </Grid>
                      </FormGroup>
                    </TableCell>
                  </TableRow>
                  )
                } else {
                  return (
                    <TableRow>
                        <TableCell colSpan={2}><FormControlLabel control={<Checkbox  checked={(filterField[key] as boolean)} onChange={() => this.handleFilter(filterField[key], key, '')}/>} label="Total for each Column" /></TableCell>
                    </TableRow>
                  )
                }
              })
            }
          </TableBody>
        </Table>
      </Paper>
    )
  }
  renderFieldsCategoriesWise() {
    const { reportType, filterFields } = this.state;
    return(
      <Box>
        { configJSON.revenueFields.indexOf(reportType ? reportType.option : '') !== -1 && this.renderRevenueFields(filterFields as SalesAndRevenueReport)}
        { configJSON.directFields.indexOf(reportType ? reportType.option : '') !== -1 && this.renderFields(filterFields)}
    </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>    
              <Box style={webStyle.flexSpaceEvenly}>
                {this.renderTaskbar()}
                <Box sx={webStyle.contentWrapper}>
                <Box sx={webStyle.taskbar}>
                  <Box sx={{ maxWidth: 365, minWidth: 410 }}>
                    <h4 style={webStyle.headerText1} data-test-id="report-text"><strong>Report Name :</strong> {this.state.reportType?.option}</h4>
                    <h4 style={webStyle.headerText1}><strong>Period : </strong>{`${this.formatDate(this.state.selectedFromDate)} ${this.state.selectedFromDate && 'to'} ${this.formatDate(this.state.selectedToDate)}`}</h4>
                  </Box>
                    <Box sx={webStyle.regionStoreDiv}>
                      <h4 style={webStyle.headerText1}><strong>Region : </strong> {'All Regions'}</h4>
                      <h4 style={webStyle.headerText1}><strong>Stores : </strong> {`${this.state.selectedStore.length === this.state.storeList.length ? ' All Stores' : ' Selected Region Stores'} (${this.state.selectedStore.length})`}</h4>
                    </Box>
                </Box>
                <StyleCustomizeBox>
                    <Button
                      data-test-id={configJSON.showOptionsTest}
                      disabled={!this.state.reportType}
                      onClick={this.handleShowFilter}
                      style={{...webStyle.buttonStyle, ...webStyle.customizeButtonStyle}}
                    >
                      {configJSON.customizeText}
                    </Button>
                  { renderBaseOnConditions(
                      this.state.isB2bActive,
                      <div className="reportSelectClientContainer">
                        <AutocompleteSelect
                          data-test-id='reportDropdown2'
                          placeholder="Select Client"
                          fieldName="report"
                          inputStyle={webStyle.autoCompleteInputStyle}
                          options={this.state.reportList}
                          value={this.state.reportType?.id || ''}
                          disableClear={true}
                          listBoxStyle={{ maxHeight: 310 }}
                          onChange={(_blank: React.ChangeEvent<HTMLInputElement>, value: DropdownType | null) => {
                            this.handleReportChange(value)
                          }}
                        />
                      </div>,
                      <></>
                    )
                    }
                </StyleCustomizeBox>
                {
                  this.state.showFilterOptions && this.renderFieldsCategoriesWise()
                }
                </Box>
              </Box>
          
        <Snackbar
          anchorOrigin={{
            vertical: configJSON.top,
            horizontal: configJSON.center,
          }}
          autoHideDuration={2000}
          open={this.state.message.name !== ''}
          data-test-id="snack-bar"
          onClose={this.handleCloseSnackbar}
        >
          <Alert severity={this.state.message?.type}>{this.state.message?.name}</Alert>
        </Snackbar>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledWrapper = styled("div")({
  "& .bordered-select": {
    minWidth: "260px",
    margin: "0 12px",
  },

  "& .reportSelectContainer": {
    margin: "0 12px",
    width: "28%",
    "@media only screen and (max-width: 1024px)": {
      minWidth:"32%",
     },
     "@media only screen and (max-width: 1280px)": {
      minWidth:"40%",
     },
     "@media only screen and (max-width: 1366px)": {
      minWidth:"40%",
     }
  }
})

const webStyle: any = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: colors().white,
  },
  flexSpaceEvenly:{
    display:"flex",
    justifyContent:"space-evenly",
    flexDirection:"column"
  },
  datePicker: {
    marginRight: 20,
    '& .react-datepicker__calendar-icon': {
      right: 0,
      padding: '0.4rem',
      fontSize: 18
    },
    '& .react-datepicker__view-calendar-icon input': {
      padding: '10px 12px 10px 12px',
      width: '132px',
      borderRadius: '6px',
      border: `1px solid ${colors().lightborder}`,
      height: '37px'
    }
  },
  headerDropdown: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 2
  },
  regionStoreDiv : {
    marginLeft: '150px'
  },
  taskbar: {
    display: 'flex',
    justifyContent:"space-between",
    marginTop: '15px'
  },
  contentWrapper: {
    marginTop: '10px',
    marginLeft: '12px',
    lineheight: 18
  },
  checkBoxFieldWrapper: {
    padding: '10px'
  },
  branchDropdown: {
    minWidth: 200,
    margin: "0 12px",
  },
  autoCompleteInputStyle: {
    padding: '0px 10px 0px 6px',
    width: 'auto',
    maxWidth: '350px'
  },
  buttonStyle: {
    height: "37px",
    padding: '6px 10px 6px 10px',
    fontWeight: 'bolder',
    border: "none",
    borderRadius: '6px',
    textTransform: 'none',
    backgroundColor: colors().lightgray,
  },
  customizeButtonStyle: {
    color: colors().cyancobaltblue,
    fontWeight: 600,
    marginBottom: 16,
    marginTop: 16
  },
  loader: {
    color: colors().cyancobaltblue,
    animationDuration: '550ms',
  },
  headerText1: {
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  }
};

const StyleCustomizeBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "60%",
  "& .reportSelectClientContainer": {
    minWidth: "30%",
    marginTop: "12px"
  },
  "@media only screen and (max-width: 1366px)": {
    width:"70%",
   },
   "@media only screen and (max-width: 1280px)": {
    width:"77%",
   },
  "@media only screen and (max-width: 1024px)": {
    width: "96%"
  },  
})


// Customizable Area End
